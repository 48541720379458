import styled from "@emotion/styled";

type ITextProps = {
  color: string,
  size: 'small' | 'medium' | 'large'
};

enum Size {
  small = 8,
  medium = 10,
  large = 16
}

const Text = styled.span<ITextProps>`
  color: ${props => props.color};
  font-family: Open Sans, sans-serif;
  font-size: ${props => Size[ props.size ]}px;
  font-weight: 500;
  text-align: center;
  user-select: none;
`

export default Text
