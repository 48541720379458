import styled from '@emotion/styled';
import BorderedInput from "./bordered-input";

const PlayerNumberInput = styled(BorderedInput)`
  border: 1px solid transparent;
  padding: 0 6px;
  height: 36px;
  text-align: center;
  max-width: 127px;
  min-width: 127px;
`;

export default PlayerNumberInput;
