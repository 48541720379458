import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { addTimer, setTimer as storeSetTimer } from '../../store/time/time';
import useTime from "./useTime";

function useTimer() {
  const dispatch = useDispatch();
  const { isActive, passedTime, startTime } = useTime();
  const [timer, setTimer] = React.useState(passedTime);
  const interval = useRef<NodeJS.Timer | undefined>(undefined)
  const timeout = useRef<NodeJS.Timeout | undefined>(undefined)
  
  function changeTimer(milliseconds: number) {
    dispatch(storeSetTimer({ milliseconds }))
  }
  
  function addToTimer(milliseconds: number) {
    dispatch(addTimer(milliseconds))
  }
  
  useEffect(() => {
    setTimer(passedTime);
  }, [passedTime, startTime])
  
  useEffect(() => {
    function updateTimer() {
      setTimer(prevState => prevState + 1000);
    }
    
    function startTimer() {
      const left = passedTime % 1000
      timeout.current = setTimeout(() => {
        updateTimer();
        interval.current = setInterval(updateTimer, 1000);
      }, 1000 - left);
    }
    
    function stopTimer() {
      clearInterval(interval.current)
      clearTimeout(timeout.current)
    }
    
    if (isActive) {
      startTimer()
    } else {
      stopTimer()
    }
    
    return () => {stopTimer()};
  }, [isActive, passedTime])
  
  return { timer, setTimer: changeTimer, addToTimer, isActive }
}

export default useTimer
