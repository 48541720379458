import getTimeFromTotal from "./getTimeFromTotal";

interface IVkUrlData {
  oid: string | null
  id: string | null
  hour: number | null
  minute: number | null
  second: number | null
}

type GenerateVkUrlParams = {
  oid: string | null,
  id: string | null,
} & ({
  hour: number | null,
  minute: number | null,
  second: number | null,
  totalSeconds?: never,
} | {
  totalSeconds: number,
  hour?: never,
  minute?: never,
  second?: never,
});

export class TransformVKUrl {
  static isVkUrl(url: string): boolean {
    const regex = /^(https?:\/\/)?(www\.)?(vk\.com|vkvideo\.ru)\/.+$/;
    return regex.test(url);
  }
  
  static transformUrl(url: string): string | null {
    if (this.isValidUrl(url)) {
      return url;
    } else {
      const data = this.getUrlData(url);
      return this.generateUrl(data)
    }
  }
  
  static isValidUrl(url: string): boolean {
    const regex = /^(https?:\/\/)?(www\.)?(vk\.com)\/.+$/;
    return regex.test(url);
  }
  
  static getUrlData(url: string): IVkUrlData {
    const regex = /^(https?:\/\/)?(www\.)?(vk\.com|vkvideo\.ru)\/.*?(((video-)|(oid=-))(\d+).*?(\d+))((\?t=)((\d+)h)?((\d+)m)?((\d+)?s)?)?.*?$/;
    const match = url.match(regex.source);
    return {
      oid: match?.[ 8 ] ?? null,
      id: match?.[ 9 ] ?? null,
      hour: match?.[ 13 ] ? parseInt(match[ 13 ], 10) : null,
      minute: match?.[ 15 ] ? parseInt(match[ 15 ], 10) : null,
      second: match?.[ 17 ] ? parseInt(match[ 17 ], 10) : null,
    }
  }
  
  static getTime(url: string): number | null {
    if (this.isVkUrl(url)) {
      const { hour, minute, second } = this.getUrlData(url)
      if (hour === null && minute === null && second === null) return null
      return (
        (!hour || isNaN(hour) ? 0 : hour * 3600) +
        (!minute || isNaN(minute) ? 0 : minute * 60) +
        (!second || isNaN(second) ? 0 : second)
      );
    }
    
    return null
  }
  
  static generateUrl(data: GenerateVkUrlParams): string | null {
    const { oid, id, totalSeconds } = data
    if (oid === null || id === null) return null
    
    const { hour, minute, second } = totalSeconds ? getTimeFromTotal({ totalSeconds }) : data;
    const time = (hour || minute || second) && `&t=${hour && `${hour}h`}${minute && `${minute}m`}${second && `${second}s`}`;
    return `https://vk.com/video_ext.php?oid=-${oid}&id=${id}&autoplay=0${time}`
  }
}
