import React from 'react';
import styled from "@emotion/styled";
import Text from "./text";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  headerText?: string
}

function Block(props: IProps) {
  const { children, headerText, ...rest } = props

  return (
    <MainBlock {...rest}>
      <Text color={"#686A79"} size={'medium'}>{headerText}</Text>
      {children}
    </MainBlock>
  );
}

export default Block;

const MainBlock = styled.div`
  width: 100%;
  padding-inline: 16px;
  padding-block: 6px;
  background: #212738;
  gap: 8px;

  display: flex;

  position: relative;
`
