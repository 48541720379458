import { useAuth } from "../../../auth/ui/hooks/useAuth";
import { useEffect, useMemo } from "react";
import { setActiveBoard, setActiveTemplate, setTournamentId } from "../../store/templates/templates";
import { fetchUserTemplates } from "../../store/templates/actions";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import useTemplates from "./useTemplates";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { useMatch } from "react-router-dom";
import { Pages } from "../../../navigation/domain/enums/pages";

export function useLoadTemplates() {
  const dispatch = useDispatch<AppDispatch>()
  const { templateId } = useMatch(Pages.TEMPLATE.BOARDS + '/*')?.params ?? {}
  const { boardId } = useMatch(Pages.TEMPLATE.EDITOR + '/*')?.params ?? {}
  
  const { info } = useOrganizer()
  const { user } = useAuth()
  const { isLoaded: isTemplatesLoaded, tournamentId } = useTemplates()
  
  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'
  
  const isAdmin = useMemo(() => {
    if (isDev) return true
    if (!user) return false
    return user.id === 20
  }, [isDev, user])
  
  
  useEffect(() => {
    if (!info) return
    if (isTemplatesLoaded && info.id === tournamentId) return
    dispatch(setTournamentId({ id: info.id }))
    dispatch(fetchUserTemplates({ ownerId: info.id, visibility: isAdmin ? ['superAdmin', 'all'] : ['all'] }))
  }, [dispatch, info, isTemplatesLoaded, isAdmin, tournamentId])
  
  useEffect(() => {
    if (templateId) {
      dispatch(setActiveTemplate({ id: parseInt(templateId) }))
    }
    
    if (boardId) {
      dispatch(setActiveBoard({ id: parseInt(boardId) }))
    }
  }, [boardId, dispatch, templateId]);
}
