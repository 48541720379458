import { Board } from "../../../core/domain/interfaces/supabase/overlay/entity";

export default function sortBoards(boards: Board[]) {
  if (boards.length === 0) return [];
  
  const favorite = boards.filter((b) => b.isFavorite);
  const others = boards.filter((b) => !b.isFavorite);
  
  const sortByOrderThenTime = (a: Board, b: Board) => {
    if (a.order === b.order) {
      return a.createdAt.getTime() - b.createdAt.getTime();
    }
    return a.order - b.order;
  };
  
  return [
    ...favorite.sort(sortByOrderThenTime),
    ...others.sort(sortByOrderThenTime),
  ];
}
