import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  canvas {
    box-shadow: 0 1px 3px 0 #00000026, 0 1px 2px 0 #0000004D;
    border: 1px solid #E2E3E4;
    border-radius: 6px;
  }
`
export const MainBlock = styled.div`
  width: 100%;
  height: 100%;
  gap: 8px;
`
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
`
export const RightBlock = styled.div`
  display: flex;
  gap: 20px;
`
export const TemplateName = styled.div`
  background: #E2E3E4;
  border: 1px solid #00000008;
  border-radius: 6px;
  padding: 2px 8px;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
  text-align: left;
`
export const Button = styled.button<{ color: string }>`
  padding: 2px 8px;
  border-radius: 6px;
  border: 1px solid #00000008;
  background: ${(props) => props.color};
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
  text-align: left;

  &:disabled {
    background: #E2E3E4;
    color: #9093AA;
    cursor: not-allowed;

    &:active {
      opacity: unset;
      box-shadow: unset;
      scale: unset;
    }
  }

  &:active {
    opacity: 0.8;
    box-shadow: 0 -1px 3px 0 #00000026, -1px -1px 2px 0 #0000004D;
    scale: 90%;
  }
`
export const ZoomContainer = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  
  height: 32px;
  padding: 7px 10px 7px 10px;
  border-radius: 8px;
  background-color: #E2E3E4;
  
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const onHover = css`
  color: #242424;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: #DBB400;
  }
`
