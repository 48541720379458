interface ICategory {
  label: string,
  value: string,
}

export const categoryList: ICategory[] = [
  {
    label: 'Обычные',
    value: 'common',
  }, {
    label: 'Составные',
    value: 'complex',
  }, {
    label: 'События',
    value: 'events',
  }, {
    label: 'Карусель',
    value: 'carousel',
  },
]
