/** @jsxImportSource @emotion/react */
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { IconButton, tooltipStyles } from "./styles";
import UndoIcon from "../../../../../ud-ui/components/icon/undo-icon";
import React, { useEffect } from "react";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import InteractiveClickableIcon from "./interactiveClickableIcon";
import { useSelector } from "react-redux";
import { selectActiveModal, selectFabricManager } from "../../../../store/editor/selectors";

export function Undo() {
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager);
  const [isActive, setIsActive] = React.useState(history.isInit() ?? history.undoIsActive())
  
  const onClickUndo = () => {
    if (!fabricManager) return
    history.undo()
  }
  
  
  useEffect(() => {
    history.history.subscribe(() => {
      setIsActive(history.undoIsActive())
    })
    history.index.subscribe(() => {
      setIsActive(history.undoIsActive())
    })
  }, [])
  
  
  return (
    <UDTooltip show={!activeModal} css={tooltipStyles} text={"Назад"}>
    <IconButton onClick={onClickUndo}>
      <InteractiveClickableIcon isActive={isActive} child={UndoIcon}/>
    </IconButton>
  </UDTooltip>
  );
}
