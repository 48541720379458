import { GameDetailsTeam, GameDetailsTeamMember } from "../domain/interfaces/GameDetails";

export function getMembers(teams: [GameDetailsTeam, GameDetailsTeam]): Record<number, GameDetailsTeamMember> {
  return teams.reduce((result, team) => {
    const teamMembersById = team.members.reduce((teamResult, member) => {
      return {
        ...teamResult,
        [ member.id ]: member,
      };
    }, {});
    
    return {
      ...result,
      ...teamMembersById,
    };
  }, {})
}
