export default function boardNameValidatorOnEdit(boardName?: string, boardsList?: string[], oldName?: string) {
  if (!boardName) {
    return 'Название табло не может быть пустым'
  }
  if (boardName.length <= 0) {
    return 'Название табло не может быть пустым'
  }
  if (boardsList && boardName !== oldName && boardsList.includes(boardName)) {
    return 'Такое название табло уже существует'
  }
  return undefined
}
