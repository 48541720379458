interface IYoutubeUrlData {
  videoId: string | null
  start: number | null
}

export class TransformYoutubeUrl {
  static isYoutubeUrl(url: string): boolean {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    return regex.test(url);
  }
  
  static transformUrl(url: string): string | null {
    if (this.isValidUrl(url)) {
      return url;
    } else {
      const data = this.getUrlData(url)
      return this.generateUrl(data)
    }
  }
  
  static isValidUrl(url: string): boolean {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com)\/.+$/;
    return regex.test(url);
  }
  
  static getUrlData(url: string): IYoutubeUrlData {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/((.+?)(&t=)(.+)|(.+?))$/;
    const match = url.match(regex.source);
    return {
      videoId: match?.[ 4 ] ?? null,
      start: match?.[ 7 ] ? parseInt(match[ 7 ], 10) : null,
    }
  }
  
  static getUrlTime(url: string): number | null {
    return this.getUrlData(url).start;
  }
  
  static generateUrl(data: { videoId: string | null, start: number | null }): string | null {
    const { videoId, start } = data
    if (videoId === null) return null
    const time = start && `&amp;start=${start}`;
    return `https://www.youtube.com/embed/${videoId}${time}`
  }
}
