import { Organizer } from "../domain/interfaces/Organizer";
import { createAsyncThunk } from "@reduxjs/toolkit";
import organizersRepository from "../domain/repositories/organizersRepository";
import { ActiveTournamentPlan } from "../domain/interfaces/plan";
import { GivePlan, TopupBalance } from "../domain/interfaces/actions";
import { ResponseGivePlan, ResponseTopupBalance } from "../domain/interfaces/response";
import plans from "../domain/resources/Plans";

const PREFIX = 'organizers';

export const fetchOrganizers = createAsyncThunk<Organizer[], undefined>(
  `${PREFIX}/fetchOrganizers`,
  async (_, { rejectWithValue }) => {
    try {
      return organizersRepository.fetchOrganizers();
    } catch (error) {
      console.error(`${PREFIX}/fetchOrganizers error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const fetchActiveTournamentPlan = createAsyncThunk<ActiveTournamentPlan | null, { tournamentId: number }>(
  `${PREFIX}/fetchActiveTournamentPlan`,
  async (payload, { rejectWithValue }) => {
    try {
      const result = await organizersRepository.fetchOrganizerPlan(payload.tournamentId)
      return result ? { ...result, ownerId: payload.tournamentId } : null;
    } catch (error) {
      console.error(`${PREFIX}/fetchActiveTournamentPlan error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const topupBalance = createAsyncThunk<ResponseTopupBalance, TopupBalance>(
  `${PREFIX}/topupBalance`,
  async (payload, { rejectWithValue }) => {
    try {
      return await plans.topupBalance(payload);
    } catch (error) {
      console.error(`${PREFIX}/topupBalance error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);

export const givePlan = createAsyncThunk<ResponseGivePlan, GivePlan>(
  `${PREFIX}/givePlan`,
  async (payload, { rejectWithValue }) => {
    try {
      const result = await plans.givePlan(payload)
      if (typeof result === 'string') {
        console.error({ event: 'givePlan', message: result, data: { payload } })
        return rejectWithValue(result)
      }
      
      return result;
    } catch (error) {
      console.error(`${PREFIX}/givePlan error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);
