import styled from "@emotion/styled";

export const CanvasWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 80;
  width: 100%;
  height: 100%;
`
