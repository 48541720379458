/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import UDText from "../../../../../../ud-ui/components/text";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentDate } from "../../../../../store/selectors";
import ArrowBracketIcon from "../../../../../../ud-ui/components/icon/arrow-bracket-icon";
import { EmptyButton } from "../../../../../../template-editor/ui/components/editor/rightToolbar/styles";
import { ButtonStyles } from "./styles";
import { AppDispatch } from "../../../../../../../store/store";
import { setCurrentDate } from "../../../../../store";

function Header() {
  const dispatch = useDispatch<AppDispatch>()
  const { month: currentMonth, year: currentYear } = useSelector(selectCurrentDate)
  const date = new Date(currentYear, currentMonth);
  const nextMonth = useCallback(() => {
    const year = currentMonth === 11 ? currentYear + 1 : currentYear;
    const month = currentMonth === 11 ? 0 : currentMonth + 1;
    dispatch(setCurrentDate({ month, year }))
  }, [currentMonth, currentYear, dispatch]);
  const prevMonth = useCallback(() => {
    const year = currentMonth === 0 ? currentYear - 1 : currentYear;
    const month = currentMonth === 0 ? 11 : currentMonth - 1;
    dispatch(setCurrentDate({ month, year }))
  }, [currentMonth, currentYear, dispatch]);
  
  return (
    <div className={'d-flex  justify-content-between'}>
      <div>
        <UDText type={'subhead'} style={{ textTransform: 'uppercase' }}>
          {date.toLocaleString('ru-RU', { month: 'long' })}
          {' '}
          {date.toLocaleString('ru-RU', { year: 'numeric' })}
        </UDText>
      </div>
      <div className={'d-flex'} style={{ gap: 16 }}>
        <EmptyButton onClick={prevMonth} css={ButtonStyles}>
          <ArrowBracketIcon/>
        </EmptyButton>
        <EmptyButton onClick={nextMonth} css={ButtonStyles}>
          <ArrowBracketIcon direction={'left'}/>
        </EmptyButton>
      </div>
    </div>
  );
}

export default Header;
