import { ScheduleSliceState } from "../index";

export const markEmptyDays = (state: ScheduleSliceState) => {
  for (const week of state.weeks) {
    for (const day of week) {
      if (!day.date) continue
      day.status = undefined
    }
  }
}
