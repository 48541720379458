import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../store/store";

const selectTime = (state: RootState) => state.overlay.time;

export const selectPassedTime = createSelector(
  selectTime,
  (state) => state.passedTime,
)

export const selectPeriod = createSelector(
  selectTime,
  (state) => state.period,
)

export const selectIsActive = createSelector(
  selectTime,
  (state) => state.isActive,
)

export const selectStartTime = createSelector(
  selectTime,
  (state) => state.startTime,
)

export const selectLastChange = createSelector(
  selectTime,
  (state) => state.lastChange,
)
