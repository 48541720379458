import { MatchDTO } from "../interfaces/dtos";
import { Calendar } from "../interfaces/calendar";
import { Championship } from "../interfaces/championship";
import { mapChampionshipSettings } from "../../../tourneys/domain/data-mappers/championship-game-teams-mapper";
import { GameTeam } from "../../../games/domain/interfaces/Game";
import { SportType } from "../../../core/domain/enums/SportType";

export function matchesMapper(matches: MatchDTO[]): Calendar {
  const calendar: Calendar = {}
  
  for (const match of matches) {
    if (!match || !match.teams || !match.teams?.length || !match.date) {
      continue
    }
    
    const date = new Date(match.date);
    const championship = match.championship
    
    const mappedChampionship: Championship = {
      id: championship.id,
      name: championship.name,
      tournamentId: championship.tournament.id,
      address: championship.tournament.address,
      settings: mapChampionshipSettings(championship.settings),
    }
    
    const teams = match.teams?.map((team) => {
      const score = match.teamMatches?.find(tm => tm.team?.id === team.id)?.score ?? undefined
      return {
        id: team.id,
        players: [],
        score,
        name: team.name,
        logo: team.emblem ? {
          url: team.emblem.externalUrl,
        } : undefined,
      }
    });
    
    const matchMapped = {
      id: match.id,
      date: new Date(match.date),
      teams: [teams[ 0 ], teams[ 1 ]] as [GameTeam | null, GameTeam | null],
      hasResults: false,
      sportType: match.sportType as SportType,
      address: championship.tournament.address.city ?? undefined,
    };
    
    const month = date.toLocaleString('ru-RU', { month: 'long' });
    calendar[ month ] = calendar[ month ] || [];
    let day = calendar[ month ].find(d => d.date.getDate() === date.getDate());
    if (!day) {
      day = { date, matches: [] };
      calendar[ month ].push(day);
    }
    
    day.matches.push({
      ...matchMapped,
      championship: mappedChampionship,
    });
  }
  
  return calendar
}
