import React from 'react';
import { CardContainer } from './styles';
import PlayerCardHeader from './player-card-header';
import { TournamentUser } from '../../../../../domain/interfaces/TournamentUser';
import { DivProps } from "../../../../../../../typings";

type Props = {
  teamId: number;
  player: TournamentUser;
  containerProps?: Omit<DivProps, 'children'>;
  index: number;
};

const PlayersComponentsPlayerCard = (props: Props) => {
  const { teamId, player, index, containerProps } =
    props;
  
  return (
    <CardContainer className='mb-4 last-mb-0'>
      <PlayerCardHeader
        teamId={teamId}
        player={player}
        containerProps={containerProps}
        index={index}
      />
    </CardContainer>
  );
};

export default PlayersComponentsPlayerCard;
