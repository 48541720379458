import React from 'react';
import { useDispatch } from 'react-redux';
import UDIcon from '../../../../ud-ui/components/icon';
import Block from '../components/block';
import Counter from '../components/counter';
import { setGoals } from "../../../store/gameInfo/gameInfo";
import useGameInfo from "../../hooks/useGameInfo";

export function Score() {
  const dispatch = useDispatch()
  const { goals } = useGameInfo()
  
  const setScoreFirstTeam = (num: number) => {
    dispatch(setGoals({ ...goals, first: num }))
  }
  
  const setScoreSecondTeam = (num: number) => {
    dispatch(setGoals({ ...goals, second: num }))
  }
  
  const onClickReset = () => {
    dispatch(setGoals({ first: 0, second: 0 }))
  }
  
  return <Block className={'flex-center flex-column'} headerText={'Счёт'}>
    <div className={'d-flex'} style={{ width: '100%', justifyContent: 'space-between' }}>
      <Counter value={goals.first} setValue={setScoreFirstTeam}/>
      <UDIcon componentProps={{ onClick: onClickReset }} name={'cyclic-arrow'}/>
      <Counter value={goals.second} setValue={setScoreSecondTeam}/>
    </div>
  </Block>;
}
