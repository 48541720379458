import { EventPlaceholder } from "../../../../../../ud-ui/components/fabric-canvas/types/placeholders/createArgument";

interface IItem extends EventPlaceholder {
  listName: string
}

export const eventPlaceholderList: IItem[] = [
  {
    indicator: 'playerName',
    blockText: 'ФИО игрока',
    listName: 'ФИО игрока',
    type: 'text',
    playerIndex: 0,
  },
  {
    indicator: 'playerAvatar',
    blockText: 'Аватар игрока',
    listName: 'Аватар игрока',
    type: 'image',
    playerIndex: 0,
  },
  {
    indicator: 'playerNumber',
    blockText: 'Номер игрока',
    listName: 'Номер игрока',
    type: 'text',
    playerIndex: 0,
  },
  {
    indicator: 'playerRedCardCount',
    blockText: 'Количество красных карточек',
    listName: 'Количество красных карточек',
    type: 'text',
    playerIndex: 0,
  },
  {
    indicator: 'playerYellowCardCount',
    blockText: 'Количество жёлтых карточек',
    listName: 'Количество жёлтых карточек',
    type: 'text',
    playerIndex: 0,
  },
  {
    indicator: 'teamName',
    blockText: 'Название команды',
    listName: 'Название команды',
    type: 'text',
    teamReverse: false,
  },
  {
    indicator: 'teamLogo',
    blockText: 'Логотип команды',
    listName: 'Логотип команды',
    type: 'image',
    teamReverse: false,
  },
  {
    indicator: 'score',
    blockText: 'Счёт команды',
    listName: 'Счёт команды',
    type: 'text',
    teamReverse: false,
  },
  {
    indicator: 'fouls',
    blockText: 'Фолы',
    listName: 'Фолы',
    type: 'text',
    teamReverse: false,
  },
  {
    indicator: 'currentPeriod',
    blockText: 'Текущий тайм',
    listName: 'Текущий тайм',
    type: 'text',
  },
  {
    indicator: 'eventMinute',
    blockText: 'Минута события',
    listName: 'Минута события',
    type: 'text',
  },
]
