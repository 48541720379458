import React from 'react';
import * as S from './styles';

type PlayerPhotoProps = {
  url?: string;
  name?: string;
  size?: number;
  className?: string;
};

const PlayerPhoto = (props: PlayerPhotoProps) => (
  <S.PlayerPhotoContainer
    className={props.className}
    size={props.size}
    title={props.name}
  >
    <S.PlayerPhoto photoUrl={props.url}/>
  </S.PlayerPhotoContainer>
);

export default PlayerPhoto;
