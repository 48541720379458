import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export const gamesStoreSelector = (state: RootState) => state.games;

export const gameDetailsIsLoadingSelector = createSelector(gamesStoreSelector, (state) => state.details.isLoading);
export const gameDetailsDataSelector = createSelector(gamesStoreSelector, (state) => state.details.data);
export const gameEventsChangedSelector = createSelector(gamesStoreSelector, (state) => state.details.eventsChanged);
export const gameDetailsIsSavingSelector = createSelector(gamesStoreSelector, (state) => state.details.isSaving);
export const selectTimestamp = createSelector(
  [gamesStoreSelector],
  (state) => state.details.data?.timestamps ?? {},
);
