import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { TemplatesSliceState } from "./templates";
import {
  addLike,
  addView,
  copyBoard,
  copyTemplate,
  createBoard,
  createTemplate,
  deleteBoard,
  deleteTemplate,
  fetchCatalogTemplates,
  fetchUserTemplates,
  updateBoard,
  updateTemplate,
} from "./actions";
import sortTemplates from "../../domain/utils/sortTemplates";
import { toast } from "react-toastify";
import sortBoards from "../../domain/utils/sortBoards";

type TemplateEditorReducerBuilder = ActionReducerMapBuilder<TemplatesSliceState>;

export function createFetchUserTemplatesReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(fetchUserTemplates.pending, (state) => {
    state.isLoading = true;
    state.isLoaded = false;
  });
  
  builder.addCase(fetchUserTemplates.fulfilled, (state, action) => {
    state.isLoading = false;
    state.isLoaded = true;
    const templates = action.payload
    if (!templates) {return;}
    
    const mappedTemplates = templates.map(template => {
      template.boards = sortBoards(template.boards)
      return template
    });
    
    state.templates = sortTemplates(mappedTemplates) as any
  });
  
  builder.addCase(fetchUserTemplates.rejected, (state) => {
    state.isLoading = false;
    state.isLoaded = true;
    
    toast.error('Не удалось загрузить шаблоны')
  });
}

export function createCreateTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(createTemplate.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'create template'
  });
  
  builder.addCase(createTemplate.fulfilled, (state, action) => {
    if (action.payload.access === 'private') {
      state.templates.push(action.payload as any)
    } else if (action.payload.access === 'public' || action.payload.access === 'default') {
      state.catalog?.push(action.payload as any)
    }
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(createTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось создать шаблон')
  });
}

export function createDeleteTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(deleteTemplate.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'delete template'
  });
  
  builder.addCase(deleteTemplate.fulfilled, (state, action) => {
    state.templates = state.templates.filter(t => t.id !== action.payload.id)
    state.catalog = state.catalog?.filter(t => t.id !== action.payload.id)
    state.activeTemplateId = undefined;
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(deleteTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось удалить шаблон')
  });
}

export function createUpdateTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(updateTemplate.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'update template'
  });
  
  builder.addCase(updateTemplate.fulfilled, (state, action) => {
    const oldAccess = state.templates.find(t => t.id === action.payload.id)?.access ?? state.catalog?.find(t => t.id === action.payload.id)?.access
    const currentAccess = action.payload.access
    switch (oldAccess) {
      case 'private': {
        if (currentAccess === 'default') {
          state.templates = state.templates.filter(t => t.id !== action.payload.id)
        }
        if (currentAccess === 'public' || currentAccess === 'default') {
          state.catalog?.push(action.payload as any)
        }
        break;
      }
      case 'public': {
        if (currentAccess === 'private') {
          state.catalog = state.catalog?.filter(t => t.id !== action.payload.id)
        }
        if (currentAccess === 'default') {
          state.templates = state.templates.filter(t => t.id !== action.payload.id) as any
        }
        break;
      }
      case 'default': {
        if (currentAccess === 'private') {
          state.catalog = state.catalog?.filter(t => t.id !== action.payload.id)
        }
        if (currentAccess === 'public') {
          const hasInTemplates = state.templates.some(t => t.id === action.payload.id)
          if (!hasInTemplates) {
            state.templates.push(action.payload as any)
          }
        }
        break;
      }
    }
    
    state.templates = state.templates.map(t => t.id === action.payload.id ? action.payload : t) as any
    state.catalog = state.catalog?.map(t => t.id === action.payload.id ? action.payload : t) as any
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(updateTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось обновить шаблон')
  });
}

export function createCreateBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(createBoard.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'create board'
  });
  
  builder.addCase(createBoard.fulfilled, (state, action) => {
    state.templates = state.templates.map(template => {
      if (template.id === state.activeTemplateId) {
        template.boards.push(action.payload as any)
      }
      return template
    })
    
    state.catalog = state.catalog?.map(template => {
      if (template.id === state.activeTemplateId) {
        template.boards.push(action.payload as any)
      }
      return template
    })
    
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(createBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось создать табло')
  });
}

export function createDeleteBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(deleteBoard.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'delete board'
  });
  
  builder.addCase(deleteBoard.fulfilled, (state, action) => {
    state.templates = state.templates.map(template => {
      if (template.id === state.activeTemplateId) {
        template.boards = template.boards.filter(board => board.id !== action.payload.id)
      }
      return template
    })
    
    state.catalog = state.catalog?.map(template => {
      if (template.id === state.activeTemplateId) {
        template.boards = template.boards.filter(board => board.id !== action.payload.id)
      }
      return template
    })
    
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(deleteBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось удалить табло')
  });
}

export function createUpdateBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(updateBoard.pending, (state) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = 'update board'
  });
  
  builder.addCase(updateBoard.fulfilled, (state, action) => {
    state.templates = state.templates.map(template => {
      if (template.id === state.activeTemplateId) {
        template.boards = template.boards.map(board => board.id === action.payload.id ? action.payload : board) as any
      }
      return template
    })
    
    state.catalog = state.catalog?.map(template => {
      if (template.id === state.activeTemplateId) {
        template.boards = template.boards.map(board => board.id === action.payload.id ? action.payload : board) as any
      }
      return template
    })
    
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(updateBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    
    toast.error('Не удалось обновить табло')
  });
}

export function createCopyTemplateReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(copyTemplate.pending, (state, action) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = action.meta.arg.process ?? 'copy template'
  });
  
  builder.addCase(copyTemplate.fulfilled, (state, action) => {
      const template = action.payload
      template.boards = sortBoards(template.boards)
      state.templates.push(template as any)
      
      state.isProcessed = false;
      state.isFinished = true;
    },
  );
  
  builder.addCase(copyTemplate.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    toast.error('Не удалось создать копию')
  });
}

export function createCopyBoardReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(copyBoard.pending, (state, action) => {
    state.isProcessed = true;
    state.isFinished = false;
    state.process = action.meta.arg.process ?? 'copy board'
  });
  
  builder.addCase(copyBoard.fulfilled, (state, action) => {
    state.templates = state.templates.map(template => {
      if (template.id === action.payload.templateId) {
        template.boards.push(action.payload as any)
      }
      return template
    })
    
    state.catalog = state.catalog?.map(template => {
      if (template.id === action.payload.templateId) {
        template.boards.push(action.payload as any)
      }
      return template
    })
    
    state.isProcessed = false;
    state.isFinished = true;
  });
  
  builder.addCase(copyBoard.rejected, (state) => {
    state.isProcessed = false;
    state.isFinished = false;
    toast.error('Не удалось создать копию')
  });
}

export function createFetchCatalogTemplatesReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(fetchCatalogTemplates.fulfilled, (state, action) => {
    const templates = action.payload
    if (!templates) {
      toast.error('Не удалось загрузить шаблоны каталога')
      return;
    }
    
    const mappedTemplates = templates.map(template => {
      template.boards = sortBoards(template.boards)
      return template
    });
    
    state.catalog = sortTemplates(mappedTemplates) as any
  });
  
  builder.addCase(fetchCatalogTemplates.rejected, (state) => {
    toast.error('Не удалось загрузить шаблоны каталога')
  });
}

export function createAddLikeReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(addLike.fulfilled, (state, action) => {
    state.catalog = state.catalog?.map(template => {
      if (template.id === action.payload.id) {
        action.payload.boards = sortBoards(action.payload.boards)
        return action.payload
      }
      return template
    }) ?? [] as any
  });
  
  builder.addCase(addLike.rejected, (state) => {
    toast.error('Не удалось поставить лайк')
  });
}

export function createAddViewReducer(builder: TemplateEditorReducerBuilder) {
  builder.addCase(addView.fulfilled, (state, action) => {
    state.catalog = state.catalog?.map(template => {
      if (template.id === action.payload.id) {
        action.payload.boards = sortBoards(action.payload.boards)
        return action.payload
      }
      return template
    }) ?? [] as any
  });
  
  builder.addCase(addView.rejected, (state) => {
    toast.error('Не удалось засчитать просмотр')
  });
}
