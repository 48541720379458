import supabase from "../../../core/supabase/supabase";

export async function getFouls(matchId: number) {
  const data = await supabase.matchChronology.getRow(matchId)
  const currentPeriod = data?.currentPeriod
  const chronology = data?.chronology
  let firstTeam = 0
  let secondTeam = 0
  for (const receivedEvent of chronology ?? []) {
    if (receivedEvent.type === 'FOUL' && receivedEvent.period === currentPeriod) {
      if (receivedEvent.teamId === data?.firstTeamId) {
        firstTeam += 1
      } else {
        secondTeam += 1
      }
    }
  }
  
  return { first: firstTeam, second: secondTeam }
}
