/** @jsxImportSource @emotion/react */
import React from 'react';
import { DaysLine, DaysWrapper } from "./styles";
import Day from "../day";
import { useSelector } from "react-redux";
import { selectLoad, selectWeeks } from "../../../../../store/selectors";
import { ClipLoader } from "react-spinners";

function Days() {
  const weeks = useSelector(selectWeeks)
  const { isLoading } = useSelector(selectLoad)
  
  return (
    <DaysWrapper>
      {isLoading ? (
        <div className={'flex-center w-100'} style={{ height: 196 }}>
          <ClipLoader/>
        </div>
      ) : (
        weeks.map((week, index) => {
          return (
            <DaysLine key={index}>
              {week.map((day) => {
                return <Day {...day} />;
              })}
            </DaysLine>
          );
        }))}
    </DaysWrapper>
  );
}

export default Days;
