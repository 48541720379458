import { Player, Team } from "../../domain/interfaces/player";
import { useTranslation } from "react-i18next";
import { millisToMinutesAndSeconds } from "../../../stream/domain/utils/convertTime";
import UDText from "../../../ud-ui/components/text";
import { EventForMomentCard } from "../../domain/interfaces/event";
import { format } from "date-fns";
import { useMemo } from "react";
import ru from "date-fns/locale/ru";
import MomentPlayer from "./moment-player";
import styled from "@emotion/styled";

interface PlayerForMomentCard extends Partial<Player> {
  name: string
  surname: string
  middleName: string
  positionsInTeams: {
    position: string | number
    number: string | number
    team: Team
  }[]
}

interface IProps {
  player: PlayerForMomentCard
  event: EventForMomentCard
}

function MomentCard(props: IProps) {
  const { t } = useTranslation();
  
  const {
    event: {
      id,
      videoUrl: url,
      type,
      second,
      match,
      period,
    },
  } = props
  
  const matchDate = useMemo(() => {
    return match.date && new Date(match.date)
  }, [match.date]);
  
  const matchScore = useMemo(() => {
    const firstTeam = match.teamMatches?.[ 0 ] && {
      name: match.teamMatches[ 0 ].team?.name,
      score: match.teamMatches[ 0 ]?.score,
    }
    const secondTeam = match.teamMatches?.[ 1 ] && {
      name: match.teamMatches[ 1 ].team?.name,
      score: match.teamMatches[ 1 ]?.score,
    }
    
    return (firstTeam && secondTeam) && `${firstTeam.name} ${firstTeam.score}:${secondTeam.score} ${secondTeam.name}`
  }, [match.teamMatches]);
  
  return (
    <div className={'flex-column gap-2 align-items-center'}>
      <MomentPlayer url={url}/>
      <Text type={'subhead'}>ID: {id}</Text>
      <div className={'flex-column align-items-center'}>
        {matchDate ? (
          <Text type={'subhead'} color={'gray'}>{format(
            new Date(matchDate),
            'dd MMMM HH:mm',
            { locale: ru },
          )}</Text>
        ) : (
          'Дата матча неизвестна'
        )}
        <Text type={'subhead'}>{match.championship?.name ?? 'Турнир неизвестен'}</Text>
        {matchScore ? (
          <Text type={'headline'}>{matchScore}</Text>
        ) : (
          'Команды не определены'
        )}
        <div className={'flex-column align-items-center'}>
          {/*<Text type={'subhead'}>{player.surname} {player.name} {player.middleName}</Text>*/}
          <div className={'flex-row gap-2'}>
            <Text type={'subhead'}>{period !== null && t(`games.periods.${period}`)}</Text>
            <Text type={'subhead'}>{millisToMinutesAndSeconds((second ?? 0) * 1000)}</Text>
            <Text type={'subhead'}>{t(`games.eventTypes.${type}`)}</Text>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MomentCard;

const Text = styled(UDText)`
  text-align: center;
`
