import styled from '@emotion/styled';
import React from 'react';
import UDIcon from '../../../../ud-ui/components/icon';
import Block from '../components/block';
import { Separator } from '../components/separator';
import Text from '../components/text';
import useGameInfo from "../../hooks/useGameInfo";

export function TeamsName() {
  const { teams } = useGameInfo()
  
  return <div className={'d-flex'}>
    <Separator colorDown={'#FFF'}/>
    <Block className={'flex-center'} style={{ gap: 4, height: 60, width: '100%', paddingInline: 8 }}>
      <TeamName className={'flex-center'}>
        <Text color={'#FFF'} size={'large'} style={{ maxWidth: '100%' }}>{teams?.first.name}</Text>
      </TeamName>
      <UDIcon componentProps={{ style: { flexGrow: 2, flexBasis: 0 } }} name={'cyclic-arrow'}/>
      <TeamName className={'flex-center'}>
        <Text color={'#FFF'} size={'large'} style={{ maxWidth: '100%' }}>{teams?.second.name}</Text>
      </TeamName>
    </Block>
    <Separator colorDown={'#FFF'}/>
  </div>;
}

const TeamName = styled.div`
  flex-grow: 48;
  flex-basis: 0;
  overflow: hidden;
  max-width: 100%;
`;
