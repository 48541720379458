import Selector from "../../../elements/selector";
import React, { useCallback } from "react";
import history from "../../../../../../ud-ui/components/fabric-canvas/addons/history";
import { selectFabricManager } from "../../../../../store/editor/selectors";
import { useSelector } from "react-redux";
import UDText from "../../../../../../ud-ui/components/text";

interface IProps {
  onChange: (reverse: boolean) => void
  value: boolean
}

function ChoosingReverse(props: IProps) {
  const { onChange, value } = props
  const manager = useSelector(selectFabricManager)
  
  const onFocusField = useCallback(() => {
    manager?.render()
    history.saveState()
  }, [manager])
  
  return (
    <>
      <UDText type={'caption-1'}>Команда события</UDText>
      <Selector
        onFocus={onFocusField}
        styledEachOption
        onChange={(event) => {onChange((event?.value))}}
        options={options}
        value={options.find((option) => option.value === value)}
      />
    </>
  );
}

export default ChoosingReverse;

const options = [
  { label: 'Команда вызвавшая событие', value: false },
  { label: 'Противоположная команда', value: true },
]
