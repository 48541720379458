import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import soccer from "../../../../ud-ui/components/icon/icons/soccer-ball.svg";
import voleyball from "../../../../ud-ui/components/icon/icons/voleyball-ball.svg";
import basketball from "../../../../ud-ui/components/icon/icons/basketball-ball.svg";
import EmptyButton from "../../../../ud-ui/components/emptyButton";

export const MainBlock = styled.div`
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  //overflow-y: auto;
  background: #1A1A29;
  gap: 10px;
  
  position: relative;
  /* Задаем стиль для скроллбаров */
  
  &::-webkit-scrollbar {
    /* Устанавливаем минимальную ширину скроллбара */
    width: 4px;
    height: 4px;
    
    /* Делаем скроллбар прозрачным */
    background-color: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    /* Черный цвет с прозрачностью */
    background-color: rgba(0, 0, 0, 0.7);
    
    /* Закругленные углы */
    border-radius: 10px;
  }
  
  /* Поддержка Firefox */
  @-moz-document url-prefix() {
    scrollbar-width: thin; /* Тонкая ширина скроллбара */
    scrollbar-color: rgba(0, 0, 0, 0.7) transparent; /* Черный цвет с прозрачностью и прозрачный фон */
  }
`
export const loaderAnimation = keyframes`
  from {
    background-image: url(${soccer});
    transform: translateY(0);
  }
  12.5% {
    background-image: url(${soccer});
    transform: translateY(-4px);
  }
  25% {
    background-image: url(${soccer});
    transform: translateY(0);
  }
  37.5% {
    background-image: url(${voleyball});
    transform: translateY(0);
  }
  50% {
    background-image: url(${voleyball});
    transform: translateY(-4px);
  }
  62.5% {
    background-image: url(${voleyball});
    transform: translateY(0);
  }
  75% {
    background-image: url(${basketball});
    transform: translateY(0);
  }
  87.5% {
    background-image: url(${basketball});
    transform: translateY(-4px);
  }
  to {
    background-image: url(${basketball});
    transform: translateY(0);
  }
`;
export const Button = styled.button<{ color: string }>`
  outline: unset;
  border: unset;
  
  gap: 10px;
  background: ${({ color }) => color};
  padding: 8px;
  position: relative;
  cursor: pointer;
  color: white;
  min-height: 30px;
  
  &:disabled {
    background: #686A79;
    color: #9093AA;
    cursor: not-allowed;
    
    &:hover {
      &:after {
        background: rgba(0, 0, 0, 0);
      }
    }
  }
  
  &:hover {
    &:after {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    
    transition: background 0.2s ease-in-out;
  }
  
  
  &.loading {
    position: relative;
    user-select: none;
    pointer-events: none;
    
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
    
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      margin-top: -7px;
      margin-left: -7px;
      background-size: 14px 14px;
      animation: ${loaderAnimation} 3s ease infinite alternate;
      background: rgba(0, 0, 0, 0);
      transition: background 0s ease-in-out;
    }
  }
`

export const OpenPanelWrapper = styled.div<{ position: 'left' | 'right', opacity?: number }>`
  width: 25%;
  height: 100%;
  z-index: 9999;

  position: absolute;
  left: ${({ position }) => position === 'left' ? 0 : 'unset'};
  right: ${({ position }) => position === 'right' ? 0 : 'unset'};
  top: 50%;
  transform: ${({ position }) => position === 'left' ? 'translateX(-100%)' : ''} translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: end;


  opacity: ${({ opacity }) => opacity ?? 0};
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`

export const HideButton = styled(EmptyButton)`
  position: absolute;
  top: 50%;
  z-index: 9999;
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  
  //transform: translateX(calc(-50%));
  
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 88px;
    background: #D9B244;
    border-radius: 8px 0 0 8px;
    transition: background-color 0.2s ease-in-out;
    
    &:hover {
      background: white;
    }
  }
`
