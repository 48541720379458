import {
  CarouselPlaceholder,
} from "../../../../../../ud-ui/components/fabric-canvas/types/placeholders/createArgument";

interface IItem extends CarouselPlaceholder {
  listName: string
}

export const carouselPlaceholderList: IItem[] = [
  {
    blockText: 'Аватар игрока',
    listName: 'Аватар',
    type: 'image',
    indicator: 'carousel-avatar',
    teamIndex: 0,
  },
  {
    blockText: 'ФИО игрока',
    listName: 'ФИО',
    type: 'text',
    indicator: 'carousel-name',
    teamIndex: 0,
  },
  {
    blockText: 'Номер игрока',
    listName: 'Номер',
    type: 'text',
    indicator: 'carousel-number',
    teamIndex: 0,
  },
  {
    blockText: 'Позиция игрока',
    listName: 'Позиция',
    type: 'text',
    indicator: 'carousel-position',
    teamIndex: 0,
  },
]
