import { TransformUrl } from "../../../utils/transformUrl/transformUrl";
import UDText from "../../../ud-ui/components/text";
import { useMemo } from "react";
import { MomentPlayerWrapper } from "./styles";
import { TransformYoutubeUrl } from "../../../utils/transformUrl/transformUrl.Youtube";
import { TransformVKUrl } from "../../../utils/transformUrl/transformUrl.VK";

interface IProps {
  url?: string
}

function MomentPlayer(props: IProps) {
  const { url } = props
  
  const transformUrl = useMemo(() => {
    return TransformUrl.transformUrl(url ?? '');
  }, [url]);
  
  const isYoutube = useMemo(() => {
    return TransformYoutubeUrl.isYoutubeUrl(url ?? '');
  }, [url]);
  
  const isVk = useMemo(() => {
    return TransformVKUrl.isVkUrl(url ?? '');
  }, [url]);
  
  const content = useMemo(() => {
    if (!url) {
      return <UDText type={'subhead'}>Видео не найдено</UDText>
    }
    
    if (!transformUrl) {
      return <UDText type={'subhead'}>Ссылка не валидна</UDText>
    }
    
    if (isYoutube) {
      return (
        <MomentPlayerWrapper>
          <iframe
            width={280}
            height={158}
            src={transformUrl}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerPolicy='strict-origin-when-cross-origin'
            allowFullScreen
          />
        </MomentPlayerWrapper>
      );
    }
    
    if (isVk) {
      return (
        <iframe
          title='vk video player'
          src={transformUrl}
          width={280}
          height={158}
          frameBorder='0'
          allow='encrypted-media; fullscreen; picture-in-picture'
        />
      )
    }
  }, [isVk, isYoutube, transformUrl, url]);
  
  return (
    <MomentPlayerWrapper>
      {content}
    </MomentPlayerWrapper>
  );
}

export default MomentPlayer;
