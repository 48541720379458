import { IndicatorWrapper } from "./styles";
import UDText from "../../../../ud-ui/components/text";
import { DivProps } from "../../../../../typings";
import classNames from "classnames";

interface IProps extends DivProps {
  icon?: JSX.Element
  text: string
  isActive: boolean
}

function Indicator(props: IProps) {
  const { icon, text, isActive, className, ...rest } = props
  
  return (
    <IndicatorWrapper className={classNames(className, { active: isActive, disable: !isActive })} {...rest}>
      {icon}
      <UDText style={{ color: 'inherit' }} type={'tip'}>{text}</UDText>
    </IndicatorWrapper>
  );
}

export default Indicator;
