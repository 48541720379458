import styled from "@emotion/styled";
import UDInput from "../../../../ud-form/components/input/component";

export const TransparentInput = styled(UDInput)`
  background: transparent;
  border-color: transparent;
  padding: 0;
  
  font-size: 11px !important;
  font-weight: 400 !important;
  text-align: left  !important;
  color: #242424 !important;
  
  &:focus, &:hover {
    border-color: transparent;
  }
`
