import { useSelector } from "react-redux";
import {
  selectIsActive,
  selectLastChange,
  selectPassedTime,
  selectPeriod,
  selectStartTime,
} from "../../store/time/selectors";

function useTime() {
  const passedTime = useSelector(selectPassedTime)
  const period = useSelector(selectPeriod)
  const isActive = useSelector(selectIsActive)
  const startTime = useSelector(selectStartTime)
  const lastChange = useSelector(selectLastChange)
  
  return {
    passedTime,
    period,
    isActive,
    startTime,
    lastChange,
  }
}

export default useTime
