import { BoardDTO } from "../interfaces/supabase/overlay/dto";
import { Board } from "../interfaces/supabase/overlay/entity";

const boardDataMapper = (board: BoardDTO): Board => {
  const { template_id, is_favorite, created_at, ...rest } = board
  
  return {
    ...rest,
    createdAt: new Date(created_at),
    templateId: template_id,
    isFavorite: is_favorite,
  }
};

export default boardDataMapper;
