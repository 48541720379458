import styled from '@emotion/styled';
import UDLogo from '../logo';

export const MenuLogo = styled(UDLogo)`
  height: 70px;
  min-height: 70px;
`;

export const MenuContainer = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.SurfaceIndependence40};

  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.AccentNapplesYellow};
  }
`;
