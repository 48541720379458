import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import useBoards from "../../useBoards";
import { AppDispatch } from "../../../../../../store/store";
import { updateBoard } from "../../../../store/templates/actions";
import { resetPressedState, sortBoardsByTemplate } from "../../../../store/templates/templates";
import Type, { eventsOptions, typeOptions } from "../elements/events-selector/type";
import { EventType } from "../../../../../stream/domain/interfaces/events";
import boardDurationValidator from "../../../../domain/validators/boardDurationValidator";
import boardNameValidatorOnEdit from "../../../../domain/validators/boardNameValidatorOnEdit";
import { BoardTypeEnum } from "../../../../../core/domain/interfaces/supabase/overlay/auxiliary-types";

function useEditModal() {
  const dispatch = useDispatch<AppDispatch>();
  const { activeBoard, boards, isProcessed, isFinished, process } = useBoards()
  
  const validator = useCallback((values: { [ p: string ]: string | { label: string, value: string } }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    if (typeof values.name === 'string') {
      const nameError = boardNameValidatorOnEdit(
        values.name?.trim(),
        boards.map(board => board.name),
        activeBoard?.name,
      )
      if (nameError) {errors.name = nameError}
    }
    
    if (typeof values.type === 'object') {
      const durationError = boardDurationValidator(+values.duration)
      if (durationError && values.type.value === 'events') {errors.duration = durationError}
    }
    
    return errors
  }, [activeBoard?.name, boards]);
  
  const onSubmit = useCallback((values: {
    [ p: string ]: string | { label: string, value: string } | { label: string, value: string }[] | undefined
  }) => {
    if (!activeBoard) return
    
    if (typeof values.name !== 'string') {
      console.warn({ event: 'failed to create category: name is not string', values });
      return;
    }
    
    if ((typeof values.type !== 'object') && (values?.type !== undefined) && (Array.isArray(values?.type))) {
      console.warn({ event: 'failed to create category: type is not object or undefined', values });
      return;
    }
    
    const { name, duration } = values
    const type = (values.type as { label: string, value: BoardTypeEnum } | undefined)?.value
    const events = (values.events as { label: string, value: EventType }[] | undefined)?.map(event => event.value)
    const isEvents = type === 'events';
    const isCarousel = type === 'carousel';
    
    dispatch(updateBoard({
      id: activeBoard.id,
      name: name.trim(),
      type,
      duration: (isEvents || isCarousel) && duration ? +duration : undefined,
      events: isEvents ? events : undefined,
      isFavorite: isEvents ? false : activeBoard.isFavorite,
    }))
  }, [activeBoard, dispatch]);
  
  const events = useMemo(() => {
    if (!activeBoard) return undefined
    return eventsOptions
      .filter(option =>
        activeBoard.events?.includes(option.value),
      ) as { label: string, value: EventType }[]
  }, [activeBoard]);
  
  const data: IUNModalProps = useMemo(() => {
    return {
      initialValue: {
        name: activeBoard?.name ?? '',
        type: typeOptions.find(type => type.value === activeBoard?.type),
        events: events,
        duration: activeBoard?.duration.toString(),
      },
      headerText: 'Переименовать',
      validator,
      buttonText: 'Сохранить',
      input: {
        placeholder: 'Название табло',
      },
      additionalComponents: [<Type type={activeBoard?.type ?? 'static'}/>],
      onSubmit,
      isProcessed,
    }
  }, [activeBoard?.duration, activeBoard?.name, activeBoard?.type, events, isProcessed, onSubmit, validator]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  useEffect(() => {
    if (!isFinished) { return; }
    if (process !== 'update board') { return; }
    close()
    dispatch(resetPressedState())
    if (!activeBoard?.templateId) { return; }
    dispatch(sortBoardsByTemplate({ id: activeBoard.templateId }))
  }, [activeBoard?.templateId, close, dispatch, isFinished, process]);
  
  return { modal, close, open }
}

export default useEditModal;
