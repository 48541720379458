import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function Likes(props: IProps) {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 14} height={height ?? 12} viewBox="0 0 14 12" fill="none"
         xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M5.2 5.52831L6.4 6.66038L9.1 4.11321M6.99589 2.20895C5.79628 0.885887 3.79585 0.529986 2.29282 1.74152C0.789786 2.95305 0.578181 4.97867 1.75852 6.41155C2.65005 7.49384 5.18278 9.66659 6.36881 10.665C6.58683 10.8486 6.69584 10.9404 6.8235 10.9765C6.93432 11.0078 7.05747 11.0078 7.16828 10.9765C7.29594 10.9404 7.40495 10.8486 7.62298 10.665C8.80901 9.66659 11.3417 7.49384 12.2333 6.41155C13.4136 4.97867 13.2278 2.94031 11.699 1.74152C10.1701 0.54273 8.1955 0.885887 6.99589 2.20895Z"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
