import styled from "@emotion/styled";

export const Buttons = styled.div`
  width: 100%;
  height: 36px;

  margin-top: 10px;
  gap: 24px;

  display: flex;
  flex-direction: row;
  align-items: center;
`
