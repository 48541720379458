import { ScheduleSliceState } from "../index";
import { getWeekOfMonth } from "date-fns";
import getDay from "../../domain/utils/getDay";

export const markSelectedDay = (state: ScheduleSliceState) => {
  const date = state.selectedDate
  const isSomeMonth = state.currentMonth !== date.getMonth()
  const isSomeYear = state.currentYear !== date.getFullYear()
  if (isSomeMonth || isSomeYear) return
  const weekNum = getWeekOfMonth(date, {weekStartsOn: 1})
  const weekDay = state.weeks[ weekNum - 1 ][ getDay({ date: date }) ]
  weekDay.isSelected = true
}
