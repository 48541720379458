import React, { useCallback } from 'react';
import { Header, IconWrapper, Inputs, MainBlock } from "./styles";
import UDText from "../../../../../../ud-ui/components/text";
import QuestionMarkIcon from "../../../../../../ud-ui/components/icon/question-mark-icon";
import SizeItem from "./sizeItem";
import { manual } from "../../../../../domain/consts/urls";

interface IProps {
  readonly: boolean
}

function SizeInputs(props: IProps) {
  const redirectToManual = useCallback(() => {
    window.open(manual, '_blank');
  }, []);
  
  return (
    <MainBlock>
      <Header>
        <UDText type={'caption-1'} className={'color-Black24 flex-center'}>Укажите размер экрана/холста</UDText>
        <IconWrapper onClick={redirectToManual}><QuestionMarkIcon width={16} height={16}/></IconWrapper>
      </Header>
      <Inputs>
        <SizeItem readonly={props.readonly} name={'width'} text={'Ширина'} type={'text'}/>
        <SizeItem readonly={props.readonly} name={'height'} text={'Высота'} type={'text'}/>
      </Inputs>
    </MainBlock>
  );
}

export default SizeInputs;
