import React from 'react';
import { useDispatch } from 'react-redux';
import UDIcon from '../../../../ud-ui/components/icon';
import Block from '../components/block';
import Counter from '../components/counter';
import useGameInfo from "../../hooks/useGameInfo";
import { AppDispatch } from "../../../../../store/store";
import { setFouls } from "../../../store/gameInfo/gameInfo";

export function Fouls() {
  const dispatch = useDispatch<AppDispatch>()
  const { fouls } = useGameInfo()
  
  
  const setScoreFirstTeam = (num: number) => {
    dispatch(setFouls({ ...fouls, first: num }))
  }
  
  const setScoreSecondTeam = (num: number) => {
    dispatch(setFouls({ ...fouls, second: num }))
  }
  
  const onClickReset = () => {
    dispatch(setFouls({ first: 0, second: 0 }))
  }
  
  return <Block className={'flex-center flex-column'} headerText={'Фолы'}>
    <div className={'d-flex'} style={{ width: '100%', justifyContent: 'space-between' }}>
      <Counter value={fouls.first} setValue={setScoreFirstTeam}/>
      <UDIcon componentProps={{ onClick: onClickReset }} name={'cyclic-arrow'}/>
      <Counter value={fouls.second} setValue={setScoreSecondTeam}/>
    </div>
  </Block>;
}
