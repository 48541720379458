import React, { CSSProperties, useEffect, useMemo } from 'react';
import { Game } from "../../../../../../../games/domain/interfaces/Game";
import { useOrganizer } from "../../../../../../../organizer/ui/hooks/useOrganizer";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import { getLastGameResult } from "../requesters/fetchLastGameResult";
import useFabric from "../../../../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../../../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import { Resolution, Scheme } from "../../../../../../../ud-ui/components/fabric-canvas/types";
import { getFouls } from "../../../../../../../stream/domain/utils/getFouls";
import { CanvasWrapper } from "../../../../../../../ud-ui/components/fabric-canvas/view-boards/styles";
import { TeamByMatchId } from "../../../../../../domain/interfaces/TeamByMatchId";
import ReplaceCommon from "../../../../../../../ud-ui/components/fabric-canvas/addons/replacer/replaceCommon";
import ReplaceEvent from "../../../../../../../ud-ui/components/fabric-canvas/addons/replacer/replaceEvent";
import ReplaceCarousel from "../../../../../../../ud-ui/components/fabric-canvas/addons/replacer/replaceCarousel";
import { useTranslation } from "react-i18next";

type CanvasProps = {
  game: Game
  teamsPlayers: TeamByMatchId[]
  championship: {
    address?: {
      city?: string
    },
    name?: string
  }
  scheme?: Scheme
  resolution?: Resolution
}

const CanvasComponent = ({ game, championship, scheme, resolution, teamsPlayers }: CanvasProps) => {
  const { info } = useOrganizer()
  const { canvas, manager } = useFabric("static", {
    data: {
      ...(resolution ?? { width: 1920, height: 1080 }),
      backgroundColor: 'transparent',
    },
    style: canvasStyle,
    resizing: true,
  })
  const replacer = useReplacer(manager)
  const fouls = useMemo(() => getFouls(game.id), [game.id])
  const lastResults = useMemo(async () => {
    const results = await getLastGameResult(game.id)
    const firstTeamLastResult = results.find((result) => result.team.id === game?.teams[ 0 ]?.id)?.lastGamesResults ?? []
    const secondTeamLastResult = results.find((result) => result.team.id === game?.teams[ 1 ]?.id)?.lastGamesResults ?? []
    firstTeamLastResult.length = Math.min(firstTeamLastResult.length, 3)
    secondTeamLastResult.length = Math.min(secondTeamLastResult.length, 3)
    return [firstTeamLastResult.map(result => result.result as 'WON' | 'DRAW' | 'LOST'), secondTeamLastResult.map(result => result.result as 'WON' | 'DRAW' | 'LOST')]
  }, [game]);
  const replaceCommon = useMemo(() => {
    return new ReplaceCommon(replacer)
  }, [replacer]);
  const replaceEvent = useMemo(() => {
    return new ReplaceEvent(replacer)
  }, [replacer]);
  const replaceCarousel = useMemo(() => {
    return new ReplaceCarousel(replacer)
  }, [replacer])
  const { t } = useTranslation();
  
  useEffect(
    () => {
      if (!info) {
        console.warn({ event: 'info not found' });
        return;
      }
      
      if (!manager) {
        console.warn({ event: 'fabricManager not found' });
        return
      }
      
      const replaceAllPlaceHolders = () => {
        const firstTeam = game.teams[ 0 ]
        const secondTeam = game.teams[ 1 ]
        const city = championship.address?.city ? championship.address?.city.split(' ')[ 1 ] : 'Город'
        if (!firstTeam || !secondTeam) {
          console.warn({ event: 'teams not found', firstTeam, secondTeam });
          return
        }
        if (!firstTeam.logo || !secondTeam.logo) {
          console.warn({ event: 'logo not found', firstTeam, secondTeam });
          return
        }
        if (!info.logoUrl) {
          console.warn({ event: 'logoUrl not found', info });
          return
        }
        
        Promise.all([lastResults, fouls]).then(([lastResults, fouls]) => {
          replaceCommon.replace({
            startOpacity: 1,
            data: {
              championshipName: championship.name,
              city: city,
              formatStartDate: {
                time: format(new Date(game.date), 'HH:mm'),
                date: format(new Date(game.date), 'd MMMM', { locale: ru }),
              },
              fouls,
              goals: {
                first: firstTeam?.score ?? 0,
                second: secondTeam?.score ?? 0,
              },
              organizerLogo: info.logoUrl,
              period: 0,
              teams: {
                first: {
                  name: firstTeam.name,
                  logo: firstTeam?.logo?.url,
                  members: teamsPlayers[ 0 ].players.map((player) => ({
                    photo: player.avatar,
                    name: `${player.firstName} ${player.lastName}`,
                    number: player.number,
                  })),
                  lastGamesResults: lastResults[ 0 ],
                },
                second: {
                  name: secondTeam.name,
                  logo: secondTeam?.logo?.url,
                  members: teamsPlayers[ 1 ].players.map((player) => ({
                    photo: player.avatar,
                    name: `${player.firstName} ${player.lastName}`,
                    number: player.number,
                  })),
                  lastGamesResults: lastResults[ 1 ],
                },
              },
              timer: '00:00',
            },
          })
        })
        
        replaceEvent.replace({
          startOpacity: 1,
          data: {
            players: {
              first: {
                ...teamsPlayers[ 0 ].players[ 0 ],
                name: teamsPlayers[ 0 ].players[ 0 ].firstName,
                qtyRedCards: 0,
                qtyYellowCards: 0,
              },
              second: {
                ...teamsPlayers[ 0 ].players[ 1 ],
                name: teamsPlayers[ 0 ].players[ 1 ].firstName,
                qtyRedCards: 0,
                qtyYellowCards: 0,
              },
            },
            teams: {
              team: {
                fouls: 0,
                logo: firstTeam?.logo?.url,
                name: firstTeam.name,
                score: firstTeam.score ?? 0,
              },
              reversedTeam: {
                fouls: 0,
                logo: secondTeam?.logo?.url,
                name: secondTeam.name,
                score: secondTeam.score ?? 0,
              },
            },
            eventMinute: 0,
            currentPeriod: 0,
          },
        })
        
        const firstPlayer = teamsPlayers[ 0 ].players[ 0 ]
        const firstPlayerPosition = t(`player.position.${firstPlayer.position}`)
        replaceCarousel.replace({
          data: {
            avatar: firstPlayer.avatar,
            name: firstPlayer.firstName,
            number: firstPlayer.number,
            position: firstPlayerPosition.charAt(0).toUpperCase() + firstPlayerPosition.slice(1),
          },
          teamIndex: 0,
          startOpacity: 1,
        })
        
        const secondPlayer = teamsPlayers[ 1 ].players.find(player => player.position === firstPlayer.position)
        const secondPlayerPosition = t(`player.position.${secondPlayer?.position}`)
        replaceCarousel.replace({
          data: {
            avatar: secondPlayer?.avatar,
            name: secondPlayer?.firstName,
            number: secondPlayer?.number,
            position: secondPlayerPosition.charAt(0).toUpperCase() + firstPlayerPosition.slice(1),
          },
          teamIndex: 1,
          startOpacity: 1,
        })
      }
      
      if (scheme) {
        const data = { scheme: scheme, safeBackground: true };
        manager.loadLocal(data).then(replaceAllPlaceHolders);
        manager.normalizedViewport()
        manager.setParentSize()
      } else {
        replaceAllPlaceHolders()
        manager.clear(true)
      }
    },
    [championship.address?.city, championship.name, fouls, game.date, game.teams, info, lastResults, manager, replaceCommon, replaceEvent, scheme, teamsPlayers],
  )
  
  return (
    <CanvasWrapper>
      {canvas}
    </CanvasWrapper>
  )
};

const canvasStyle: CSSProperties = { height: '100%', width: '100%', position: 'absolute' }

export default CanvasComponent;
