import { GameDetailsTeam, GameDetailsTeamMember, GameEvent } from "../../../../domain/interfaces/GameDetails";
import { useTranslation } from "react-i18next";
import TeamLogo from "../../../../../ud-ui/components/team-logo";
import { EventCardWrapper, EventIcon, PlayerInfo, teamLogoStyles, TimeInfo } from "./styles";
import { GameResultEventIcon } from "../../game-result-events/game-result-event";
import { Divider } from "../../../../../ud-ui/components/divider";
import UDText from "../../../../../ud-ui/components/text";
import UDInput from "../../../../../ud-form/components/input/component";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";
import { setMomentUrl } from "../../../../store/game-details";

interface IProps {
  event: GameEvent
  teams: [GameDetailsTeam, GameDetailsTeam]
  members: Record<number, GameDetailsTeamMember>
}

function EventCard(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { t } = useTranslation();
  const { event, members, teams } = props
  
  const handleChangeUrl = useCallback((value: string) => {
    dispatch(setMomentUrl({ eventId: event.id, url: value }))
  }, [dispatch, event.id])
  
  return (
    <EventCardWrapper>
      <TeamLogo size={80} url={teams.find(team => team.id === event.teamId)?.logo} style={teamLogoStyles}/>
      <Divider type={'vertical'} color={'#000000'} width={'1px'}/>
      <EventIcon>
        <GameResultEventIcon type={event.type}/>
      </EventIcon>
      <Divider type={'vertical'} color={'#000000'} width={'1px'}/>
      <PlayerInfo>
        <div>{t(`games.eventTypes.${event.type}`)}</div>
        <div className={'h-100 flex-center'} style={{ gap: 16 }}>
          {event.playerIds.map((playerId) => (
            <div key={playerId}>
              <div className='game-event-player-info'>
                {members[ playerId ]?.lastName ?? 'Неизвестный'} {members[ playerId ]?.firstName ?? 'Неизвестный'}
              </div>
              <div className='game-event-player-position'>
                #{members[ playerId ]?.number ?? '?'}, {members[ playerId ] ? t(`player.position.${members[ playerId ]?.position}`) : 'Неизвестно'}
              </div>
            </div>
          ))}
        </div>
      </PlayerInfo>
      <Divider type={'vertical'} color={'#000000'} width={'1px'}/>
      <TimeInfo>
        <div className='game-event-period'>{getPeriodString(event.period)}</div>
        <div className={'h-100 flex-center flex-column'}>
          <div className='game-event-minutes'>{event.minutes}` минута</div>
          {event.extraMinutes && <div className='game-event-extra-minutes'>{`+${event.extraMinutes} доп.`}</div>}
        </div>
      </TimeInfo>
      <div className={'d-flex flex-column justify-content-between w-100'} style={{ padding: 8 }}>
        <UDText type={"subhead"}>Ссылка на момент</UDText>
        <UDInput
          value={event?.videoUrl}
          placeholder={'Введите ссылку'}
          onChange={event => handleChangeUrl(event.target.value)}
        />
      </div>
    </EventCardWrapper>
  );
}

export default EventCard;

function getPeriodString(period: number) {
  switch (period) {
    case 0:
      return 'Серия пенальти';
    case 1:
      return '1-й тайм';
    case 2:
      return '2-й тайм';
  }
}
