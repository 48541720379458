import classNames from "classnames";
import UDInput from "../../../ud-form/components/input/component";
import React, { useCallback, useMemo } from "react";
import debounce from "../../../utils/debounce";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { setPlayersPerPage } from "../../store";
import useTournamentUsers from "../hooks/useTournamentUsers";
import PerPage from "../../../ud-ui/components/per-page/per-page";

type Props = {
  className?: string;
  onFilter: (changes: any) => any;
};

function PlayerFilter(props: Props) {
  const dispatch = useDispatch<AppDispatch>()
  const { onFilter, className } = props;
  const { filter, playersPerPage } = useTournamentUsers();
  
  const handleChangeSearch = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onFilter({
          search: e.target.value,
        });
      }, 300),
    [onFilter],
  );
  
  const handleChangeResultsOnPage = useCallback((value: number) => {
    dispatch(setPlayersPerPage({ playersPerPage: value }))
  }, [dispatch]);
  
  return (
    <div className={classNames('container py-6', className)}>
      <div className='row gx-2'>
        <div className='col-9'>
          <div className='row gx-2'>
            <div className='col-8'>
              <UDInput
                defaultValue={filter.text}
                placeholder='Поиск по игрокам'
                onChange={handleChangeSearch}
                iconProps={{
                  position: 'icon-left',
                  name: 'search',
                  size: 20,
                  componentProps: { className: 'color-SurfaceRomanSilver30' },
                }}
              />
            </div>
          </div>
        </div>
        <PerPage value={playersPerPage} onChange={handleChangeResultsOnPage}/>
      </div>
    </div>
  );
}

export default PlayerFilter;
