import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

export default function CalendarNavIcon(props: IProps) {
  const {
    width,
    height,
  } = props;
  
  return (
    <svg width={width ?? 18} height={height ?? 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 7.4H1M12.5556 1V4.2M5.44444 1V4.2M5.26667 17H12.7333C14.2268 17 14.9735 17 15.544 16.7384C16.0457 16.5083 16.4537 16.1412 16.7094 15.6896C17 15.1762 17 14.5041 17 13.16V6.44C17 5.09587 17 4.42381 16.7094 3.91042C16.4537 3.45883 16.0457 3.09168 15.544 2.86158C14.9735 2.6 14.2268 2.6 12.7333 2.6H5.26667C3.77319 2.6 3.02646 2.6 2.45603 2.86158C1.95426 3.09168 1.54631 3.45883 1.29065 3.91042C1 4.42381 1 5.09587 1 6.44V13.16C1 14.5041 1 15.1762 1.29065 15.6896C1.54631 16.1412 1.95426 16.5083 2.45603 16.7384C3.02646 17 3.77319 17 5.26667 17Z"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
