import React from 'react';

type ArrowRightIconProps = {
  width?: number;
  height?: number;
  color?: string;
  direction?: 'left' | 'right' | 'up' | 'down';
};

const ArrowRightIcon = (props: ArrowRightIconProps) => {
  const {
    width = 16,
    height = 16,
    color = 'none',
    direction = 'right',
  } = props;
  
  const transformDirection = direction === 'left' ? 'rotate(180)' : direction === 'up' ? ' rotate(-90)' : direction === 'down' ? 'rotate(90)' : '';
  
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      transform={transformDirection}
    >
      <path
        d='M2 7.5C1.72386 7.5 1.5 7.72386 1.5 8C1.5 8.27614 1.72386 8.5 2 8.5L2 7.5ZM14.3535 8.35355C14.5488 8.15829 14.5488 7.84171 14.3535 7.64645L11.1716 4.46447C10.9763 4.2692 10.6597 4.2692 10.4645 4.46447C10.2692 4.65973 10.2692 4.97631 10.4645 5.17157L13.2929 8L10.4645 10.8284C10.2692 11.0237 10.2692 11.3403 10.4645 11.5355C10.6597 11.7308 10.9763 11.7308 11.1716 11.5355L14.3535 8.35355ZM2 8.5L14 8.5L14 7.5L2 7.5L2 8.5Z'
        fill={color}
      />
    </svg>
  );
};

export default ArrowRightIcon;
