import { Option, Values } from "../../../interface/form/board";
import { BoardTypeEnum } from "../../../../../core/domain/interfaces/supabase/overlay/auxiliary-types";
import { Object } from "fabric/fabric-impl";
import { EventType } from "../../../../../stream/domain/interfaces/events";

interface IProps {
  values: Values
}

interface Result {
  name: string
  type: BoardTypeEnum
  objects: Object[]
  version: string
  duration: number
  events: EventType[]
}

function ValueTypeValidator(props: IProps): Result {
  const { values } = props
  
  if (typeof values.name !== 'string') {
    throw Error('failed to create category: name is not string');
  }
  
  if ((typeof values.type !== 'object') && (values?.type !== undefined) && (Array.isArray(values?.type))) {
    throw Error('failed to create category: type is not object or undefined');
  }
  
  if (typeof values.objects !== 'object' || !Array.isArray(values.objects)) {
    throw Error('failed to create category: objects is not type object');
  }
  
  if (typeof values.version !== 'string') {
    throw Error('failed to create category: version is not string');
  }
  
  if (values.duration === undefined && isNaN(Number(values.duration))) {
    throw Error('failed to create category: duration is not number');
  }
  
  if (!Array.isArray(values.events)) {
    throw Error('failed to create category: events is not type array');
  }
  
  return {
    name: values.name.trim(),
    type: (values.type as Option)?.value as BoardTypeEnum,
    objects: values.objects as Object[],
    version: values.version,
    duration: Number(values.duration),
    events: (values.events as { label: string, value: EventType }[] | undefined)?.map(event => event.value) ?? [],
  }
}

export default ValueTypeValidator
