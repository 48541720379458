import { BasePlaceholder } from "./base";
import { commonPlaceholder } from "../../types/placeholders/createArgument";
import { Group, Object } from "fabric/fabric-impl";
import { fabric } from "fabric";
import customization from "../customization";
import SnapToGrid from "../snapToGrid";
import Elements from "../elements";

export class Complex extends BasePlaceholder {
  
  create(props: commonPlaceholder) {
    let placeholder: Group | undefined = undefined
    
    switch (props.indicator) {
      case 'lastGameResults': {
        placeholder = this.createImagePlaceholder(props)
        SnapToGrid.snapToGrid([placeholder])
        break
      }
      case 'teamComposition': {
        placeholder = this.createTeamCompositionPlaceholder()
        break
      }
    }
    
    if (!placeholder) {
      throw new Error('Unknown placeholder type')
    }
    
    this.canvas.add(placeholder)
    return placeholder
  }
  
  createTeamCompositionPlaceholder() {
    const { left, top } = Elements.getLeftTop(this.canvas)
    
    const avatarWidth = 36
    const numberWidth = 48
    const nameWidth = 240
    const playerBlockGap = 5
    const playerBlockHeight = 36
    const playerBlockWidth = avatarWidth + numberWidth + nameWidth + playerBlockGap * 2
    
    const numColumn = 2
    const numRow = 8
    const gapRow = 8
    const gapColumn = 40
    
    
    const background = new fabric.Rect({
      width: playerBlockWidth * numColumn + gapColumn * (numColumn - 1),
      height: playerBlockHeight * numRow + gapRow * (numRow - 1),
      strokeWidth: 0,
      fill: '#FFFFFF00',
      ...customization.getAllProperties(),
    })
    
    const blocks: Object[] = []
    
    for (let i = 0; i < numColumn; i++) {
      const left = i * playerBlockWidth + i * gapColumn
      for (let j = 0; j < numRow; j++) {
        const top = j * playerBlockHeight + j * gapRow
        blocks.push(this.createPlayerBlock({
          pos: { left, top },
          index: i * numRow + j,
          height: playerBlockHeight,
        }))
      }
    }
    
    return new fabric.Group([background, ...blocks], {
      left,
      top,
      originX: 'left',
      originY: 'top',
      data: {
        indicator: "teamComposition",
        type: 'composition',
        teamIndex: 0,
        backgroundColor: '#ffffff00',
        elementColor: '#1A1A29',
        borderColor: '#404861',
        textColor: '#FFFFFF',
      },
      ...customization.getAllProperties(),
    })
  }
  
  private createPlayerBlock(props: { pos: { left: number; top: number }, index: number, height: number }) {
    const { pos, index, height } = props
    const { left, top } = pos
    const avatarWidth = 36
    const numberWidth = 48
    const nameWidth = 240
    const gap = 5
    const strokeWidth = 1
    
    const avatarBlock = this.createPlayerElement({
      text: `Аватар\nИгрока №${index + 1}`,
      width: avatarWidth,
      height,
      strokeWidth,
      indicator: 'playerAvatar',
      left: 0,
      fontSize: 5.5,
    })
    SnapToGrid.transformAfterScaling(avatarBlock)
    
    const numberBlock = this.createPlayerElement({
      text: `Номер\nИгрока №${index + 1}`,
      width: numberWidth,
      height,
      strokeWidth,
      indicator: 'playerNumber',
      left: avatarWidth + gap,
      fontSize: 8,
    })
    SnapToGrid.transformAfterScaling(numberBlock)
    
    const nameBlock = this.createPlayerElement({
      text: `ФИО Игрока №${index + 1}`,
      width: nameWidth,
      height,
      strokeWidth,
      indicator: 'playerName',
      left: avatarWidth + numberWidth + gap + gap,
      fontSize: 20,
    })
    SnapToGrid.transformAfterScaling(nameBlock)
    
    return new fabric.Group([avatarBlock, numberBlock, nameBlock], {
      left,
      top,
      originX: 'left',
      originY: 'top',
      data: { indicator: `playerBlock`, index, team: 0 },
      ...customization.getAllProperties(),
    })
  }
  
  private createPlayerElement(props: {
    width: number,
    height: number,
    strokeWidth: number,
    indicator: string,
    text: string
    fontSize: number
    left: number
  }) {
    
    const {
      width,
      height,
      strokeWidth,
      indicator,
      text,
      left,
      fontSize,
    } = props
    
    const background = this.createRect({
      options: {
        width: width - strokeWidth / 2,
        height: height - strokeWidth / 2,
        strokeWidth,
        fill: '#1A1A29',
        ...customization.getAllProperties(),
        stroke: '#404861',
      },
    })
    
    const center = background.getCenterPoint()
    const textObject = this.createText({
      text,
      options: {
        left: center.x,
        top: center.y,
        textAlign: 'center',
        originX: 'center',
        originY: 'center',
        
        fontSize,
        fontFamily: 'Open Sans, sans-serif',
        fill: '#FFFFFF',
        fontWeight: 'normal',
        ...customization.getAllProperties(),
      },
    })
    
    return this.createGroup({
      objects: [background, textObject],
      data: { indicator },
      options: {
        left,
      },
    })
  }
}
