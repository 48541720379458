import BaseFabric from "./baseFabric";
import { CanvasConstructor } from "../types";
import { fabric } from "fabric";
import { StaticCanvas } from "fabric/fabric-impl";

export default class Static extends BaseFabric {
  canvas: StaticCanvas
  
  constructor(data?: CanvasConstructor) {
    super();
    
    const { width = 16 * 50, height = 9 * 50, backgroundColor = 'transparent', id = 'canvas' } = data ?? {}
    
    this.canvas = new fabric.StaticCanvas(id, {
      height,
      width,
      backgroundColor,
      selection: false,
      interactive: false,
      imageSmoothingEnabled: false,
    })
    
    this.setResolution({ width, height })
    
    this.init()
  }
}
