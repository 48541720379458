import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function Stars(props: IProps) {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg width="52" height="74" viewBox="0 0 52 74" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M3.36798 -6.23473L9.45508 -1.05605L16.2613 -5.24492L13.2171 2.14455L19.3042 7.32324L11.3357 6.7115L8.2915 14.101L6.41088 6.33343L-1.55764 5.72169L5.2486 1.53282L3.36798 -6.23473Z"
        fill="#FBFBFB"/>
      <path
        d="M-0.890192 26.1447L2.43005 28.9695L6.14254 26.6846L4.48206 30.7153L7.8023 33.54L3.45583 33.2063L1.79536 37.2369L0.769571 33.0001L-3.57689 32.6664L0.135598 30.3816L-0.890192 26.1447Z"
        fill="#FBFBFB"/>
      <path
        d="M40.1098 51.1447L43.43 53.9695L47.1425 51.6846L45.4821 55.7153L48.8023 58.54L44.4558 58.2063L42.7954 62.2369L41.7696 58.0001L37.4231 57.6664L41.1356 55.3816L40.1098 51.1447Z"
        fill="#FBFBFB"/>
      <path
        d="M40.1098 6.14474L43.43 8.96947L47.1425 6.68463L45.4821 10.7153L48.8023 13.54L44.4558 13.2063L42.7954 17.2369L41.7696 13.0001L37.4231 12.6664L41.1356 10.3816L40.1098 6.14474Z"
        fill="#FBFBFB"/>
      <path
        d="M7.10981 60.1447L10.43 62.9695L14.1425 60.6846L12.4821 64.7153L15.8023 67.54L11.4558 67.2063L9.79536 71.2369L8.76957 67.0001L4.42311 66.6664L8.1356 64.3816L7.10981 60.1447Z"
        fill="#FBFBFB"/>
      <path
        d="M19.368 25.7653L25.4551 30.944L32.2613 26.7551L29.2171 34.1446L35.3042 39.3232L27.3357 38.7115L24.2915 46.101L22.4109 38.3334L14.4424 37.7217L21.2486 33.5328L19.368 25.7653Z"
        fill="#FBFBFB"/>
    </svg>
  );
}
