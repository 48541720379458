import React from 'react';
import LeftToolbar from "../components/editor/leftToolbar/leftToolbar";
import styled from "@emotion/styled";
import Header from "../components/editor/mainWindow/header";
import RightToolbar from "../components/editor/rightToolbar/rightToolbar";
import Content from "../components/editor/mainWindow/content";
import { useLoadTemplates } from "../hooks/useLoadTemplates";
import { useFetchOrganizer } from "../../../organizer/ui/hooks/useFetchOrganizer";

const BoardEditor = () => {
  
  useLoadTemplates()
  useFetchOrganizer();
  
  return (
    <MainBlock>
      <Header/>
      <ContentWrapper>
        <LeftToolbar/>
        <Content/>
        <RightToolbar/>
      </ContentWrapper>
    </MainBlock>
  );
};

export default BoardEditor;

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;

  overflow: hidden;
`
