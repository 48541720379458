/** @jsxImportSource @emotion/react */
import React, { useCallback } from 'react';
import { MainBlock } from "./styles";
import { Day as IDay } from "../../../../../domain/interfaces/day";
import UDText from "../../../../../../ud-ui/components/text";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../../store/store";
import { setSelectedDate } from "../../../../../store";

interface IProps extends IDay {}

function Day(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { date, status, isSelected } = props
  
  const onClick = useCallback(() => {
    if (!date) return
    dispatch(setSelectedDate( date ))
  }, [date, dispatch]);
  
  return (
    <MainBlock onClick={onClick} className={classNames(isSelected ? 'active' : status)}>
      <UDText style={{ color: 'inherit', lineHeight: 'inherit' }} type={'footnote'}>{date?.getDate()}</UDText>
    </MainBlock>
  );
}

export default Day;
