interface IProps {
  date: Date
}

function getDay(props: IProps) {
  const { date } = props
  return [6, 0, 1, 2, 3, 4, 5][date.getDay()];
}

export default getDay
