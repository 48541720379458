import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  direction?: 'left' | 'right';
}

export default function ArrowBracketIcon(props: IProps) {
  const {
    width,
    height,
    direction,
    ...rest
  } = props;
  
  
  const transformDirection = direction === 'left' ? 'rotate(180)' : '';
  
  return (
    <svg
      width={width ?? 16}
      height={height ?? 16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg' {...rest}
      transform={transformDirection}
    >
      <path
        d='M10.4008 2.3999L4.80078 7.9999L10.4008 13.5999' stroke={"currentColor"} strokeWidth='2' strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
