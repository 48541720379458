import graphqlResource, { GraphqlResource } from "../../../core/graphqlResource";
import { MatchDTO } from "../interfaces/dtos";
import { matchesMapper } from "../data-mappers/matches-mapper";

interface MatchesByDateRepository {
  data: {
    matchesByDate: MatchDTO[]
  },
  error: Error
}

class OrganizerRepository {
  constructor(
    private readonly graphqlResource: GraphqlResource,
  ) {}
  
  async fetchMatchesByDataRange(tournamentId: number, startDate: Date, endDate: Date) {
    const query = `
      query getMatchesByDateRange($tournamentId: Int!, $dateFrom: String!, $dateTo: String!) {
       matchesByDate(
              input: {
                  tournamentId: $tournamentId
                  limit: 500
                  dateFrom: $dateFrom
                  dateTo: $dateTo
              }
          ) {
              id
              teams {
                  id
                  name
                  emblem {
                      externalUrl
                  }
              }
              date
              sportType
              championship {
                  id
                  name
                  tournament {
                      id
                      address {
                          city
                      }
                  }
                  settings {
                      id
                      name
                      value
                  }
              }
          }
      }
    `
    
    const result = await this.graphqlResource.query<MatchesByDateRepository>(
      query,
      { tournamentId, dateFrom: startDate.toISOString(), dateTo: endDate.toISOString() },
    )
    
    if (!result.data) throw new Error('Tournament not found', { cause: result })
    return matchesMapper(result.data.data.matchesByDate)
  }
}

const repository = new OrganizerRepository(graphqlResource)
export default repository
