import { Event } from "../../../domain/interfaces/events";
import _ from "lodash";
import { Member } from "../../../domain/interfaces/gameInfo/gameInfoMapped";
import { Teams } from "../../../domain/interfaces/gameInfo/state";

interface IOptions {
  playerIndex?: number
  teamReverse?: boolean
}

function parseInfoEvent(event: Event, teams?: Teams, options?: IOptions) {
  const { playerIndex = 0, teamReverse = false } = options ?? {}
  
  if (!event) return { player: undefined, team: undefined, teamPrefix: undefined }
  if (!teams) return { player: undefined, team: undefined, teamPrefix: undefined }
  
  const isFirst = (event.teamId === teams?.first.id);
  const teamPrefix = (isFirst && !teamReverse) || (!isFirst && teamReverse) ? 'first' : 'second' as 'first' | 'second';
  const team = teams?.[ teamPrefix ]
  const playerId = event.players[ playerIndex ]?.id
  const player = _.find(team?.members, { id: playerId }) as Member | undefined;
  return { player, team, teamPrefix }
}

export default parseInfoEvent
