import { Option } from "../../../interface/form/template";
import { AccessTypes, VisibilityEnum } from "../../../../../core/domain/interfaces/supabase/overlay/auxiliary-types";

export const accessOptions: Option<AccessTypes>[] = [
  { label: 'Приватный', value: 'private' },
  { label: 'Публичный', value: 'public' },
  { label: 'Дефолтный', value: 'default' },
]

export const visibilityOptions: Option<VisibilityEnum>[] = [
  { label: 'Все', value: 'all' },
  // { label: 'Дизайнеры', value: 'designer' },
  // { label: 'Администраторы', value: 'admin' },
  { label: 'Супер админы', value: 'superAdmin' },
  // { label: 'Скрыть', value: 'hide' },
]
