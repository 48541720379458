import { ClipLoader } from "react-spinners";

function PlayerLoad() {
  
  return (
    <div
      className={'w-100 h-100 flex-center'}
      style={{ height: 'calc(100vh - 140px)' }}
    >
      <ClipLoader/>
    </div>
  );
}

export default PlayerLoad;
