import { Organizer } from "../interfaces/Organizer";
import _ from "lodash";

export function filteringBySearch(organizers: Organizer[] | undefined, filter: string | undefined) {
  if (!filter) return organizers
  
  return _.reduce(organizers, (acc, organizer) => {
    if (organizer?.name?.toLocaleLowerCase().includes(filter)) {
      acc.push(organizer)
      return acc
    }
    
    if (organizer?.address?.city?.toLocaleLowerCase().includes(filter)) {
      acc.push(organizer)
      return acc
    }
    
    const nameWords = [
      organizer?.adminUser?.name,
      organizer?.adminUser?.surname,
      (organizer?.adminUser?.middleName),
    ]
    
    const filterWords = filter.split(' ')
    
    const isIncludes = filterWords.every(filterWord =>
      nameWords.some(nameWord => nameWord?.toLowerCase().includes(filterWord.toLowerCase())),
    );
    
    if (isIncludes) {
      acc.push(organizer)
      return acc
    }
    
    return acc
  }, [] as Organizer[]);
}
