/** @jsxImportSource @emotion/react */
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import UDText from "../../../../../../ud-ui/components/text";
import UDFormInput from "../../../../../../ud-form/components/input";
import { modalButton, modalError, modalInputStyle } from "../../../../components/elements/modals/styles";
import { Button } from "../../../../../../ud-ui/components/button/styles";
import classNames from "classnames";
import React from "react";
import _ from "lodash";
import { Buttons } from "./styles";
import { Values } from "../../../../../domain/interface/form/template";
import SizeInputs from "../../elements/size/sizeInputs";
import UDFormSelect from "../../../../../../ud-form/components/select";
import { accessOptions, visibilityOptions } from "../../../../../domain/consts/form/template/options";
import Row from "../../elements/events-selector/row";
import { CustomSelect } from "../../elements/events-selector/customSelect";
import { presets, PresetsNameList } from "../../../../../domain/consts/form/template/presets";

type IProps = FormikConfig<Values> & {
  isAdmin: boolean
  isLoading: boolean
  presets: PresetsNameList
}

function TemplateForm(props: IProps) {
  const {
    isAdmin,
    isLoading,
  } = props
  
  const getErrors = (props: FormikProps<Values>) => {
    return _.find(props.errors, (error, key) => !!error && !!props.touched[ key ])
  }
  
  const text = presets[ props.presets ]
  
  return (
    <Formik
      validateOnChange
      {...props}
    >
      {(formProps) => {
        return (
          <Form>
            <div key={'modal-content'} style={{ display: "flex", flexDirection: "column", gap: 20 }}>
              <UDText type={'title-3'}>{text.header}</UDText>
              <UDFormInput
                readOnly={isLoading}
                onChangeCapture={() => {
                  formProps.values.name &&
                  !formProps.touched.name &&
                  formProps.setFieldTouched('name')
                }}
                name={'name'}
                autoFocus={true}
                css={modalInputStyle}
                placeholder={'Название шаблона'}
              />
              <SizeInputs readonly={isLoading}/>
              {isAdmin && (
                <>
                  <Row headerText={'Тип шаблона'}>
                    <UDFormSelect
                      containerProps={{ style: { width: '100%' } }}
                      selectComponent={CustomSelect}
                      name={'access'}
                      options={accessOptions}
                    />
                  </Row>
                  <Row headerText={'Видимость'}>
                    <UDFormSelect
                      containerProps={{ style: { width: '100%' } }}
                      selectComponent={CustomSelect}
                      name={'visibility'}
                      options={visibilityOptions}
                    />
                  </Row>
                </>
              )}
              <Buttons>
                <Button
                  type={'submit'}
                  className={classNames('flex-center', isLoading && 'loading')}
                  disabled={!formProps.isValid || formProps.values.name === ''}
                  css={modalButton}
                >
                  {text.submitButton}
                </Button>
                <span css={modalError}>
                  {getErrors(formProps)}
                </span>
              </Buttons>
            </div>
          </Form>
        )
      }}
    </Formik>
  );
}

export default TemplateForm;
