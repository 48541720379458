export default function templateNameOnEditValidator(templateName?: string, templatesList?: string[], oldName?: string) {
  if (!templateName || templateName.length <= 0) {
    return 'Название категории не может быть пустым'
  }
  // if (templateName.length > 30) {
  //   return 'Название категории слишком длинное'
  // }
  if (templatesList && templateName !== oldName && templatesList.includes(templateName)) {
    return 'Такое название уже существует, придумайте новое.'
  }
  return undefined
}
