import styled from "@emotion/styled";

interface IProps {
  type: 'horizontal' | 'vertical'
  color: string
  width?: string
  height?: string
}

export const Divider = styled.div<IProps>`
  &:after {
    content: '';
    display: block;
    width: ${({ width, type }) => width ?? getWidth(type)};
    height: ${({ height, type }) => height ?? getHeight(type)};
    background: ${({ color }) => color};
  }
`

const getWidth = (type: 'horizontal' | 'vertical') => type === 'horizontal' ? '100%' : '1px'
const getHeight = (type: 'horizontal' | 'vertical') => type === 'horizontal' ? '1px' : '100%'
