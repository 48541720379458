import { fetchActiveTournamentPlan, fetchOrganizers, givePlan, topupBalance } from "./actions";
import { toast } from "react-toastify";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { OrganizersSliceState } from "./index";

type ReducerBuilder = ActionReducerMapBuilder<OrganizersSliceState>;

export function createFetchOrganizersReducer(builder: ReducerBuilder) {
  builder.addCase(fetchOrganizers.pending, (state) => {
    state.isLoaded = false
    state.isLoading = true
  });
  
  builder.addCase(fetchOrganizers.fulfilled, (state, action) => {
    state.organizers = action.payload
    state.isLoaded = true
    state.isLoading = false
  });
  
  builder.addCase(fetchOrganizers.rejected, (state) => {
    state.isLoaded = false
    state.isLoading = false
    toast.error('Не удалось загрузить организаторов')
  });
}

export function createFetchActiveTournamentPlanReducer(builder: ReducerBuilder) {
  builder.addCase(fetchActiveTournamentPlan.pending, (state) => {
    state.isProcessing = true
    state.isProcessed = false
    state.processName = 'fetchActiveTournamentPlan'
  });
  
  builder.addCase(fetchActiveTournamentPlan.fulfilled, (state, action) => {
    state.plans.byOwnerId[ action.meta.arg.tournamentId ] = action.payload
    state.isProcessing = false
    state.isProcessed = true
  });
  
  builder.addCase(fetchActiveTournamentPlan.rejected, (state) => {
    toast.error('Не удалось выполнить операцию')
  });
}

export function createTopupBalanceReducer(builder: ReducerBuilder) {
  builder.addCase(topupBalance.pending, (state) => {
    state.isProcessing = true
    state.isProcessed = false
    state.processName = 'topupBalance'
  });
  
  builder.addCase(topupBalance.fulfilled, (state, action) => {
    state.isProcessing = false
    state.isProcessed = true
    state.processName = undefined
    
    toast.success(`Баланс успешно пополнен на сумму ${action.meta.arg.amount} руб.!`)
  });
  
  builder.addCase(topupBalance.rejected, (state) => {
    state.isProcessing = false
    state.isProcessed = false
    toast.error('Не удалось пополнить баланс')
  });
}

export function createGivePlanReducer(builder: ReducerBuilder) {
  builder.addCase(givePlan.pending, (state) => {
    state.isProcessing = true
    state.isProcessed = false
    state.processName = 'givePlan'
  });
  
  builder.addCase(givePlan.fulfilled, (state, action) => {
    state.isProcessing = false
    state.isProcessed = true
    state.processName = undefined
    
    toast.success('План подключен');
  });
  
  builder.addCase(givePlan.rejected, (state) => {
    state.isProcessing = false
    state.isProcessed = false
    toast.error('Не удалось выдать план')
  });
}
