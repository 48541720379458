/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo } from "react";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import { Pages } from "../../../navigation/domain/enums/pages";
import UDMainLayout from "../../../ud-ui/layout/main";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { MainBlock } from "./styles";
import useFabric from "../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import { Board } from "../../../core/domain/interfaces/supabase/overlay/entity";
import Header from "../components/catalogCard/header";
import { AppDispatch, history } from "../../../../store/store";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import {
  CanvasWrapper,
  Container,
  LeftContainer,
  RightContainer,
} from "../../../ud-ui/components/fabric-canvas/view-boards/styles";
import BoardsList from "../../../ud-ui/components/fabric-canvas/view-boards/boards-list/boards-list";
import BoardItem from "../../../ud-ui/components/fabric-canvas/view-boards/boards-list/board-item";
import { selectActiveTemplate } from "../../store/templates/selectors";
import { addView } from "../../store/templates/actions";
import { useAuth } from "../../../auth/ui/hooks/useAuth";
import EmptyButton from "../../../ud-ui/components/emptyButton";
import PlusIcon from "../../../ud-ui/components/icon/plus-icon";
import useCreateModal from "../hooks/modals/board/create";
import useBoards from "../hooks/useBoards";
import { setActiveBoard } from "../../store/templates/templates";

function CatalogCard() {
  const dispatch = useDispatch<AppDispatch>()
  const { user } = useAuth()
  const { info } = useOrganizer()
  const id = useParams()?.id;
  const template = useSelector(selectActiveTemplate);
  const { activeBoard, boards } = useBoards()
  if (template === undefined) {
    history.push(Pages.TEMPLATE.CATALOG.INDEX)
  }
  
  useEffect(() => {
    if (!activeBoard || !template || !template.boards.find(board => board.id === activeBoard.id)) {
      if (!boards || boards.length <= 0) return;
      dispatch(setActiveBoard({ id: boards[ 0 ].id }))
    }
  }, [activeBoard, boards, dispatch, template])
  
  const breadcrumb = useMemo(() => (<UDBreadcrumbs
    className='my-10' breadcrumbs={[
    { label: 'Турниры', to: Pages.TOURNEYS.INDEX },
    { label: 'Шаблоны', to: Pages.TEMPLATE.INDEX },
    { label: 'Готовые шаблоны', to: Pages.TEMPLATE.CATALOG.INDEX },
    { label: template?.name ?? 'Неизвестный шаблон', to: Pages.TEMPLATE.CATALOG.builders.card(id ? +id : -1) },
  ]}
  />), [id, template?.name]);
  
  const { canvas, manager } = useFabric('static', {
    data: { ...template?.resolution },
    style: { borderRadius: 8, position: 'absolute' },
  });
  
  useEffect(() => {
    if (!manager) return
    const resizeCanvas = () => {
      manager.setParentSize()
      manager.normalizedViewport()
    }
    
    resizeCanvas()
    window.addEventListener('resize', resizeCanvas)
    
    return () => {
      window.removeEventListener('resize', resizeCanvas)
    }
  }, [manager])
  
  useEffect(() => {
    if (!manager) return
    if (!activeBoard) return
    
    manager.loadLocal({
      scheme: { objects: activeBoard.objects, version: activeBoard.version },
      safeBackground: true,
    })
  }, [activeBoard, manager]);
  
  const onClick = (board: Board) => {
    dispatch(setActiveBoard({ id: board.id }))
  }
  
  useEffect(() => {
    if (!info) return
    dispatch(addView({ templateId: id ? +id : -1, userId: info.id }))
  }, [dispatch, id, info]);
  
  const { modal: createModal, open: openCreateModal } = useCreateModal()
  
  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'
  const isAdmin = useMemo(() => {
    return user?.id === 20 || isDev
  }, [user?.id, isDev])
  
  return (
    <UDMainLayout headerContent={breadcrumb}>
      <MainBlock>
        <Container>
          <LeftContainer>
            <BoardsList
              items={
                boards.map((board) => {
                  return (
                    <BoardItem
                      key={board.id}
                      board={board}
                      isEditable={isAdmin}
                      onClick={() => onClick(board)}
                      isActive={board.id === activeBoard?.id}
                      isDefaultTemplate={true}
                    />
                  )
                })
              }
              headerText={'Табло'}
            />
            {isAdmin && (
              <EmptyButton onClick={openCreateModal} color={'#A2A2A2'} icon={<PlusIcon/>}>
                Добавить табло
              </EmptyButton>
            )}
          </LeftContainer>
          <RightContainer>
            <Header templateId={id ? +id : -1}/>
            <CanvasWrapper>
              {canvas}
            </CanvasWrapper>
          </RightContainer>
        </Container>
      </MainBlock>
      {createModal}
    </UDMainLayout>
  );
}

export default CatalogCard;
