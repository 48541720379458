import styled from "@emotion/styled";

export const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0;
  gap: 10px;
  
  color: white;
  
  &.disable {
    background: #FF5959;
  }
  
  &.active {
    background: #57B34F;
  }
`
