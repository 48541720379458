import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function NewMonitorIcon(props: IProps) {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg
      width={width ?? 24}
      height={height ?? 24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M8.4 20H15.6M12 16.4444V20M7.32 16.4444H16.68C18.1921 16.4444 18.9482 16.4444 19.5258 16.1538C20.0338 15.8981 20.4469 15.4902 20.7057 14.9884C21 14.418 21 13.6713 21 12.1778V8.26667C21 6.77319 21 6.02646 20.7057 5.45603C20.4469 4.95426 20.0338 4.54631 19.5258 4.29065C18.9482 4 18.1921 4 16.68 4H7.32C5.80786 4 5.05179 4 4.47423 4.29065C3.96619 4.54631 3.55314 4.95426 3.29428 5.45603C3 6.02646 3 6.77319 3 8.26667V12.1778C3 13.6713 3 14.418 3.29428 14.9884C3.55314 15.4902 3.96619 15.8981 4.47423 16.1538C5.05179 16.4444 5.80786 16.4444 7.32 16.4444Z'
        stroke='currentColor'
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
