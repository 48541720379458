import { useNavigate, useParams } from "react-router-dom";
import UDMainLayout from "../../../../ud-ui/layout/main";
import React, { useEffect, useMemo } from "react";
import UDBreadcrumbs from "../../../../ud-ui/components/breadcrumbs";
import { Pages } from "../../../../navigation/domain/enums/pages";
import { useSelector } from "react-redux";
import { selectMatchById } from "../../../store/selectors";
import { toast } from "react-toastify";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import Template from "../../../../tourneys/ui/components/form-parts/stages/cover-templates/template";
import UDText from "../../../../ud-ui/components/text";
import { MainBlock } from "./styles";

function PreviewTemplate() {
  const navigate = useNavigate()
  const { id } = useParams()
  const match = useSelector(selectMatchById(+(id ?? 0)))
  useEffect(() => {
    if (!match) {
      toast.error('Матч не найден')
      navigate(Pages.SCHEDULE.INDEX)
    }
  }, []);
  
  const breadcrumb = useMemo(() => {
    const matchName = match ? (
      `Матч: ${match?.date && format(
        match?.date,
        'kk:mm dd MMMM, EEEEEE',
        { locale: ru },
      )} ${match?.teams[ 0 ]?.name} vs ${match?.teams[ 1 ]?.name}`
    ) : (
      'Матч не найден'
    );
    
    return (<UDBreadcrumbs
      className='my-10' breadcrumbs={[
      { label: 'Расписание', to: Pages.SCHEDULE.INDEX },
      {
        label: matchName,
        to: Pages.SCHEDULE.builders.view(+(id ?? 0)),
      },
    ]}
    />);
  }, [id, match])
  
  return (
    <UDMainLayout headerContent={breadcrumb}>
      <MainBlock>
        {match ? (
          <Template game={match} championship={match.championship}/>
        ) : (
          <UDText type={'caption-1'}>Матч не найден</UDText>
        )}
      </MainBlock>
    </UDMainLayout>
  );
}

export default PreviewTemplate;
