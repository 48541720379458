import { eachDayOfInterval, endOfMonth, startOfMonth } from "date-fns";

interface IProps {
  month: number
  year?: number
}

function getDaysByMonth(props: IProps) {
  const { month, year = new Date().getFullYear() } = props;
  const startDate = startOfMonth(new Date(year, month));
  const endDate = endOfMonth(new Date(year, month));
  return eachDayOfInterval({ start: startDate, end: endDate })
}

export default getDaysByMonth
