import styled from "@emotion/styled";

export const MainBlock = styled.div`
  background: ${({ theme }) => theme.colors.SurfaceCultured10};
  height: 24px;
  width: 24px;
  border-radius: 4px;
  color: #1A1A29;
  line-height: unset;
  
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  
  transition: color 0.2s ease-in-out, border 0.2s ease-in-out, background 0.2s ease-in-out;
  
  &:hover {
    background: rgba(0, 122, 255, 0.24);
  }
  
  &.passed {
    color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
  }
  
  &.active {
    background: #007AFF;
    color: white;
    cursor: default;
  }
  
  &.game {
    background: ${({ theme }) => theme.colors.AccentNapplesYellow};
    &:hover {
      background: rgba(0, 122, 255, 0.24);
    }
  }
  
  &.today {
    border: 1px solid ${({ theme }) => theme.colors.AccentNapplesYellow};
  }
  
  &.empty {
    background: white;
    color: white;
    cursor: default;
  }
`
