import React, { useMemo } from "react";
import UDPagination from "../../../ud-ui/components/pagination";
import PlayerList from "./player-list";
import UseTournamentUsers from "../hooks/useTournamentUsers";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { setCurrentPage } from "../../store";
import { TeamsSkeleton } from "../../../tourneys/ui/components/edit-form/parts/teams/teams-skeleton";
import { selectCurrentPage, selectPages } from "../../store/selectors";
import { fetchTournamentUsers } from "../../store/actions";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";


function BodyContent() {
  const dispatch = useDispatch<AppDispatch>()
  const { isLoading, isLoaded, players, allDataLoaded, playerLoadedLimit } = UseTournamentUsers()
  const { info } = useOrganizer()
  const pages = useSelector(selectPages)
  const currentPage = useSelector(selectCurrentPage)
  
  const isInitialLoad = useMemo(() => {
    return players.length === 0 && (isLoading || !isLoaded)
  }, [isLoaded, isLoading, players.length]);
  
  if (isInitialLoad) {
    return (
      <div className={'px-6'}>
        <TeamsSkeleton/>
      </div>
    );
  }
  
  const onPageChange: (page: number) => any = (page) => {
    dispatch(setCurrentPage({ currentPage: page }));
    if (!info) return
    if (allDataLoaded) return
    if (page === pages) {
      dispatch(fetchTournamentUsers({ tournamentId: info.id, limit: playerLoadedLimit, offset: players.length }))
    }
  }
  
  return (
    <div className='px-6'>
      <PlayerList/>
      <UDPagination
        className='justify-content-end px-6 my-8'
        pages={pages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
  
}

export default BodyContent;
