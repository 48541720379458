import styled from "@emotion/styled";

export const DaysWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const DaysLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
