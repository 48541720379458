import UDHeaderTitle from "../../../ud-ui/components/header-title";
import UDButton from "../../../ud-ui/components/button";
import UDIcon from "../../../ud-ui/components/icon";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../../navigation/domain/enums/pages";

function HeaderContent() {
  const navigate = useNavigate()
  
  const handleCreateClick = useCallback(() => {
    navigate(Pages.PLAYERS.CREATE)
  }, [navigate]);
  
  return (
    <>
      <UDHeaderTitle>Игроки</UDHeaderTitle>
      <div className='d-flex justify-content-end'>
        <UDButton
          className='d-flex align-items-center ml-6'
          variant='dark'
          onClick={handleCreateClick}
        >
          <UDIcon name='plus' componentProps={{ className: 'mr-2' }}/>
          <span>Добавить игрока</span>
        </UDButton>
      </div>
    </>
  );
}

export default HeaderContent;
