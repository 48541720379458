import { RootState } from "../../../../store/store";
import { createSelector } from "@reduxjs/toolkit";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";

const selectGameInfo = (state: RootState) => state.overlay.gameInfo

export const selectGoals = createSelector(
  [selectGameInfo],
  (gameInfo) => gameInfo.goals,
);

export const selectFouls = createSelector(
  [selectGameInfo],
  (gameInfo) => gameInfo.fouls,
);

export const selectOrganizer = createSelector(
  [selectGameInfo],
  (gameInfo) => gameInfo.organizer,
);

export const selectChampionship = createSelector(
  [selectGameInfo],
  (gameInfo) => gameInfo.championship,
);

export const selectMatch = createSelector(
  [selectGameInfo],
  (gameInfo) => gameInfo.match,
);

export const selectTeams = createSelector(
  [selectGameInfo],
  (gameInfo) => gameInfo.teams,
);

export const selectLoading = createSelector(
  [selectGameInfo],
  (gameInfo) => ({
    isLoading: gameInfo.isLoading,
    isLoaded: gameInfo.isLoaded,
  }),
);

export const selectSynchronization = createSelector(
  [selectGameInfo],
  (gameInfo) => ({
    isSynchronization: gameInfo.isSynchronization,
    isSynchronize: gameInfo.isSynchronize,
  }),
);

export const selectFormatStartDate = createSelector(
  [selectMatch],
  (match) => {
    const date = match?.date ? new Date(match?.date) : undefined
    
    return {
      date: date ? format(date, 'd MMMM', { locale: ru }) : 'Неизвестная дата',
      time: date ? format(date, 'HH:mm') : 'Неизвестное время',
    }
  },
)
