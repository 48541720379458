import { ScheduleSliceState } from "../index";
import { getWeekOfMonth } from "date-fns";
import getDay from "../../domain/utils/getDay";

export const markToday = (state: ScheduleSliceState) => {
  const today = new Date()
  const isSomeMonth = state.currentMonth !== today.getMonth()
  const isSomeYear = state.currentYear !== today.getFullYear()
  if (isSomeMonth || isSomeYear) return
  const weekNum = getWeekOfMonth(today, { weekStartsOn: 1 })
  const weekDay = state.weeks[ weekNum - 1 ][ getDay({ date: today }) ]
  weekDay.status = 'today'
}
