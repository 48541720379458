import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function StarIcon(props: IProps) {
  const {
    width = 24,
    height = 24,
    fill,
    ...rest
  } = props;
  
  return (
    <svg
      width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M11.7084 3.82368C11.9389 3.35674 12.0542 3.12327 12.2106 3.04868C12.3467 2.98377 12.5049 2.98377 12.641 3.04868C12.7974 3.12327 12.9127 3.35674 13.1432 3.82368L15.3298 8.25364C15.3979 8.3915 15.4319 8.46042 15.4816 8.51394C15.5257 8.56132 15.5785 8.59971 15.6371 8.62698C15.7033 8.65778 15.7794 8.6689 15.9315 8.69113L20.8228 9.40607C21.3378 9.48135 21.5954 9.519 21.7146 9.6448C21.8183 9.75425 21.867 9.90466 21.8473 10.0541C21.8246 10.2259 21.6382 10.4075 21.2653 10.7707L17.7272 14.2168C17.6169 14.3242 17.5618 14.3779 17.5262 14.4418C17.4947 14.4984 17.4745 14.5606 17.4667 14.6249C17.4579 14.6975 17.4709 14.7734 17.4969 14.9251L18.3317 19.7925C18.4198 20.3059 18.4638 20.5625 18.3811 20.7149C18.3091 20.8474 18.1811 20.9403 18.0328 20.9678C17.8624 20.9994 17.6319 20.8782 17.1709 20.6358L12.7982 18.3362C12.6619 18.2645 12.5938 18.2287 12.522 18.2146C12.4585 18.2022 12.3931 18.2022 12.3296 18.2146C12.2578 18.2287 12.1897 18.2645 12.0534 18.3362L7.6807 20.6358C7.2197 20.8782 6.98919 20.9994 6.81875 20.9678C6.67046 20.9403 6.5425 20.8474 6.47052 20.7149C6.38778 20.5625 6.43181 20.3058 6.51986 19.7925L7.35467 14.9251C7.38069 14.7734 7.39371 14.6975 7.3849 14.6249C7.3771 14.5606 7.35689 14.4984 7.32539 14.4418C7.2898 14.3779 7.23466 14.3242 7.12437 14.2168L3.58634 10.7707C3.21344 10.4075 3.027 10.2259 3.00431 10.0541C2.98457 9.90466 3.03333 9.75425 3.13703 9.6448C3.25622 9.519 3.51375 9.48135 4.02882 9.40607L8.92009 8.69113C9.07221 8.6689 9.14826 8.65778 9.2145 8.62698C9.27315 8.59971 9.32594 8.56132 9.36997 8.51394C9.41969 8.46042 9.45371 8.3915 9.52175 8.25364L11.7084 3.82368Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
