import { GameDetails } from "../../../../domain/interfaces/GameDetails";
import { EventCardSkeleton } from "./styles";
import UDText from "../../../../../ud-ui/components/text";
import EventCard from "./event-card";
import { useMemo } from "react";
import { getMembers } from "../../../../utils/getMembers";

interface IProps {
  isLoading: boolean;
  game: GameDetails | null;
}

function EventsList(props: IProps) {
  const { game, isLoading } = props
  
  const members = useMemo(() => {
    return game?.teams ? getMembers(game.teams) : {}
  }, [game?.teams]);
  
  if (isLoading) {
    return (
      <div className='d-flex flex-column' style={{ gap: 28 }}>
        <EventCardSkeleton className={'skeleton-block'}/>
        <EventCardSkeleton className={'skeleton-block'}/>
        <EventCardSkeleton className={'skeleton-block'}/>
      </div>
    )
  }
  
  if (!game?.events.length) {
    return (
      <div className={'w-100 flex-center'}>
        <UDText type={'title-1'}>Событий нет</UDText>
      </div>
    )
  }
  
  return (
    <div className={'d-flex flex-column'} style={{ gap: 28 }}>
      {game.events.map((event) => (
        <EventCard key={event.id} event={event} members={members} teams={game.teams}/>
      ))}
    </div>
  );
}

export default EventsList;
