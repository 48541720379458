import React, { useCallback } from 'react';
import UDFormInput from 'modules/ud-form/components/input';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import UDButton from 'modules/ud-ui/components/button';
import UDFormDropZone from 'modules/ud-form/components/dropzone/index';
import { tourneysCommonStoreImagesSelector } from 'modules/tourneys/store/common/selectors';
import { AppDispatch } from "../../../../store/store";
import { Player } from "../../domain/interfaces/player";
import * as yup from 'yup';
import { format } from "date-fns";
import { TeamPlayerForm } from "../../../teams/ui/components/team-card/styles";
import { birthDateFormatter } from "../../../ud-form/components/input/formatters/birthDateFormatter";
import BorderedInput from "../../../teams/ui/components/bordered-input";
import { usePlayers } from "../../../organizer/ui/hooks/usePlayers";
import { toast } from "react-toastify";
import { upsertTournamentUser } from "../../store/actions";

type UpsertTeamFormProps = {
  tournamentId: number;
  player: Player;
};

const UpsertPlayerForm = (props: UpsertTeamFormProps) => {
  const dispatch = useDispatch<AppDispatch>()
  const {
    tournamentId,
    player,
  } = props;
  const { isLoading } = usePlayers();
  
  
  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        upsertTournamentUser({
          userId: player?.id,
          tournamentId,
          name: values.name,
          surname: values.surname,
          middleName: values.middleName,
          birthDate: values.birthDate,
          height: +values.height,
          weight: +values.weight,
          photo: values.photo,
        }),
      ).then(() => {
        toast.success('Игрок успешно сохранён');
      });
    },
    [dispatch, tournamentId, player?.id],
  );
  
  const teamPlayerValidator = yup.object().shape({
    name: yup.string().required('Обязательное поле'),
    surname: yup.string().required('Обязательное поле'),
    birthDate: yup
      .string()
      .matches(
        /^(\d{2})\.(\d{2})\.(\d{4})$/,
        'Дата рождения должна быть в формате: число.месяц.год',
      )
      .required('Обязательное поле'),
  });
  
  const initialValues = player
    ? {
      surname: player.surname,
      name: player.name,
      middleName: player.middleName,
      birthDate: format(new Date(player.birthDate), 'dd.MM.yyyy'),
      height: player.height,
      weight: player.weight,
      photo: player.tournamentAvatars?.length
        ? player.tournamentAvatars[ player.tournamentAvatars.length - 1 ].image
        : [],
    }
    : {
      surname: '',
      name: '',
      middleName: '',
      birthDate: '',
      height: '',
      weight: '',
    };
  const images = useSelector(tourneysCommonStoreImagesSelector);
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={teamPlayerValidator}
      onSubmit={handleSubmit}
    >
      {(formProps) => (
        <form
          className='d-flex flex-column justify-content-between h-100'
          onSubmit={formProps.handleSubmit}
        >
          <TeamPlayerForm>
            <div className='row'>
              <div className='col-6' style={{ paddingRight: 24 }}>
                <UDFormInput
                  name='surname'
                  label='Фамилия'
                  placeholder='Введите фамилию'
                />
                
                <div className='player-data'>
                  <UDFormInput
                    name='name'
                    label='Имя'
                    placeholder='Введите имя'
                  />
                  
                  <UDFormInput
                    name='middleName'
                    label='Отчество'
                    placeholder='Введите отчество'
                  />
                </div>
                
                <div className='player-data'>
                  <UDFormInput
                    name='birthDate'
                    label='Дата рождения'
                    placeholder='Укажите дату рождения'
                    mask='дд.мм.гггг'
                    formatter={birthDateFormatter}
                    inputComponent={BorderedInput}
                  />
                </div>
                
                <div className='player-data'>
                  <UDFormInput
                    name='height'
                    label='Рост игрока'
                    placeholder='Укажите рост'
                  />
                  
                  <UDFormInput
                    name='weight'
                    label='Вес игрока'
                    placeholder='Укажите вес'
                  />
                </div>
              </div>
              
              <div className='col-6' style={{ paddingLeft: 24 }}>
                <UDFormDropZone
                  name='photo'
                  label='Фото игрока'
                  value={
                    player && player.tournamentAvatars?.length
                      ? [
                        player.tournamentAvatars[
                        player.tournamentAvatars.length - 1
                          ].image,
                      ]
                      : images
                  }
                  isCover={false}
                  formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
                />
              </div>
            </div>
            
            <div className='form-buttons'>
              <UDButton
                type='button'
                variant='secondary'
                disabled={isLoading}
                className='mr-4'
              >
                Отменить
              </UDButton>
              
              <UDButton
                type='submit'
                variant='primary'
                disabled={isLoading}
                loading={isLoading}
              >
                Сохранить
              </UDButton>
            </div>
          </TeamPlayerForm>
        </form>
      )}
    </Formik>
  );
};

export default UpsertPlayerForm;
