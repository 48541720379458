import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function NewCupIcon(props: IProps) {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg
      width={width ?? 24}
      height={height ?? 24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg' {...rest}>
      <rect width='24' height='24' fill='white'/>
      <path
        d='M16.5 5.25H18.75C18.375 6.9375 17.0625 10.3125 14.8125 10.3125M7.5 5.25H5.25C5.625 6.9375 6.9375 10.3125 9.1875 10.3125M10.875 11.8452V14.8125H9.75V16.5M9.75 16.5H9.1875C9.1875 16.5 9.38398 17.232 9.1875 17.625C9.04857 17.9029 8.625 18.1875 8.625 18.1875V19.3125H15.375V18.1875C15.375 18.1875 14.9514 17.9029 14.8125 17.625C14.6161 17.232 14.8125 16.5 14.8125 16.5H14.25M9.75 16.5H14.25M14.25 16.5V14.8125H13.125V11.8701M7.5 4.125H16.5C16.3125 6.75 15.15 12 12 12C8.85 12 7.6875 6.75 7.5 4.125Z'
        stroke='currentColor'
        strokeWidth='0.8'
      />
    </svg>
  );
}
