import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { resetPressedState } from "../../../../store/templates/templates";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import { deleteTemplate } from "../../../../store/templates/actions";
import useTemplates from "../../useTemplates";

function useDeleteModal() {
  const dispatch = useDispatch()
  const { activeTemplate, isProcessed, isFinished, currentProcess } = useTemplates()
  
  const onSubmit = useCallback(() => {
    if (!activeTemplate) return
    dispatch(deleteTemplate({ id: activeTemplate.id }))
  }, [activeTemplate, dispatch]);
  
  const data: IUNModalProps = useMemo(() => {
    return {
      validator: () => ({}),
      initialValue: {},
      headerText: 'Удаление шаблона',
      contentText: 'Вы уверены, что хотите удалить этот шаблон? Это действие нельзя отменить.',
      buttonText: 'Удалить',
      onSubmit,
      isProcessed: isProcessed,
    }
  }, [isProcessed, onSubmit]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  useEffect(() => {
    if (!isFinished) { return; }
    if (currentProcess !== 'delete template') { return; }
    close()
    dispatch(resetPressedState())
  }, [isFinished, close, dispatch, currentProcess])
  
  return { modal, close, open }
}

export default useDeleteModal;
