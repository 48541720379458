import { TemplateExtend } from "../interfaces/supabase/overlay/auxiliary-types";
import boardDataMapper from "./board-data-mapper";
import { TemplateExtendDTO } from "../interfaces/supabase/overlay/dto";

const templateExtendDataMapper = (template: TemplateExtendDTO): TemplateExtend => {
  const { created_at, owner_id, sport_type, ...rest } = template
  
  return {
    ...rest,
    createdAt: new Date(created_at),
    ownerId: owner_id,
    boards: template.boards.map(boardDataMapper),
    sportType: sport_type,
  }
};

export default templateExtendDataMapper;
