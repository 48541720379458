import classNames from "classnames";
import { Button } from "../../../../../../ud-ui/components/button/styles";
import React, { useCallback } from "react";
import { FormikProps } from "formik";
import { eventsOptions, typeOptions } from "../events-selector/type";
import ImportIcon from "../../../../../../ud-ui/components/icon/import-icon";
import UDText from "../../../../../../ud-ui/components/text";
import { EventType } from "../../../../../../stream/domain/interfaces/events";
import { toast } from "react-toastify";

interface IProps {
  formProps: FormikProps<{ [ p: string ]: string }>
  isLoading: boolean
}

function ImportButton(props: IProps) {
  const {
    formProps,
    isLoading,
  } = props;
  
  const importBoard = useCallback((formProps: FormikProps<{ [ p: string ]: string }>) => {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = '.board';
      input.style.display = 'none';
      document.body.appendChild(input)
      input.onchange = () => {
        const reader = new FileReader();
        reader.onload = function (e) {
          if (e === null) return
          if (e.target === null) return
          const src = e.target.result;
          if (typeof src === "string") {
            const board = JSON.parse(decodeURIComponent(
              window.atob(
                src.split('data:application/octet-stream;base64,')[ 1 ])
                .split('')
                .map(function (c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); })
                .join('')));
            
            board?.name && formProps.setFieldValue('name', board.name);
            board?.type && formProps.setFieldValue('type', typeOptions.find(o => o.value === board.type));
            board?.objects && formProps.setFieldValue('objects', board.objects);
            board?.version && formProps.setFieldValue('version', board.version);
            switch (board?.type) {
              case "events": {
                board?.duration && formProps.setFieldValue('duration', board.duration);
                board?.events && formProps.setFieldValue('events', getEvents(board.events));
                break;
              }
              case "carousel": {
                board?.duration && formProps.setFieldValue('duration', board.duration);
                break;
              }
            }
            
            toast.success('Данные успешно импортированы')
          }
        }
        
        if (input.files) {
          reader.readAsDataURL(input.files[ 0 ]);
        }
      };
      
      input.click()
    }, [],
  );
  
  return (
    <Button
      onClick={() => {importBoard(formProps)}}
      type={'button'}
      style={{ height: 36, paddingInline: 14 }}
      className={
        classNames(
          isLoading && 'loading',
          'secondary',
          'flex-center',
        )
      }
    >
      <ImportIcon style={{ marginRight: '10px' }}/>
      <UDText type={'subhead'}>Импортировать файл</UDText>
    </Button>
  );
}

export default ImportButton;

function getEvents(events: EventType[]) {
  return eventsOptions.filter(option => events.includes(option.value))
}
