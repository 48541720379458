/** @jsxImportSource @emotion/react */
import UDText from "../text";
import React from "react";
import { MainBlock } from "./styles";

interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon?: JSX.Element
  color?: string
  hoverColor?: string
  width?: number
  height?: number
  disabled?: boolean
}

function UDEmptyButton(props: IProps) {
  const { icon, color = '#1a1a29', children, ...rest } = props
  
  return (
    <MainBlock color={color} {...rest}>
      {icon}
      {children && <UDText style={{ color: 'inherit', fontSize: 'inherit' }} type={'subhead'}>{children}</UDText>}
    </MainBlock>
  );
}

export default UDEmptyButton;
