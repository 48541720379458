import { Button, Item, LongDivider } from "../styles";
import { Object } from "fabric/fabric-impl";
import { useCallback } from "react";
import history from "../../../../../../ud-ui/components/fabric-canvas/addons/history";

interface IProps {
  objects: Object[]
  onChange: (value: boolean) => void
  value: boolean
  onClick?: () => void
}

function LockPosition(props: IProps) {
  const { objects, onChange, value, onClick } = props
  
  const toggleLockPosition = useCallback(() => {
    for (const object of objects) {
      history.saveState()
      object?.set({
        lockMovementX: !value,
        lockMovementY: !value,
        lockScalingX: !value,
        lockScalingY: !value,
        lockUniScaling: !value,
        lockRotation: !value,
        lockSkewingX: !value,
        lockSkewingY: !value,
        lockScalingFlip: !value,
      })
      history.saveState()
    }
    onChange(!value)
  }, [onClick, onChange, value, objects]);
  
  return (
    <>
      <LongDivider/>
      <Item>
        <Button className={'flex-center'} onClick={toggleLockPosition} style={{ height: 32, width: 175 }}>
          {value ? 'Разблокировать положение' : 'Заблокировать положение'}
        </Button>
      </Item>
    </>
  );
}

export default LockPosition;
