export enum GameEventType {
  GOAL = 'goal',
  PASS_AND_GOAL = 'pass_and_goal',
  FOUL = 'foul',
  CORNER = 'corner',
  PENALTY = 'penalty',
  SAVE = 'save',
  TIMEOUT = 'timeout',
  REPLACEMENT = 'replacement',
  YELLOW_CARD = 'yellowcard',
  RED_CARD = 'redcard',
  OWN_GOAL = 'owngoal',
  
  AFTER_MATCH_PENALTY_GOAL = 'after_match_penalty_goal',
  AFTER_MATCH_PENALTY_MISSED = 'after_match_penalty_missed',
  
  DEFAULT = 'default',
}

export interface GameEvent {
  id: number;
  type: GameEventType;
  seconds: number;
  minutes: number;
  extraMinutes: number;
  period: number;
  teamId: number;
  playerIds: number[];
  
  totalMinutes: number;
  videoUrl?: string;
}

export interface GameDetailsTeamMember {
  id: number;
  teamId: number;
  firstName: string;
  lastName: string;
  avatar: string;
  position: string;
  number: number;
}

export interface GameDetailsTeam {
  id: number;
  name: string;
  logo?: string;
  members: GameDetailsTeamMember[];
}

export interface GameDetailsScore {
  firstTeamScore: number;
  secondTeamScore: number;
}

export interface GameDetails {
  id: number;
  date: Date;
  sportType: string;
  championship: {
    id: number;
    name: string;
  };
  tournament: {
    id: number;
    name: string;
  };
  hasScore: boolean;
  result: GameDetailsScore;
  teams: [GameDetailsTeam, GameDetailsTeam];
  events: GameEvent[];
  round?: {
    id: number;
    stageId: number;
    name: string;
  };
  videoUrls: string[];
  timestamps?: { [ period: string ]: { second: number, url: string } };
  participants: number[];
}
