import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { TimeSliceState } from "./time";
import { resetTime, syncTime } from "./actions";

type TimeReducerBuilder = ActionReducerMapBuilder<TimeSliceState>;

export function createResetTimeReducer(builder: TimeReducerBuilder) {
  builder.addCase(resetTime, (state) => {
    state.isLoaded = false
    state.isLoading = false
    state.passedTime = 0
    state.period = 1
    state.startTime = state.isActive ? Date.now() : null
  });
}

export function createSyncTimeReducer(builder: TimeReducerBuilder) {
  builder.addCase(syncTime.pending, (state) => {
    state.isLoaded = false
    state.isLoading = true
  });
  
  builder.addCase(syncTime.fulfilled, (state, action) => {
    state.isLoaded = true
    state.isLoading = false
    state.passedTime = action.payload.seconds * 1000
    state.startTime = state.isActive ? Date.now() : null
    state.period = action.payload.currentPeriod
    
    console.debug({ event: 'time synchronized' })
  });
  
  builder.addCase(syncTime.rejected, (state) => {
    state.isLoaded = false
    state.isLoading = false
  });
}
