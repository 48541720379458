import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};

  & .header,
  & .content {
    width: 80%;
  }

  & .left,
  & .right {
    width: 50%;
  }
  
  .tournaments-list {
    overflow-x: auto;
  }
`;

export const AlreadyContainer = styled.div`
  height: max-content;
  border: 1px solid ${({ theme }) => theme.colors.SurfaceIndependence40};
  box-sizing: border-box;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.SurfaceWhite};
`;
