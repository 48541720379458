import { BasePlaceholder } from "./base";
import { CarouselPlaceholder } from "../../types/placeholders/createArgument";
import { Group } from "fabric/fabric-impl";

export class Carousel extends BasePlaceholder {
  
  create(props: CarouselPlaceholder) {
    let placeholder: Group | undefined = undefined
    
    if (props.type === 'text') {
      placeholder = this.createTextPlaceholder(props)
    } else if (props.type === 'image') {
      placeholder = this.createImagePlaceholder(props)
    }
    
    if (!placeholder) {
      throw new Error('Unknown placeholder type')
    }
    
    this.canvas.add(placeholder)
    return placeholder
  }
}
