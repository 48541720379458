import { useCallback, useEffect, useMemo } from "react";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import templateNameOnCreateValidator from "../../../../domain/validators/templateNameOnCreateValidator";
import { useDispatch } from "react-redux";
import widthValidator from "../../../../domain/validators/width.validator";
import heightValidator from "../../../../domain/validators/height.validator";
import { createTemplate } from "../../../../store/templates/actions";
import useTemplates from "../../useTemplates";
import { resetPressedState } from "../../../../store/templates/templates";
import { Values } from "../../../../domain/interface/form/template";
import useModal from "../../../../../ud-ui/components/new-modal/useModal";
import TemplateForm from "./forms/templateForm";
import { useAuth } from "../../../../../auth/ui/hooks/useAuth";
import { useLocation } from "react-router-dom";
import { accessOptions, visibilityOptions } from "../../../../domain/consts/form/template/options";
import valueTypeValidator from "../../../../domain/utils/form/template/valueTypeValidator";

function useCreateModal() {
  const dispatch = useDispatch()
  const { info } = useOrganizer()
  const { templates, isProcessed, isFinished, currentProcess } = useTemplates()
  const { Modal, close, open } = useModal()
  const { user } = useAuth()
  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'
  const isAdmin = useMemo(() => {
    if (isDev) return true
    if (!user) return false
    return user.id === 20
  }, [isDev, user])
  const location = useLocation()
  const isCatalog = location.pathname.includes('catalog')
  
  
  const validator = useCallback((values: Values) => {
    const errors: Values | undefined = {}
    
    if (typeof values.name === 'string') {
      const nameError = templateNameOnCreateValidator(values.name?.trim(), templates.map(category => category.name))
      if (nameError) {errors.name = nameError}
    }
    
    if (typeof values.width === 'string') {
      const widthError = widthValidator(values.width)
      if (widthError) {errors.width = widthError}
    }
    
    if (typeof values.height === 'string') {
      const heightError = heightValidator(values.height)
      if (heightError) {errors.height = heightError}
    }
    
    return errors
  }, [templates]);
  
  const onSubmit = useCallback((values: Values) => {
    if (!info) {throw Error('failed to create template: info is undefined')}
    const { width, height, name, visibility, access } = valueTypeValidator({ values })
    const resolution = { width: +width, height: +height };
    dispatch(createTemplate({
      name,
      ownerId: info.id,
      resolution,
      createStartBoard: true,
      sportType: info.sportType,
      visibility,
      access,
    }))
  }, [dispatch, info]);
  
  useEffect(() => {
    if (!isFinished) { return; }
    if (currentProcess !== 'create template') return;
    close()
    dispatch(resetPressedState())
  }, [isProcessed, isFinished, close, dispatch, currentProcess])
  
  const initialValue = useMemo(() => ({
    width: '1920',
    height: '1080',
    name: '',
    visibility: visibilityOptions.find(option => option.value === 'all') ?? visibilityOptions[ 0 ],
    access: accessOptions.find(option => option.value === (isCatalog ? 'default' : 'private')) ?? accessOptions[ 0 ],
  }), [isCatalog])
  
  const component = useMemo(() => (
    <Modal>
      <TemplateForm
        presets={'create'}
        initialValues={initialValue}
        onSubmit={onSubmit}
        validate={validator}
        isAdmin={isAdmin && isCatalog}
        isLoading={isProcessed}
      />
    </Modal>
  ), [Modal, initialValue, isAdmin, isCatalog, isProcessed, onSubmit, validator]);
  
  const onOpen = useCallback(() => {
    open({
      modalStyle: {
        padding: 0,
        zIndex: 100,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderColor: 'transparent',
        overflow: 'clip',
        background: '#00000085',
      },
    })
  }, [open]);
  
  return { modal: component, close, open: onOpen }
}

export default useCreateModal;
