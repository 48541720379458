import { combineReducers } from '@reduxjs/toolkit';
import { overlaySlice } from './overlay/overlay';
import { gameInfoSlice } from "./gameInfo/gameInfo";
import { timeSlice } from "./time/time";

const overlayReducer = combineReducers({
  overlay: overlaySlice.reducer,
  gameInfo: gameInfoSlice.reducer,
  time: timeSlice.reducer,
});

export default overlayReducer;
