import history from "../../../ud-ui/components/fabric-canvas/addons/history";
import useLocalStorage from "./useLocalStorage";
import { updateBoard } from "../../store/templates/actions";
import { resetPressedState } from "../../store/templates/templates";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveBoard } from "../../store/templates/selectors";
import { selectFabricManager } from "../../store/editor/selectors";
import { AppDispatch } from "../../../../store/store";
import { useCallback } from "react";
import { setHasChanges, setSavedScheme } from "../../store/editor/editor";

function useSaveScheme() {
  const dispatch = useDispatch<AppDispatch>()
  const activeBoardId = useSelector(selectActiveBoard)?.id;
  const manager = useSelector(selectFabricManager);
  const { getData, setData } = useLocalStorage()
  
  const saveScheme = useCallback(async () => {
    if (!manager) {
      throw new Error('No manager');
    }
    
    history.disableHistory()
    
    const { boardId, scheme } = getData()
    if (!boardId || !scheme) {
      throw new Error('No board id or scheme in local storage');
    }
    
    const currentScheme = manager.getScheme(true)
    
    setData({
      scheme: currentScheme,
      resolution: manager.resolution.value,
    })
    
    await dispatch(updateBoard({
      id: activeBoardId ?? boardId,
      objects: currentScheme.objects,
      version: currentScheme.version,
    }))
    
    dispatch(resetPressedState())
    dispatch(setSavedScheme(currentScheme))
    dispatch(setHasChanges(false))
    
    history.activeHistory()
    history.saveState()
  }, [activeBoardId, dispatch, getData, manager, setData]);
  
  return { saveScheme }
}

export default useSaveScheme;
