import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ReceivedTime } from "../../domain/interfaces/receivedTime";
import { getTime } from "../../domain/utils/getTime";
import supabase from "../../../core/supabase/supabase";

const PREFIX = 'time';

export const resetTime = createAction(`${PREFIX}/resetTime`);

export const syncTime = createAsyncThunk<ReceivedTime, { matchId: number }>(
  `${PREFIX}/syncTime`,
  async (payload, { rejectWithValue }) => {
    try {
      return await getTime(payload.matchId);
    } catch (error) {
      console.error(`${PREFIX}/fetchUser error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      
      supabase.overlayLogs.createError({ comment: 'Не удалось получить данные при синхронизации времени', message })
      return rejectWithValue(message)
    }
  },
)
