import { useEffect, useMemo } from "react";
import { syncGameInfo } from "../../store/gameInfo/actions";
import { fetchUserTemplates } from "../../../template-editor/store/templates/actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import useGameInfo from "./useGameInfo";
import { setActiveBoard, setActiveTemplate } from "../../../template-editor/store/templates/templates";
import { useQueryParam } from "use-query-params";
import { TokenStorage } from "../../../core/tokenStorage";
import { useParams } from "react-router-dom";
import supabase from "../../../core/supabase/supabase";
import { syncTime } from "../../store/time/actions";
import useTemplates from "../../../template-editor/ui/hooks/useTemplates";
import { getCurrentBoard } from "../../domain/utils/getCurrentBoard";
import { selectIsBoardLocked } from "../../store/overlay/selectors";
import {
  parseEvent,
  setFouls,
  setGoals,
  setPassedEventsId as setPassedEventsIdGameInfo,
} from "../../store/gameInfo/gameInfo";
import { addEvents, setPassedEventsId as setPassedEventsIdOverlay } from "../../store/overlay/overlay";
import { setPeriod, setTimer } from "../../store/time/time";
import { Event } from "../../domain/interfaces/events";
import { selectPeriod } from "../../store/time/selectors";
import { getFouls } from "../../domain/utils/getFouls";
import { Score } from "../../../core/supabase/tables/match-chronology";
import { useAuth } from "../../../auth/ui/hooks/useAuth";

function useInitOverlay() {
  const dispatch = useDispatch<AppDispatch>()
  const { matchId: id } = useParams()
  
  const matchId = useMemo(() => id ? parseInt(id) : 0, [id]);
  const encodeToken = useQueryParam('token')[ 0 ] as string | undefined
  const { organizer, championship } = useGameInfo()
  const { isLoaded } = useTemplates()
  const isBoardLocked = useSelector(selectIsBoardLocked)
  const statePeriod = useSelector(selectPeriod)
  
  const isDev = process.env.NODE_ENV !== 'production'
  const { user } = useAuth()
  const isAdmin = useMemo(() => {
    if (isDev) return true
    if (!user) return false
    return user.id === 20
  }, [isDev, user])
  
  useEffect(() => {
    if (!encodeToken) return
    const token = window.atob(encodeToken)
    const tokenStorage = new TokenStorage()
    tokenStorage.saveToken(token)
  }, [encodeToken]);
  
  useEffect(() => {
    supabase.overlayLogs.init({ matchId })
    dispatch(syncGameInfo({ matchId }))
    dispatch(syncTime({ matchId }))
    
    supabase.matchChronology.getRow(matchId).then((data) => {
      const passedEvents = data?.chronology.map(event => event.id) ?? []
      dispatch(setPassedEventsIdGameInfo(passedEvents))
      dispatch(setPassedEventsIdOverlay(passedEvents))
    })
  }, [dispatch, matchId])
  
  useEffect(() => {
    if (!organizer) return
    if (isLoaded) return;
    supabase.overlayLogs.init({ tournamentId: organizer.id })
    dispatch(fetchUserTemplates({ ownerId: organizer.id, visibility: isAdmin ? ['superAdmin', 'all'] : ['all'] }))
      .catch((data) => {
        supabase.overlayLogs.createError({
          comment: 'Не удалось получить шаблоны пользователя',
          message: data.toString(),
        })
      })
  }, [dispatch, isAdmin, isLoaded, organizer]);
  
  useEffect(() => {
    if (!championship?.overlayId) return
    dispatch(setActiveTemplate({ id: championship.overlayId }))
    supabase.overlayLogs.init({
      championshipId: championship.id,
      templateId: championship.overlayId,
    })
  }, [championship?.id, championship?.overlayId, dispatch]);
  
  useEffect(() => {
    supabase.matchChronology.observeChanges(matchId)
    
    return () => {
      supabase.matchChronology.stopObserveChanges()
    }
  }, [dispatch, matchId])
  
  useEffect(() => {
    
    const scoreCallback = (score: Score | null) => {
      if (!score) {
        supabase.overlayLogs.createWarning({
          message: 'No score received',
        })
        return
      }
      dispatch(setGoals({ first: score[ 0 ].score, second: score[ 1 ].score }))
    }
    
    supabase.matchChronology.score.subscribe(scoreCallback)
    
    
    return () => {
      supabase.matchChronology.score.unsubscribe(scoreCallback)
    }
  }, [organizer?.id, championship?.id, matchId, championship?.overlayId, dispatch]);
  
  useEffect(() => {
    const activeBoardCallback = (id: number | null) => {
      if (isBoardLocked) return
      dispatch(setActiveBoard({ id: id ?? undefined }));
    };
    
    const eventsCallback = (newEvents: Event[]) => {
      dispatch(parseEvent({ events: newEvents }))
      if (isBoardLocked) return
      dispatch(addEvents(newEvents));
    };
    
    supabase.matchChronology.activeBoardId.subscribe(activeBoardCallback)
    supabase.matchChronology.events.subscribe(eventsCallback)
    
    return () => {
      supabase.matchChronology.activeBoardId.unsubscribe(activeBoardCallback)
      supabase.matchChronology.events.unsubscribe(eventsCallback)
    }
  }, [dispatch, isBoardLocked]);
  
  useEffect(() => {
    
    const timeCallback = ({ seconds, period }: { seconds: number; period: number }) => {
      dispatch(setTimer({ milliseconds: seconds * 1000 }));
      dispatch(setPeriod(period));
      if (statePeriod !== period) {
        getFouls(matchId).then((fouls) => {
          dispatch(setFouls(fouls))
        })
      }
    };
    
    supabase.matchChronology.currentTime.subscribe(timeCallback)
    
    return () => {
      supabase.matchChronology.currentTime.unsubscribe(timeCallback)
    }
  }, [dispatch, matchId, statePeriod])
  
  useEffect(() => {
    if (!isLoaded) return
    getCurrentBoard(matchId).then(currentBoard => {
      dispatch(setActiveBoard({ id: currentBoard }))
    })
  }, [dispatch, matchId, isLoaded]);
}

export default useInitOverlay;
