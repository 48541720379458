import { ScheduleSliceState } from "../index";

export const markPassedDays = (state: ScheduleSliceState) => {
  state.weeks = state.weeks.map((week) => {
    week = week.map((day) => {
      if (!day.date) return day;
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      if (today.getTime() > day.date.getTime()) {
        day.status = "passed";
      }
      return day;
    });
    return week
  })
}
