import { TransformYoutubeUrl } from "./transformUrl.Youtube";
import { TransformVKUrl } from "./transformUrl.VK";

type AvailableDomains = 'youtube' | 'vk'

export class TransformUrl {
  static youtube = TransformYoutubeUrl
  static vk = TransformVKUrl
  
  static getDomain(url: string): AvailableDomains | null {
    if (this.youtube.isYoutubeUrl(url)) {
      return 'youtube';
    }
    
    if (this.vk.isVkUrl(url)) {
      return 'vk'
    }
    
    return null
  }
  
  static transformUrl(url: string): string | null {
    switch (this.getDomain(url)) {
      case 'youtube':
        return this.youtube.transformUrl(url);
      case 'vk':
        return this.vk.transformUrl(url);
      default:
        return null
    }
  }
  
  static getUrlTime(url: string): number | null {
    switch (this.getDomain(url)) {
      case 'youtube':
        return this.youtube.getUrlTime(url);
      case 'vk':
        return this.vk.getTime(url)
      default:
        return null
    }
  }
  
  static generateUrl = (url: string): string | null => {
    switch (this.getDomain(url)) {
      case 'youtube':
        return this.youtube.generateUrl(this.youtube.getUrlData(url));
      case 'vk':
        return this.vk.generateUrl(this.vk.getUrlData(url));
      default:
        return null
    }
  }
}
