import React from 'react';
import styled from '@emotion/styled';

const TeamSkeletonCard = styled.div`
  border-radius: 5px;
  width: 100%;
  height: 84px;
  margin-bottom: 16px;
`;

export const TeamsSkeleton = () => {
  const items = new Array(5).fill('');

  return (
    <div>
      {items.map(() => (
        <TeamSkeletonCard className="skeleton-block" />
      ))}
    </div>
  )
};
