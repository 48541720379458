import React, { useCallback } from 'react';
import classNames from 'classnames';
import { TeamCardHeaderRow } from './styles';
import UDText from 'modules/ud-ui/components/text';
import TeamLogo from '../../../../ud-ui/components/team-logo';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../../../navigation/domain/enums/pages';
import { Team } from '../../../domain/interfaces/Team';
import UDButton from '../../../../ud-ui/components/button';
import { useModal } from 'modules/ud-ui/components/modal/useModal';
import { DivProps } from "../../../../../typings";

type Source = 'common' | 'players';

type TeamCardHeaderProps = {
  team: Team;
  containerProps?: Omit<DivProps, 'children'>;
  prefixComponent?: JSX.Element;
  actions?: JSX.Element;
  index?: number;
};

const TeamCardHeader = (props: TeamCardHeaderProps) => {
  const navigate = useNavigate();
  const handleEditInfoClick = useCallback(
    (team: Team, source: Source) => {
      navigate(Pages.TEAMS.builders.edit(team.id, source), { replace: true });
    },
    [navigate],
  );
  
  const { team, containerProps, prefixComponent, actions, index } = props;
  const { open, close, Modal } = useModal({ defaultShow: false });
  
  return (
    <>
      <TeamCardHeaderRow
        {...containerProps}
        className={classNames(containerProps?.className)}
        onClick={() => handleEditInfoClick(team, 'common')}
      >
        <div className='team-card'>
          <div className='row'>
            {index !== undefined ? (
              <UDText type='subhead' className='team-column bold col-1'>
                {index + 1}
              </UDText>
            ) : (
              ''
            )}
            
            {prefixComponent}
            
            <div className='team-column col-4'>
              <TeamLogo
                className='team-logo mr-2'
                url={team.logo?.url}
                size={52}
              />
              <UDText type='subhead' className='bold'>
                {team.name}
              </UDText>
            </div>
            
            {team.createdAt ? (
              <UDText type='subhead' className='team-column col-2'>
                {team.createdAt}
              </UDText>
            ) : (
              ''
            )}
            
            <UDText
              type='subhead'
              className='team-column col-1'
              onClick={(e) => {
                e.stopPropagation();
                handleEditInfoClick(team, 'players');
              }}
            >
              Состав
            </UDText>
            
            <div className='team-column col-4 d-flex justify-content-end'>
              <UDButton
                className='d-flex align-items-center mr-3'
                variant='primary'
                onClick={(e) => {
                  e.stopPropagation();
                  open();
                }}
              >
                <span>В архив</span>
              </UDButton>
              
              <UDButton
                className='d-flex align-items-center'
                variant='secondary'
                onClick={(e) => {
                  e.stopPropagation();
                  open();
                }}
              >
                <span>Удалить</span>
              </UDButton>
            </div>
          </div>
        </div>
        {actions}
      </TeamCardHeaderRow>
      <Modal
        header={{ title: '', subtitle: '' }}
        containerProps={{
          style: {
            width: '500px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <UDText
          type='subhead'
          className='color-SurfaceRomanSilver30 px-10 mb-15'
          style={{ textAlign: 'center' }}
        >
          Данный функционал находится в разработке. Приносим свои извинения
          за доставленные неудобства
        </UDText>
        <UDButton
          className='d-flex align-items-center justify-content-center mb-3'
          variant='primary'
          onClick={(e) => {
            e.stopPropagation();
            close();
          }}
        >
          <span>Ок</span>
        </UDButton>
      </Modal>
    </>
  );
};

export default TeamCardHeader;
