import { GameEvent } from "../domain/interfaces/GameDetails";
import { TransformUrl } from "../../utils/transformUrl/transformUrl";

interface IProps {
  periodStartUrl: string
  event: GameEvent
  predictOnSeconds?: number
}

function generateMomentUrl(props: IProps) {
  const { periodStartUrl, event, predictOnSeconds = 20 } = props
  const periodStartTime = TransformUrl.getUrlTime(periodStartUrl)
  if (periodStartTime === null) return null
  
  const eventTime = event.seconds
  
  switch (TransformUrl.getDomain(periodStartUrl)) {
    case 'youtube':
      const { videoId, start: periodStartTime } = TransformUrl.youtube.getUrlData(periodStartUrl)
      const start = Math.max((periodStartTime ?? 0) - predictOnSeconds + eventTime, 0)
      return TransformUrl.youtube.generateUrl({ videoId: videoId, start })
    case 'vk':
      const { oid, id } = TransformUrl.vk.getUrlData(periodStartUrl)
      const totalSeconds = TransformUrl.vk.getTime(periodStartUrl)
      const time = Math.max((totalSeconds ?? 0) - predictOnSeconds + eventTime, 0)
      return TransformUrl.vk.generateUrl({ oid: oid, id: id, totalSeconds: time })
    default:
      return null
  }
}

export default generateMomentUrl
