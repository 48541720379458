import styled from "@emotion/styled";
import { CSSProperties } from "react";

export const EventCardSkeleton = styled.div`
  width: 100%;
  height: 80px;
  border-radius: 8px;
`

export const EventCardWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
`

export const teamLogoStyles: CSSProperties = {
  overflow: 'hidden',
  border: 'unset',
  backgroundColor: '#2D2D47',
  borderRadius: '8px 0 0 8px',
  minWidth: '80px'
}

export const EventIcon = styled.div`
  background: #2d2d47;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 64px;
`

export const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  min-width: 30%;
  padding: 8px;
  
  background: white;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  
  .game-event-player-info {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #1A1A29;
  }
  
  .game-event-player-position {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #8F8E99;
  }
`

export const TimeInfo = styled.div`
  background: white;
  border-radius: 0 8px 8px 0;
  padding: 8px;

  min-width: 20%;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;

  .game-event-period {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #1A1A29;
  }

  .game-event-minutes {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    color: #1A1A29;
  }

  .game-event-extra-minutes {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #8F8E99;
`
