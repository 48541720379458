import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { getChampionshipAnnouncedTeams } from '../../store/championshipTeams/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  announcedChampionshipTeamsDataSelector,
  announcedChampionshipTeamsIsLoadingSelector,
  championshipTeamsChampionshipIdSelector,
} from '../../store/championshipTeams/selectors';
import ChampionshipTeamPlayers from "../components/championship-team-players/championship-team-players";

const isNullOrUndefined = (value: any) => value === null || value === undefined;

const TeamPlayersPage = () => {
  const { id, teamId } = useParams();
  const championshipIdInt = isNullOrUndefined(id) ? null : parseInt(id!);
  const teamIdInt = isNullOrUndefined(teamId) ? null : parseInt(teamId!);
  
  const currentChampionshipId = useSelector(championshipTeamsChampionshipIdSelector);
  const isLoading = useSelector(announcedChampionshipTeamsIsLoadingSelector);
  const announcedTeams = useSelector(announcedChampionshipTeamsDataSelector);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (!championshipIdInt) {
      return;
    }
    
    if (!isLoading && (currentChampionshipId !== championshipIdInt || !announcedTeams)) {
      dispatch(getChampionshipAnnouncedTeams(championshipIdInt));
    }
  }, [championshipIdInt, isLoading, announcedTeams, currentChampionshipId, dispatch]);
  
  if (championshipIdInt === null || teamIdInt === null) {
    return null;
  }
  
  return (
    <div>
      <ChampionshipTeamPlayers
        teamId={teamIdInt}
        championshipId={championshipIdInt}
      />
    </div>
  )
};

export default TeamPlayersPage;
