import useModal from "../useModal";
import { useCallback, useMemo, useState } from "react";
import UDText from "../../../../../ud-ui/components/text";
import UDButton from "../../../../../ud-ui/components/button";
import useSaveScheme from "../../useSaveScheme";
import classNames from "classnames";
import { toast } from "react-toastify";

interface IProps {
  onSuccess: () => void
}

function useNotionModal(props: IProps) {
  const { onSuccess } = props
  const [isSaving, setIsSaving] = useState(false)
  const { saveScheme } = useSaveScheme()
  
  const save = useCallback(() => {
    setIsSaving(true)
    saveScheme().then(() => {
      toast.success('Табло успешно сохранено')
      onSuccess()
    }).catch((error) => {
      console.error(error)
      toast.error('Не удалось сохранить')
    })
  }, [saveScheme, onSuccess]);
  
  const modalContent = useMemo(() => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <UDText type={'title-3'} style={{ marginBottom: 15 }}>Сохранить изменения?</UDText>
      <UDText type={'subhead'} style={{ color: '#9093AA', marginBottom: 24 }}>Вы внесли изменения в текущем
        табло.</UDText>
      <div className={'d-flex align-items-center'} style={{ gap: 16 }}>
        <UDButton className={'flex-center'} style={{ height: 36, width: 130 }} variant={'gray'} onClick={onSuccess}>
          Нет
        </UDButton>
        <UDButton
          className={classNames({ 'loading': isSaving, 'flex-center': true })}
          style={{ height: 36, width: 130 }}
          variant={'primary'}
          onClick={save}
        >
          Сохранить
        </UDButton>
      </div>
    </div>
  ), [onSuccess, isSaving, save])
  
  const data = {
    children: modalContent,
  }
  
  const { modal, open, close } = useModal(data);
  
  const onOpen = useCallback(() => {
    open({
      modalStyle: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 100,
        background: '#0000008C',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        borderRadius: 0,
      },
      containerProps: {
        style: {
          minHeight: 179,
        },
      },
    })
  }, [open]);
  
  return { modal, open: onOpen, close };
}

export default useNotionModal;
