import { useEffect, useState } from 'react';
import Static from "../canvases/static";
import { UseFabricOptions } from "../types";
import Interactive from "../canvases/interactive";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";

export default function useFabric<T extends 'static' | 'interactive'>(type: T, options?: UseFabricOptions): {
  canvas: EmotionJSX.Element;
  manager: T extends 'static' ? Static : T extends 'interactive' ? Interactive : undefined;
} {
  const [manager, setManager] = useState<any>(undefined);
  const { data, style, dependence = [], id = 'canvas', resizing } = options ?? {};
  
  useEffect(() => {
    setManager(type === 'static' ? new Static({ ...data, id }) : new Interactive({ ...data, id }));
  }, [...dependence]);
  
  useEffect(() => {
    if (!manager) return;
    if (!resizing) return;
    const resize = () => {
      manager.normalizedViewport();
      manager.setParentSize()
    }
    resize();
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, [manager, resizing]);
  
  const canvas = (
    <canvas style={style} id={id}/>
  );
  
  return { manager, canvas };
}
