/** @jsxImportSource @emotion/react */
import React from 'react';
import { MainBlock } from "./styles";
import { AddPlaceholder } from "./addPlaceholders/addPlaceholder";
import { AddShape } from "./addShape";
import { AddText } from "./addText";
import { PenTool } from "./penTool";
import { AddImage } from "./addImage";
import { Copy } from "./copy";
import { Undo } from "./undo";
import { Redo } from "./redo";
import { Delete } from "./delete";
import { Group } from "./group";

function LeftToolbar() {
  return (
    <MainBlock>
      <AddPlaceholder/>
      <AddShape/>
      <AddText/>
      <PenTool/>
      <AddImage/>
      <Copy/>
      <Group/>
      <Undo/>
      <Redo/>
      <Delete/>
    </MainBlock>
  );
}

export default LeftToolbar;
