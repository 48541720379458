/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect } from "react";
import UDMainLayout from "../../../ud-ui/layout/main";
import HeaderContent from "../components/header-content";
import PlayerFilter from "../components/player-filter";
import BodyContent from "../components/body-content";
import useTournamentUsers from "../hooks/useTournamentUsers";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { fetchTournamentUsers, fetchTournamentUsersByFilter } from "../../store/actions";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import { resetFilter, setFilter, setTournamentId } from "../../store";

function PlayerListPage() {
  const dispatch = useDispatch<AppDispatch>()
  const { isLoaded, isLoading, playerLoadedLimit, allDataLoaded, players, pages, tournamentId } = useTournamentUsers()
  const { info } = useOrganizer()
  
  useEffect(() => {
    if (!info) return
    if (info.id === tournamentId) {
      if (isLoaded) return
      if (isLoading) return;
    }
    
    dispatch(setTournamentId({ tournamentId: info.id }))
    dispatch(fetchTournamentUsers({ tournamentId: info.id, limit: playerLoadedLimit }))
  }, [dispatch, info, isLoaded, isLoading, playerLoadedLimit, tournamentId]);
  
  useEffect(() => {
    if (!info) return;
    if (isLoading) return;
    if (players.length === 0) return;
    if (pages >= 10) return;
    if (allDataLoaded) return
    dispatch(fetchTournamentUsers({ tournamentId: info.id, limit: playerLoadedLimit, offset: players.length }))
  }, [info, allDataLoaded, dispatch, playerLoadedLimit, players.length, isLoading, pages]);
  
  const changeFilter = useCallback((changes: { search: string }) => {
    if (!info) return
    const { search } = changes
    dispatch(setFilter({ text: search }))
    if (search) {
      dispatch(fetchTournamentUsersByFilter({ nameFilter: search, tournamentId: info.id, limit: playerLoadedLimit }))
    } else {
      dispatch(resetFilter())
    }
  }, [dispatch, info, playerLoadedLimit]);
  
  useEffect(() => {
    dispatch(resetFilter())
  }, [dispatch]);
  
  return (
    <UDMainLayout headerContent={<HeaderContent/>}>
      <PlayerFilter onFilter={changeFilter}/>
      <BodyContent/>
    </UDMainLayout>
  );
}

export default PlayerListPage;
