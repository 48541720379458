import { useNavigate } from "react-router-dom";
import React, { useCallback, useMemo } from "react";
import { Pages } from "../../../navigation/domain/enums/pages";
import UDButton from "../../../ud-ui/components/button";
import UDIcon from "../../../ud-ui/components/icon";
import PlayerCard from "./player-card";
import { useSelector } from "react-redux";
import { selectCurrentPage, selectPlayersOnPage } from "../../store/selectors";
import useTournamentUsers from "../hooks/useTournamentUsers";
import { TeamsSkeleton } from "../../../tourneys/ui/components/edit-form/parts/teams/teams-skeleton";

function PlayerList() {
  const players = useSelector(selectPlayersOnPage)
  const {
    playersPerPage,
    filter: { isLoading: isTournamentUsersByFilterLoading, text: filterText },
  } = useTournamentUsers()
  
  const currentPage = useSelector(selectCurrentPage)
  
  const isFilterActive = useMemo(() => {
    return Boolean(filterText)
  }, [filterText]);
  
  const navigate = useNavigate();
  const handleCreateClick = useCallback(() => {
    navigate(Pages.TEAMS.CREATE);
  }, [navigate]);
  
  if (isTournamentUsersByFilterLoading) {
    return <TeamsSkeleton/>
  }
  
  if (!players.length) {
    return (
      <div className='container pt-6'>
        <div className='flex-column flex-center mt-24'>
          <div className='text body bold mb-2'>
            {isFilterActive ? (
              'Такого игрока нет'
            ) : (
              'У вас еще нет ни одной игрока'
            )}
          </div>
          <div className='text body'>Давайте добавим?</div>
          <div className='mt-6'>
            <UDButton
              className='d-flex align-items-center'
              variant='dark'
              onClick={handleCreateClick}
            >
              <UDIcon name='plus' componentProps={{ className: 'mr-2' }}/>
              <span>Добавить игрока</span>
            </UDButton>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className='pt-6'>
      {players.map((player, index) => (
        <PlayerCard
          key={player.id}
          player={player}
          index={index + (currentPage - 1) * playersPerPage}
        />
      ))}
    </div>
  );
}

export default PlayerList;
