import { ButtonWatch, ButtonWrapper, CanvasWrapper, TemplateItemWrapper } from "./styles";
import { useCallback, useMemo, useState } from "react";
import { useAuth } from "../../../../auth/ui/hooks/useAuth";
import RoundPlusIcon from "../../../../ud-ui/components/icon/round-plus-icon";
import useCreateModal from "../../hooks/modals/template/create";

interface IProps {}

function NewTemplate(props: IProps) {
  const [isHover, setIsHover] = useState(false)
  const { user } = useAuth()
  
  const { modal: createModal, open: openCreateModal } = useCreateModal()
  
  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'
  const isAdmin = useMemo(() => {
    if (isDev) return true
    if (!user) return false
    return user.id === 20
  }, [isDev, user]);
  
  const onCreateNewTemplate = useCallback(() => {
    if (!isAdmin) return
    openCreateModal()
  }, [isAdmin, openCreateModal]);
  
  return (
    <TemplateItemWrapper>
      <CanvasWrapper
        onClick={onCreateNewTemplate}
        style={{ backgroundColor: 'transparent' }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <ButtonWrapper>
          <ButtonWatch isShow={isHover}>
            Добавить новый шаблон
          </ButtonWatch>
        </ButtonWrapper>
        <RoundPlusIcon style={{ opacity: 0.38 }} width={50} height={50}/>
      </CanvasWrapper>
      {createModal}
    </TemplateItemWrapper>
  );
}

export default NewTemplate;
