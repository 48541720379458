import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createResetTimeReducer, createSyncTimeReducer } from "./reducers";

export type TimeSliceState = {
  isLoading: boolean,
  isLoaded: boolean,
  
  period: number
  
  passedTime: number
  isActive: boolean
  startTime: number | null
  lastChange: number | null
};

const initialState: TimeSliceState = {
  isLoading: false,
  isLoaded: false,
  
  period: 1,
  passedTime: 0,
  isActive: false,
  startTime: null,
  lastChange: null,
};

export const timeSlice = createSlice({
  name: 'time',
  initialState,
  extraReducers: builder => {
    createSyncTimeReducer(builder);
    createResetTimeReducer(builder);
  },
  reducers: {
    toggleTimer(state) {
      const isActive = !state.isActive
      state.isActive = isActive
      if (isActive) {
        state.startTime = Date.now()
      } else {
        if (!state.startTime) return
        state.passedTime += Date.now() - state.startTime
        state.startTime = null
      }
    },
    setTimer(state, action: PayloadAction<{ milliseconds: number }>) {
      state.startTime = Date.now()
      state.passedTime = action.payload.milliseconds > 0 ? action.payload.milliseconds : 0
    },
    clearLastChange(state) {
      state.lastChange = null
    },
    addTimer(state, action: PayloadAction<number>) {
      state.lastChange = (state.lastChange ?? 0) + action.payload;
      
      const isActive = state.isActive
      if (isActive) {
        if (!state.startTime) return
        const now = Date.now()
        state.passedTime += now - state.startTime
        state.startTime = now
      }
      
      let passedTime = state.passedTime + action.payload;
      state.passedTime = passedTime > 0 ? passedTime : 0
    },
    setPeriod(state, action: { payload: number }) {
      state.period = action.payload
    },
  },
});

export const {
  toggleTimer,
  setTimer,
  addTimer,
  setPeriod,
  clearLastChange,
} = timeSlice.actions
