import React from 'react';
import classNames from 'classnames';
import * as S from './styles';
import { UDTab } from "./UDTab";
import { Tab } from "./interfaces";

type Props = {
  tabs: Tab[];
  beforeChangeTab?: (hash: string) => any;
  className?: string;
  defaultTab: Tab;
};

const UDTabs = (props: Props) => {
  const { tabs, className } = props;
  
  return (
    <S.TabsContainer
      className={classNames('d-flex flex-row align-items-center', className)}
    >
      {tabs.map((tab) => (
        <UDTab key={tab.hash} tab={tab}/>
      ))}
    </S.TabsContainer>
  );
};

export default UDTabs;
