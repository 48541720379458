import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GeneralInformation } from "../../domain/interfaces/generalInformation";
import { getData } from "../../domain/utils/getData";
import supabase from "../../../core/supabase/supabase";

const PREFIX = 'gameInfo';

export const resetGameInfo = createAction(`${PREFIX}/resetGameInfo`);

export const syncGameInfo = createAsyncThunk<GeneralInformation, { matchId: number }>(
  `${PREFIX}/syncData`,
  async (payload, { rejectWithValue }) => {
    try {
      return await getData(payload.matchId);
    } catch (error) {
      console.error(`${PREFIX}/fetchUser error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      
      supabase.overlayLogs.createError({ comment: 'Не удалось получить данные при синхронизации', message })
      
      return rejectWithValue(message)
    }
  },
)
