import styled from "@emotion/styled";

export const TemplateItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CanvasWrapper = styled.div`
  width: 340px;
  height: 160px;
  border-radius: 8px;
  border: 1px solid #DDDDDD;
  
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  
  overflow: hidden;
  position: relative;
  
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transition: background-color 0.2s ease-in-out;
  }
  
  &:hover {
    cursor: pointer;
    
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #1A1A29B2;
      border-radius: 8px;
      transition: background-color 0.2s ease-in-out;
    }
  }
`

export const ButtonWatch = styled.button<{ isShow: boolean }>`
  border: 0.5px solid #F5D956;
  min-width: 110px;
  padding-inline: 6px;
  height: 26px;
  border-radius: 8px;
  cursor: pointer;
  color: #f5d956;
  z-index: 10;
  background-color: transparent;

  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
