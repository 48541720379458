import styled from "@emotion/styled";
import BaseModal from "../../../new-modal/baseModal";

export const BoardsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  min-width: 260px;
  width: 260px;
`

export const Header = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px 8px 0 0;
  
  background-color: #EAEAEC;
  font-size: 17px;
  color: #1A1A29;
  padding: 8px 16px;
`

export const List = styled.div`
  background-color: white;
  padding-block: 8px;
  border-radius: 0 0 8px 8px;
  
  max-height: 400px;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #2a2929;
    border-radius: 8px;
  }
`

export const BoardItem = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 8px 16px;
  background-color: transparent;
  height: 38px;
  min-height: 38px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  position: relative;

  p {
    width: 100%;
    max-width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  border-radius: 8px;
`

export const DropDownMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;

  width: 100%;
  max-width: 100%;

  background-color: #9093AA;
  top: 38px;

  overflow: hidden;
  //transition: max-height 0.2s linear;
  max-height: 0;

  &.show {
    //transition: max-height 0.1s ease-in;
    max-height: 300px;
  }
`

export const DropDownItem = styled.div`
  padding-inline: 16px;
  color: white;
  height: 36px;

  display: flex;
  align-items: center;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: #7E8198;
  }
`

export const Modal = styled(BaseModal)`
  background-color: #9093AA;
  border-radius: 8px;
  max-width: 0;
  padding: 0;

  //transition: max-height 0.2s linear, max-width 0s linear 0.2s;
  max-height: 36px;

  overflow: hidden;

  &.show {
    //transition: max-height 0.1s ease-in, max-width 0s ease-in;
    max-height: 300px;
    max-width: 300px;
  }
`
