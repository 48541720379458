import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function PaperClipIcon(props: IProps) {
  const {
    width = 16,
    height = 16,
    ...rest
  } = props;
  
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M10 8.5L7 5.5C6.44771 4.94771 5.55228 4.94772 5 5.5V5.5C4.44772 6.05228 4.44772 6.94772 5 7.5L10 12.5C11.1046 13.6046 12.8954 13.6046 14 12.5V12.5C15.1046 11.3954 15.1046 9.60457 14 8.5L7.82388 2.32388C6.98502 1.48502 5.81689 1.0621 4.63544 1.16951V1.16951C2.57649 1.35668 1 3.08299 1 5.15043V5.28377C1 6.38265 1.43653 7.43653 2.21356 8.21356L7.5 13.5'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
