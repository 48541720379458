import { RootState } from '../../../../store/store';
import { createSelector } from "@reduxjs/toolkit";

const selectOverlayState = (state: RootState) => state.overlay.overlay
export const selectIsBoardLocked = (state: RootState) => state.overlay.overlay.isBoardLocked
export const selectIsHideControlPanel = (state: RootState) => state.overlay.overlay.isHideControlPanel
export const selectEvents = createSelector(
  [selectOverlayState],
  (state) => state.events,
);
export const selectActiveEventId = createSelector(
  [selectOverlayState],
  (state) => state.activeEventId,
);
export const selectActiveEvent = createSelector(
  [selectEvents, selectActiveEventId],
  (events, activeEventId) => events.find((event) => event.id === activeEventId),
);
