import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { TeamPlayersNumber } from '../../../../shared/types/team-players-number';

export const tourneysChampionshipStoreSelector = (state: RootState) => state.tourneys.championship;

export const championshipIsLoadingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.isLoading,
);

export const championshipIsLoadedSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.isLoaded,
);


export const championshipTeamsIsLoadingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.teamsFlags.isLoading || false,
);

export const championshipTeamsIsLoadedSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.teamsFlags.isLoaded || false,
);

export const championshipStagesIsLoadingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.stagesFlags.isLoading || false,
);

export const championshipStagesIsLoadedSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.stagesFlags.isLoaded || false,
);

export const championshipStagesDataSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.stages || [],
);

export const championshipTeamsDataSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.teams,
);

export const championshipApplicationsDataSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.applications || [],
);


export const championshipISavingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.isSaving,
);

export const championshipDataSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data,
);

export const updatingApplicationTeamIdSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.updatingApplicationTeamId,
);

export const championshipStagesSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.stages || [],
);

export const isStageSavingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.isStageSaving || false,
);

export const championshipGamesIsLoadingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.gamesFlags.isLoading,
);

export const championshipGamesIsLoadedSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.gamesFlags.isLoaded,
);

export const championshipGamesSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.games || [],
);

export const championshipIsMatchSavingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.isMatchSaving || false,
);

export const championshipSymbolicTeamSelectedSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.selectedSymbolicTeam || null,
);

export const championshipSymbolicTeamIsSavingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.isSymbolicTeamSaving || false,
);

export const championshipTeamPlayersNumberSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.settings.teamPlayersNumber || TeamPlayersNumber.FIVE,
);

export const championshipSettingsSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.data?.settings,
);

export const championshipIsDeletingSelector = createSelector(
  tourneysChampionshipStoreSelector,
  (state) => state.isDeleting,
);
