/** @jsxImportSource @emotion/react */
import { Pages } from "../../../navigation/domain/enums/pages";
import { useCallback, useMemo } from "react";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import UDMainLayout from "../../../ud-ui/layout/main";
import CreatePlayerForm from "../components/create-player-form";
import { useNavigate } from "react-router-dom";
import { Player } from "../../domain/interfaces/player";

function PlayerCreatePage() {
  const navigate = useNavigate()
  
  const breadcrumb = useMemo(() => (<UDBreadcrumbs
    className='my-10' breadcrumbs={[
    { label: 'Игроки', to: Pages.PLAYERS.INDEX },
    { label: 'Добавление игрока', to: Pages.PLAYERS.CREATE },
  ]}
  />), []);
  
  const onSubmit = useCallback((player: Player) => {
    navigate(Pages.PLAYERS.builders.view(player.id));
  }, [navigate]);
  
  const initialValues = useMemo(() => ({}), []);
  
  return (
    <UDMainLayout headerContent={breadcrumb}>
      <div className={'container pt-6'}>
        <CreatePlayerForm
          onSubmit={onSubmit}
          initialValues={initialValues}
        />
      </div>
    </UDMainLayout>
  );
}

export default PlayerCreatePage;
