import styled from "@emotion/styled";

export const MainBlock = styled.div`
  min-width: 264px;
  //height: 271px;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 4px 8px 0 #1A1A2914,0 0 1px 0 #1A1A2914;
  background: #ffffff;
  
  padding: 6px 0 16px 0;
`

export const DaysWeek = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  
  color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
  text-transform: uppercase;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.SurfacePlatinum20};
`
