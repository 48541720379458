import { useSelector } from "react-redux";
import {
  selectChampionship,
  selectFouls,
  selectGoals,
  selectLoading,
  selectMatch,
  selectOrganizer, selectFormatStartDate,
  selectSynchronization,
  selectTeams,
} from "../../store/gameInfo/selectors";

const useGameInfo = () => {
  const organizer = useSelector(selectOrganizer)
  const match = useSelector(selectMatch)
  const championship = useSelector(selectChampionship)
  const teams = useSelector(selectTeams)
  const sync = useSelector(selectSynchronization)
  const load = useSelector(selectLoading)
  const goals = useSelector(selectGoals)
  const fouls = useSelector(selectFouls)
  const formatStartDate = useSelector(selectFormatStartDate)
  
  return {
    organizer,
    match,
    championship,
    teams,
    ...sync,
    ...load,
    goals,
    fouls,
    formatStartDate,
  }
}

export default useGameInfo
