import React from 'react';
import Select, { GroupBase, Props } from 'react-select';
import overriddenSelectComponents from './override';
import { getSelectStyles } from './styles';
import { SelectOption } from './types';

export type UDSelectProps<
  isMulti extends boolean = boolean,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
> = Omit<
  Props<SelectOption, isMulti, Group>,
  'hideSelectedOptions' | 'isSearchable' | 'styles'
> & { divStyle?: React.CSSProperties };

export const UDSelect = <
  isMulti extends boolean = false,
  Group extends GroupBase<SelectOption> = GroupBase<SelectOption>
>(props: UDSelectProps<isMulti, Group>) => {
  const {
    components,
    divStyle,
    ...restProps
  } = props;
  
  const styles = getSelectStyles<isMulti, Group>();
  const selectComponents = components || overriddenSelectComponents;
  
  return (
    <div style={divStyle} onClick={e => e.stopPropagation()}>
      <Select
        {...restProps}
        hideSelectedOptions={false}
        placeholder={restProps.placeholder || ''}
        closeMenuOnSelect={!restProps.isMulti}
        isSearchable={false}
        styles={styles}
        components={selectComponents}
      />
    </div>
  );
};
