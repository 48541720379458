import { Object } from "fabric/fabric-impl";
import { fabric } from "fabric";

export function getPositionFromGroup(props: {
  object: Object,
  x: 'left' | 'center' | 'right',
  y: 'top' | 'center' | 'bottom'
}): fabric.Point {
  const { x, y } = props;
  
  const getPosition = (object: Object): fabric.Point => {
    const pos = object.getPointByOrigin('center', 'center');
    if (object.group) {
      const group = object.group as Object;
      return getPosition(group).add(pos);
    } else {
      return pos;
    }
  }
  
  if (props.object.group) {
    return getPosition(props.object.group).add(props.object.getPointByOrigin(x, y));
  } else {
    return props.object.getPointByOrigin(x, y);
  }
}
