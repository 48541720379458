import React from 'react';

interface IProps {
  height?: number;
  width?: number;
}

function PlusIcon(props: IProps) {
  const {
    height,
    width,
  } = props
  
  return (
    <svg width={width ?? 16} height={height ?? 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 1V15M1 8H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  
  );
}

export default PlusIcon;
