import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Championship, Organizer } from '../../domain/interfaces/gameInfo/gameInfoMapped';
import { createResetAllReducer, createSyncDataReducer } from "./reducers";
import { Fouls, Goals, Match, Teams } from "../../domain/interfaces/gameInfo/state";
import { Event } from "../../domain/interfaces/events";
import parseInfoEvent from "./utils/parse-info.event";
import _ from "lodash";

export type GameInfoSliceState = {
  match?: Match,
  teams?: Teams,
  organizer?: Organizer,
  championship?: Championship,
  
  goals: Goals,
  fouls: Fouls,
  
  isLoading: boolean,
  isLoaded: boolean,
  
  isSynchronization: boolean,
  isSynchronize: boolean
  
  parsedEventsId: number[]
};

const initialState: GameInfoSliceState = {
  isLoaded: false,
  isLoading: false,
  isSynchronization: false,
  isSynchronize: false,
  goals: { first: 0, second: 0 },
  fouls: { first: 0, second: 0 },
  
  parsedEventsId: [],
};

export const gameInfoSlice = createSlice({
  name: 'gameInfo',
  initialState,
  reducers: {
    setGoals: (state, action: PayloadAction<{ first: number, second: number }>) => {
      state.goals = action.payload
    },
    setFouls: (state, action: PayloadAction<{ first: number, second: number }>) => {
      state.fouls = action.payload
    },
    parseEvent: (state, action: PayloadAction<{ events: Event[] }>) => {
      for (const event of action.payload.events) {
        if (_.find(state.parsedEventsId, (id) => id === event.id)) continue;
        state.parsedEventsId.push(event.id);
        switch (event.type) {
          case "GOAL":
          case "PASS_AND_GOAL":
          case "PENALTY_GOAL": {
            const { teamPrefix, team, player } = parseInfoEvent(event, state.teams)
            if (teamPrefix) {
              state.goals[ teamPrefix ] += 1
            }
            if (team && player) {
              team.members = team.members?.map((member) => {
                if (member.id === player.id) {
                  member.qtyGoalsMatch += 1
                }
                return member
              })
            }
            break
          }
          case "OWN_GOAL": {
            const { teamPrefix } = parseInfoEvent(event, state.teams, { teamReverse: true })
            if (teamPrefix) {
              state.goals[ teamPrefix ] += 1
            }
            break
          }
          case "FOUL": {
            const isFirst = event.teamId === state.teams?.first.id;
            const teamPrefix = isFirst ? 'first' : 'second'
            const team = state.teams?.[ teamPrefix ]
            if (team) {
              state.fouls[ teamPrefix ] += 1
            }
            break
          }
          case "RED_CARD": {
            const { player } = parseInfoEvent(event, state.teams)
            if (!player) break;
            player.qtyRedCardsMatch += 1;
            break;
          }
          case "YELLOW_CARD": {
            const { player } = parseInfoEvent(event, state.teams)
            if (!player) break;
            player.qtyYellowCardsMatch += 1;
            break;
          }
        }
      }
    },
    setPassedEventsId: (state, action: PayloadAction<number[]>) => {
      state.parsedEventsId = action.payload
    },
  },
  extraReducers: builder => {
    createSyncDataReducer(builder);
    createResetAllReducer(builder);
  },
});

export const {
  setGoals,
  setFouls,
  parseEvent,
  setPassedEventsId,
} = gameInfoSlice.actions
