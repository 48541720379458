export default function templateNameOnCreateValidator(templateName?: string, templateList?: string[]) {
  if (!templateName || templateName.length <= 0) {
    return 'Название шаблона не может быть пустым'
  }
  // if (categoryName.length > 30) {
  //   return 'Название шаблона слишком длинное'
  // }
  if (templateList && templateList.includes(templateName)) {
    return 'Такое название уже существует, придумайте новое.'
  }
  return undefined
}
