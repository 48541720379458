import { DivProps } from "../../../../../../typings";
import UDText from "../../../text";
import { BoardsList as MainBlock, Header, List } from "./styles";
import { useCallback } from "react";

interface IProps {
  headerText: string
  headerProps?: DivProps
  items: JSX.Element[]
}

function BoardsList(props: IProps) {
  const {
    headerText,
    headerProps,
    items,
  } = props
  
  const onScroll = useCallback(() => {
    const newEvent = new Event("scrollBoardsList");
    window.dispatchEvent(newEvent);
  }, []);
  
  return (
    <MainBlock id={'boardsList'}>
      <Header {...headerProps}>
        <UDText type={'subhead'} style={{ fontSize: 'inherit', color: 'inherit' }}>{headerText}</UDText>
      </Header>
      <List onScroll={onScroll}>
        {items}
      </List>
    </MainBlock>
  );
}

export default BoardsList;
