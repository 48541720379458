import { createAsyncThunk } from "@reduxjs/toolkit";
import { Calendar } from "../domain/interfaces/calendar";
import TournamentRepository from "../domain/repositories/TournamentRepository";
import { endOfMonth, startOfMonth } from "date-fns";

const PREFIX = 'schedule';


export const fetchCalendar = createAsyncThunk<{ calendar: Calendar }, {
  tournamentId: number,
  monthNum: number,
  yearNum: number
}>(
  `${PREFIX}/fetchCalendar`,
  async (payload, { rejectWithValue }) => {
    try {
      const startDate = startOfMonth(new Date(payload.yearNum, payload.monthNum));
      const endDate = endOfMonth(new Date(payload.yearNum, payload.monthNum));
      endDate.setHours(23, 59, 59, 999);
      const calendar = await TournamentRepository.fetchMatchesByDataRange(payload.tournamentId, startDate, endDate)
      
      return {
        calendar,
      }
    } catch (error) {
      console.error(`${PREFIX}/fetchCalendar error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  },
);
