import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import useFabric from "../../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import zoom from "../../../../../ud-ui/components/fabric-canvas/addons/zoom";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import backgroundElems from "../../../../../ud-ui/components/fabric-canvas/addons/backgroundElems";
import lineDrawer from "../../../../../ud-ui/components/fabric-canvas/addons/lineDrawer";
import layerManager from "../../../../../ud-ui/components/fabric-canvas/addons/layerManager";
import useLocalStorage from "../../../hooks/useLocalStorage";
import alignment from "../../../../../ud-ui/components/fabric-canvas/addons/alignment/alignment";
import useTemplates from "../../../hooks/useTemplates";
import useBoards from "../../../hooks/useBoards";
import {
  setActiveObjects,
  setFabricManager,
  setInit,
  setIsEditingText,
  setPreviewState,
  setSavedScheme,
} from "../../../../store/editor/editor";
import { selectPreviewState } from "../../../../store/editor/selectors";
import customization from "../../../../../ud-ui/components/fabric-canvas/addons/customization";

function Canvas() {
  const dispatch = useDispatch()
  const { getData } = useLocalStorage()
  const { activeTemplate } = useTemplates()
  const { activeScheme, activeBoard } = useBoards()
  const {
    scheme: templateBeforePreview,
    history: historyOfChanges,
    curHistoryIndex,
    resolution,
    backgroundColor,
    viewport,
  } = useSelector(selectPreviewState) ?? {}
  const startResolution = useMemo(() => {
    return activeTemplate ? activeTemplate.resolution : resolution
  }, [activeTemplate, resolution]);
  const { manager, canvas } = useFabric('interactive', {
    data: {
      ...startResolution,
      backgroundColor: '#FFFFFF',
    },
  })
  
  const resizeCanvas = useCallback(() => {
    if (!manager) return
    const { resolution } = getData();
    manager.setResolution(startResolution ?? resolution ?? { width: 1920, height: 1080 })
    manager.setDimensions({ width: window.innerWidth - 424, height: window.innerHeight - 135 })
  }, [getData, manager, startResolution]);
  
  useEffect(() => {
    dispatch(setInit(false))
    if (!manager) return
    dispatch(setFabricManager(manager))
    
    const { scheme: schemeLocalStorage, resolution, boardType } = getData();
    const loadableScheme = templateBeforePreview ?? activeScheme ?? schemeLocalStorage ?? '';
    
    manager.loadLocal({ scheme: loadableScheme, safeBackground: true })
      .then(() => {
          const resultResolution = startResolution ?? resolution ?? manager.resolution.value
          
          backgroundElems.drawBorder(manager.canvas, resultResolution)
          backgroundElems.drawGrid(manager.canvas, {
            resolution: resultResolution,
            gridStep: SnapToGrid.gridStep,
          })
          
          if (activeBoard?.type === 'carousel' || boardType === 'carousel') {
            backgroundElems.drawDivider(manager.canvas, {
              resolution: resultResolution,
            })
          }
          
          if (historyOfChanges && curHistoryIndex) {
            history.setHistory({ history: historyOfChanges, index: curHistoryIndex })
          } else {
            history.init()
          }
          if (backgroundColor) {
            manager.canvas.setBackgroundColor(backgroundColor, () => {})
          }
          if (viewport) {
            manager.canvas.setViewportTransform([...viewport])
          }
          
          if (!templateBeforePreview) {
            dispatch(setSavedScheme(manager.getScheme(true)))
          }
          
          dispatch(setPreviewState({}))
          history.activeHistory()
          manager.normalizedViewport()
          dispatch(setInit(true))
        },
      )
    
    history.loadManager(manager)
    zoom.init(manager)
    zoom.enableDragging()
    zoom.enableZoom()
    SnapToGrid.enableSnapToGrid(manager?.canvas)
    layerManager.init(manager)
    lineDrawer.init(manager.canvas)
    alignment.init(manager)
    
    manager.canvas.on('selection:created', (_) => {
      const activeObjects = manager.canvas.getActiveObjects()
      activeObjects.forEach(obj => obj.set({ ...customization.getAllProperties() }))
      dispatch(setActiveObjects(activeObjects))
    })
    
    manager.canvas.on('selection:updated', (_) => {
      const activeObjects = manager.canvas.getActiveObjects()
      activeObjects.forEach(obj => obj.set({ ...customization.getAllProperties() }))
      dispatch(setActiveObjects(activeObjects))
    })
    
    manager.canvas.on('selection:cleared', (_) => {
      dispatch(setActiveObjects([]))
    })
    
    manager.canvas.on("text:editing:entered", (_) => {
      dispatch(setIsEditingText(true))
    })
    
    manager.canvas.on("text:editing:exited", (_) => {
      dispatch(setIsEditingText(false))
    })
    
    window.addEventListener('resize', resizeCanvas, false);
    
    resizeCanvas();
    manager.normalizedViewport()
    
    return () => {
      window.removeEventListener('resize', resizeCanvas, false);
      history.clearHistory()
    };
    
    // eslint-disable-next-line
  }, [dispatch, manager])
  
  return canvas;
}

export default Canvas;
