import { UDSelect, UDSelectProps } from "../../../../../../ud-form/components/select/component";
import overridedSelectComponents from "../../../../../../ud-form/components/select/component/override";
import { MenuListCustom, MultiValueContainer, StyledSelect } from "./styles";
import { css } from "@emotion/react";
import classNames from "classnames";
import UDText from "../../../../../../ud-ui/components/text";
import React from "react";

export const CustomSelect = (props: UDSelectProps) => {
  const components = {
    ...overridedSelectComponents,
    Control: (props: any) => {
      const isOpen = props.selectProps.menuIsOpen;
      
      return (
        <StyledSelect
          {...props.innerProps}
          css={css`
            border: ${isOpen ? '1px solid #F5D956' : '1px solid transparent'};
            
            &:hover {
              border: 1px solid #F5D956;
            }
          `}
          className={classNames('d-flex flex-row pl-4', {
            disabled: props.isDisabled,
            focused: props.isFocused,
            multi: props.isMulti,
            opened: props.menuIsOpen,
          })}
        >
          {props.children}
        </StyledSelect>
      );
    },
    ValueContainer: (props: any) => {
      if (props.isMulti) {
        const allSelected = props?.selectProps?.value;
        const val = allSelected?.map((selected: any) => selected.label).join(" | ");
        
        const isOpen = props.selectProps.menuIsOpen;
        
        const onClick = () => {
          if (isOpen) {
            props.selectProps.onMenuClose();
          } else {
            props.selectProps.onMenuOpen();
          }
        }
        
        return (
          <MultiValueContainer
            onClick={onClick}
            className={classNames({
              opened: props.menuIsOpen,
              focused: props.isFocused,
            })}
          >
            {val ? (
              <UDText type={'subhead'}>{val}</UDText>
            ) : (
              <UDText type={'subhead'} style={{ color: '#9093AA' }}>{props.selectProps.placeholder}</UDText>
            )}
          </MultiValueContainer>
        );
      } else {
        return (
          <MultiValueContainer>
            {props.children}
          </MultiValueContainer>
        );
      }
    },
    MenuList: (props: any) => {
      const closeMenu = () => {
        props.selectProps.onMenuClose();
      }
      
      return (
        <>
          <MenuListCustom style={{ zIndex: 200 }} {...props.innnerProps}>{props.children}</MenuListCustom>
          <div
            onClick={closeMenu}
            style={{
              backgroundColor: 'transparent',
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 100,
              width: '100vw',
              height: '100vh',
            }}
          />
        </>
      );
    },
  };
  
  return <UDSelect {...props} components={components}/>
};
