import { Organizer } from "../interfaces/Organizer";
import { IOrganizerCard } from "../interfaces/OrganizerCard";

export class OrganizerToCardDataMapper {
  public decode(payload: Organizer): IOrganizerCard {
    return {
      name: payload.name,
      sportType: payload.sportType,
      logo: payload.logoUrl,
      phone: payload.phone,
      createAt: payload.createdAt,
      user: payload.adminUser && {
        name: payload.adminUser.name,
        surname: payload.adminUser.surname,
        middleName: payload.adminUser.middleName,
      },
      address: {
        city: payload.address.city,
        country: payload.address.country,
      },
    }
  }
}
