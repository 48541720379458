import styled from "@emotion/styled";

export const MainBlock = styled.div<{ width: number, isActive: boolean, isDisabled?: boolean }>`
  border: 1px solid ${({ isActive }) => isActive ? ' #F5D956' : '#E0E0E0'};
  background: #FFFFFF;
  box-shadow: 4px 4px 4px 0 #A7A7A740;
  position: relative;
  
  display: flex;
  flex-direction: row;
  width: ${({ width }) => (width)}%;
  padding: 20px 24px;
  
  border-radius: 8px;
  gap: 30px;
  
  &:hover {
    cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'pointer'};
    background-color: #FFFBEB;
  }
  
  transition: background-color 0.2s ease;
  
  align-items: center;
  
  ${({ isDisabled }) => isDisabled && `
  &:before {
    content: "";
    position: absolute;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    left: 0;
  }
  `}
`
