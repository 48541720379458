import React from 'react';

interface IProps {
  width?: number;
  height?: number;
  color?: string;
}

export default function SyncIcon(props: IProps) {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 13} height={height ?? 12} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M1 7.11111C1 7.11111 1.06673 7.58291 2.99982 9.53553C4.93292 11.4882 8.06708 11.4882 10.0002 9.53553C10.6851 8.84372 11.1273 7.9996 11.3269 7.11111M1 7.11111V10.4444M1 7.11111H4.3M12 4.88889C12 4.88889 11.9333 4.41709 10.0002 2.46447C8.06708 0.511845 4.93292 0.511845 2.99982 2.46447C2.31492 3.15629 1.87268 4.0004 1.67311 4.88889M12 4.88889V1.55556M12 4.88889H8.7"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
