/** @jsxImportSource @emotion/react */
import { buttonCss, MainBlock } from "./styles";
import Stars from "../../../../ud-ui/components/icon/stars";
import UDText from "../../../../ud-ui/components/text";
import UDButton from "../../../../ud-ui/components/button";
import useCopyModal from "../../hooks/modals/catalog/copy";
import { useCallback, useMemo } from "react";
import { history } from "../../../../../store/store";
import { Pages } from "../../../../navigation/domain/enums/pages";
import { useAuth } from "../../../../auth/ui/hooks/useAuth";
import { useSelector } from "react-redux";
import { selectActiveBoard } from "../../../store/templates/selectors";

interface IProps {
  templateId: number
}

function Header(props: IProps) {
  const { templateId } = props
  const { user } = useAuth()
  const activeBoard = useSelector(selectActiveBoard)
  
  const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'
  const isAdmin = useMemo(() => {
    return user?.id === 20 || isDev
  }, [user?.id, isDev])
  
  const onGoEdit = useCallback(() => {
    if (!activeBoard) {throw Error('activeBoard is undefined')}
    if (!isAdmin) {throw Error('user is not admin')}
    
    history.push(Pages.TEMPLATE.builders.editor(templateId, activeBoard.id), { from: 'catalog' })
  }, [activeBoard, isAdmin, templateId]);
  
  
  const { modal: copyModal, open: onOpenCopy } = useCopyModal(templateId)
  return (
    <MainBlock>
      <div className={'flex-center'}>
        <Stars style={{ minWidth: 52 }}/>
        <UDText className={'ml-2'} type={'subhead'}>
          Для редактирования этого шаблона необходимо сделать копию
        </UDText>
      </div>
      <div className={'flex-center'} style={{ gap: 20 }}>
        <UDButton css={buttonCss} variant={'light'} onClick={onGoEdit}>
          Редактировать
        </UDButton>
        <UDButton css={buttonCss} variant={'light'} onClick={onOpenCopy}>
          Скопировать
        </UDButton>
      </div>
      {copyModal}
    </MainBlock>
  );
}

export default Header;
