import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Event } from '../../domain/interfaces/events';

export type OverlaySliceState = {
  isLoading: boolean,
  isLoaded: boolean,
  
  featureIsAvailable?: boolean
  isBoardLocked: boolean
  isHideControlPanel: boolean
  
  events: Event[]
  passedEventsId: number[]
  activeEventId: number | null
};

const initialState: OverlaySliceState = {
  isLoading: false,
  isLoaded: false,
  isBoardLocked: false,
  isHideControlPanel: false,
  
  events: [],
  passedEventsId: [],
  activeEventId: null,
};

export const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    setEvents(state, action: { payload: Event[] }) {
      state.events = action.payload;
    },
    addEvents(state, action: PayloadAction<Event[]>) {
      const events = action.payload.filter(event => !state.passedEventsId.includes(event.id))
      
      state.events.push(...events)
      state.passedEventsId.push(...events.map(event => event.id))
    },
    shiftEvent(state) {
      state.events = state.events.slice(1)
    },
    setBoardLocked(state, action: PayloadAction<boolean>) {
      state.isBoardLocked = action.payload;
    },
    setHideControlPanel(state, action: PayloadAction<boolean>) {
      state.isHideControlPanel = action.payload;
    },
    setActiveEventId(state, action: PayloadAction<number | null>) {
      state.activeEventId = action.payload
    },
    setPassedEventsId(state, action: PayloadAction<number[]>) {
      state.passedEventsId = action.payload
    },
  },
});

export const {
  setEvents,
  setBoardLocked,
  setHideControlPanel,
  addEvents,
  shiftEvent,
  setActiveEventId,
  setPassedEventsId,
} = overlaySlice.actions
