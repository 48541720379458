import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { PlayerCardHeaderRow } from './styles';
import UDText from 'modules/ud-ui/components/text';
import { TeamPlayerAvatar } from '../../../team-card/styles';
import { TournamentUser } from '../../../../../domain/interfaces/TournamentUser';
import UDButton from 'modules/ud-ui/components/button';
import { differenceInYears, format } from 'date-fns';
import { deleteTournamentPlayerApplication } from '../../../../../store/players/actions';
import { DivProps } from "../../../../../../../typings";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../../../../../navigation/domain/enums/pages";
import { useModal } from "../../../../../../ud-ui/components/modal/useModal";

type PlayerCardHeaderProps = {
  teamId: number;
  player: TournamentUser;
  containerProps?: Omit<DivProps, 'children'>;
  index: number;
};

const defaultAvatar =
  'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';

const PlayerCardHeader = (props: PlayerCardHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { open, close, Modal } = useModal({ defaultShow: false })
  
  const { teamId, player, containerProps, index } =
    props;
  
  const name = [player.surname, player.name, player.middleName]
    .filter(Boolean)
    .join(' ');
  const birthDate = player.birthDate ? new Date(player.birthDate) : null;
  const birthDateString = birthDate ? format(birthDate, 'dd.MM.yyyy') : '';
  const years = birthDate ? differenceInYears(new Date(), birthDate) : null;
  const yearsString = years ? t('ages', { count: years }) : '';
  const fullBirthDate =
    [birthDateString, yearsString].filter(Boolean).join(', ') || '-';
  
  const handleKick = useCallback(() => {
    dispatch(
      deleteTournamentPlayerApplication({
        userId: player.id,
        teamId,
      }),
    );
  }, [dispatch, teamId, player.id]);
  
  const handleClickEdit = useCallback(() => {
    navigate(Pages.PLAYERS.builders.view(player.id))
  }, [navigate, player.id]);
  
  return (
    <PlayerCardHeaderRow
      {...containerProps}
      className={classNames(containerProps?.className)}
      onClick={() => {}}
    >
      <div className='player-card'>
        <div className='row'>
          {index !== undefined ? (
            <UDText type='subhead' className='player-column bold col-1'>
              {index + 1}
            </UDText>
          ) : (
            ''
          )}
          
          <div className='player-column col-4'>
            <TeamPlayerAvatar
              url={
                (player.tournamentAvatars?.length &&
                  player.tournamentAvatars[ player.tournamentAvatars.length - 1 ]
                    .image.externalUrl) ||
                defaultAvatar
              }
              className='player-avatar'
              size={40}
              square
            />
            <UDText type='subhead' className='bold'>
              {name}
            </UDText>
          </div>
          
          <UDText
            type='subhead'
            className='player-column col-2'
            style={{ flexBasis: '15%' }}
          >
            {fullBirthDate}
          </UDText>
          
          <div className='player-column col-5 d-flex justify-content-end'>
            <UDButton
              className='d-flex align-items-center mr-3'
              variant='primary'
              onClick={() => {
                handleClickEdit()
              }}
            >
              <span>Редактировать</span>
            </UDButton>
            
            <UDButton
              className='d-flex align-items-center'
              variant='secondary'
              onClick={open}
            >
              <span>Исключить</span>
            </UDButton>
          </div>
        </div>
      </div>
      
      <Modal>
        <UDText
          type='headline'
          className='color-SurfaceRomanSilver30 px-10'
          style={{ textAlign: 'center' }}
        >
          Вы действительно хотите исключить игрока
        </UDText>
        <UDText
          type='headline'
          className='color-SurfaceRomanSilver30 px-10 mb-15'
          style={{ textAlign: 'center' }}
        >
          {player.name} {player.surname} {player.middleName ?? ''}?
        </UDText>
        <div className={'d-flex w-100'} style={{ gap: 10 }}>
          <UDButton
            className='d-flex align-items-center justify-content-center mb-3 w-50'
            variant='primary'
            onClick={(e) => {
              e.stopPropagation();
              close();
            }}
          >
            <span>Нет</span>
          </UDButton>
          <UDButton
            className='d-flex align-items-center justify-content-center mb-3 w-50'
            variant='primary'
            onClick={(e) => {
              e.stopPropagation();
              handleKick();
            }}
          >
            <span>Да</span>
          </UDButton>
        </div>
      </Modal>
    </PlayerCardHeaderRow>
  );
};

export default PlayerCardHeader;
