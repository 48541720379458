import React, { useCallback } from 'react';
import styled from "@emotion/styled";
import { history, history as historyPage } from "../../../../../../store/store";
import ArrowLeftIcon from "../../../../../ud-ui/components/icon/arrow-left-icon";
import { Pages } from "../../../../../navigation/domain/enums/pages";
import useNotionModal from "../../../hooks/modals/notice";
import { useSelector } from "react-redux";
import { selectHasChange } from "../../../../store/editor/selectors";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useMatch } from "react-router-dom";

function Header() {
  const hasChange = useSelector(selectHasChange)
  const { getData } = useLocalStorage()
  const { templateId: templateIdUrl } = useMatch(Pages.TEMPLATE.BOARDS + '/*')?.params ?? {}
  
  const handleGoBack = useCallback(() => {
    const { from, templateId: templateIdLocalStorage } = getData()
    switch (from) {
      case 'boards': {
        if (templateIdLocalStorage || templateIdUrl) {
          historyPage.push(Pages.TEMPLATE.builders.boards(templateIdLocalStorage ?? Number(templateIdUrl)))
        } else {
          historyPage.push(Pages.TEMPLATE.INDEX)
        }
        break;
      }
      case 'catalog': {
        if (templateIdLocalStorage) {
          history.push(Pages.TEMPLATE.CATALOG.builders.card(templateIdLocalStorage))
        } else {
          history.push(Pages.TEMPLATE.CATALOG.INDEX)
        }
        break
      }
    }
  }, [getData, templateIdUrl])
  
  const onClickBack = () => {
    
    if (hasChange) {
      open()
    } else {
      handleGoBack()
    }
  }
  
  const { modal, open } = useNotionModal({ onSuccess: handleGoBack })
  
  return (
    <MainBlock>
      <Button onClick={onClickBack}><ArrowLeftIcon/> <span>Назад к списку</span></Button>
      {modal}
    </MainBlock>
  );
}

export default Header;

const MainBlock = styled.div`
  display: flex;
  flex-direction: row;
  height: 48px;
  min-height: 48px;
  background: #FDFEFF;
  width: 100%;
  border-bottom: 1px solid #E2E3E4;
  padding-right: 15px;
`

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  width: 200px;
  padding-right: 20px;
  padding-left: 15px;
  
  
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  
  font-family: Inter, sans-serif;
  color: black;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  gap: 10px;
`
