import styled from "@emotion/styled";

export const MainBlock = styled.div`
  padding: 30px 24px;
  display: flex;
  flex-direction: row;
  gap: 18px;
  
  width: 100%;
  
  height: calc(100%);
  overflow: hidden;
`

export const GamesWrapper = styled.div`
  overflow: auto;
  height: calc(100% - 38px);

  display: flex;
  flex-direction: column;
  gap: 10px;

  padding-bottom: 4px;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: inherit;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #EAEAEA;
    border-radius: 8px;
  }
`

export const GamesList = styled.div`
  &:not(:first-child) {
    .ChampionshipHeader {
      border-radius: 8px 8px 0 0;
    }
  }

`

export const ChampionshipHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  
  background-color: white;
  border-bottom: 1px #EAEAEC solid;
`

export const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin-right: 12px;
  height: 26px;
  width: 26px;
  transition: color 0.2s ease-in-out;
  border-radius: 8px;

  cursor: pointer;

  &:hover {
    color: #F5D956;
  }
`
