import { fabric } from "fabric";
import customization from "../customization";
import Interactive from "../../canvases/interactive";
import { IGroupOptions, IRectOptions, ITextboxOptions, Object } from "fabric/fabric-impl";
import elements from "../elements";
import Elements from "../elements";
import { commonPlaceholder } from "../../types/placeholders/createArgument";
import { MyTextOptions } from "../../types";

interface ConstructorProps {
  manager: Interactive
}

export class BasePlaceholder {
  protected readonly manager: Interactive;
  protected readonly canvas: fabric.Canvas;
  
  constructor(props: ConstructorProps) {
    this.manager = props.manager
    this.canvas = props.manager.canvas
  }
  
  protected createTextbox(props: { text: string, options?: ITextboxOptions }) {
    const { text, options = {} } = props
    
    return new fabric.Textbox(text, {
      fontSize: 20,
      fontFamily: 'Open Sans, sans-serif',
      fill: 'black',
      fontWeight: 'normal',
      textAlign: 'center',
      originX: 'center',
      originY: 'center',
      strokeWidth: 0,
      ...customization.getAllProperties(),
      ...options,
    });
  }
  
  protected createText(props: { text: string, options?: MyTextOptions }) {
    const { text, options = {} } = props
    
    return new fabric.Text(text, {
      fontSize: 20,
      fontFamily: 'Open Sans, sans-serif',
      fill: 'black',
      fontWeight: 'normal',
      textAlign: 'center',
      strokeWidth: 0,
      ...customization.getAllProperties(),
      ...options,
    })
  }
  
  protected createRect(props?: { options?: IRectOptions }) {
    const { options = {} } = props ?? {}
    
    return new fabric.Rect({
      width: 300,
      height: 100,
      strokeWidth: 0,
      fill: 'rgba(230, 230, 230, 0.6)',
      ...customization.getAllProperties(),
      ...options,
    })
  }
  
  protected createGroup(props?: { objects?: Object[], data?: any, options?: IGroupOptions }) {
    const { objects = [], data, options = {} } = props ?? {}
    
    return new fabric.Group(objects, {
      backgroundColor: 'rgba(230, 230, 230, 0.6)',
      strokeWidth: 0,
      ...customization.getAllProperties(),
      data: {
        fontSize: 20,
        fontFamily: 'Open Sans, sans-serif',
        color: '#000000',
        fontWeight: 'normal',
        textAlign: 'center',
        ...data,
      },
      ...options,
    })
  }
  
  createTextPlaceholder(props: commonPlaceholder) {
    const { blockText, ...rest } = props
    
    const { left, top } = elements.getLeftTop(this.canvas)
    const textbox = this.createTextbox({ text: blockText })
    return this.createGroup({
      objects: [textbox],
      data: rest,
      options: {
        left,
        top,
      },
    })
  }
  
  createImagePlaceholder(props: commonPlaceholder) {
    const { blockText, ...rest } = props
    
    const { left, top } = Elements.getLeftTop(this.canvas)
    
    const background = this.createRect()
    
    const center = background.getCenterPoint()
    const text = this.createText({
      text: blockText,
      options: {
        left: center.x,
        top: center.y,
        textAlign: 'center',
        originX: 'center',
        originY: 'center',
      },
    })
    
    return this.createGroup({
      objects: [background, text],
      data: rest,
      options: {
        left,
        top,
      },
    })
  }
}
