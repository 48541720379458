import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Player } from "../domain/interfaces/player";
import {
  createAddTournamentUserToTeamReducer,
  createDeleteTournamentUserReducer,
  createFetchPlayersReducer,
  createFetchTournamentUserReducer,
  createFetchTournamentUsersByFilterReducer,
  createFetchUserEventsReducer,
  createKickTournamentUserFromTeamReducer,
  createUpsertTournamentUserReducer,
} from "./redusers";
import { EventForMomentCard } from "../domain/interfaces/event";

export interface PlayersSliceState {
  players: Player[]
  events: { [ playerId: number ]: EventForMomentCard[] }
  currentPlayerId?: number
  
  playersPerPage: number
  pages: number
  currentPage: number
  
  isLoading: boolean
  isLoaded: boolean
  
  allDataLoaded: boolean
  playerLoadedLimit: number
  
  isProcessing: boolean
  isProcessed: boolean
  processName?: string
  
  filter: {
    text: string
    players: Player[]
    
    pages: number
    currentPage: number
    
    isLoading: boolean
    isLoaded: boolean
    allDataLoaded: boolean
  }
  
  tournamentId?: number
  
  moments: {
    perPage: number
    loadLimit: number
    
    pages: number
    currentPage: number
    
    allDataLoaded: boolean
  }
}

const initialState: PlayersSliceState = {
  players: [],
  events: {},
  
  playersPerPage: Number(localStorage.getItem('playersPerPage')) || 10,
  pages: 0,
  currentPage: 0,
  
  isLoading: false,
  isLoaded: false,
  
  allDataLoaded: false,
  playerLoadedLimit: 50,
  
  isProcessing: false,
  isProcessed: false,
  
  filter: {
    text: '',
    players: [],
    
    pages: 0,
    currentPage: 0,
    
    isLoading: false,
    isLoaded: false,
    allDataLoaded: false,
  },
  
  moments: {
    perPage: Number(localStorage.getItem('momentsPerPage')) || 9,
    loadLimit: 20,
    
    pages: 0,
    currentPage: 0,
    
    allDataLoaded: false,
  },
};

export const TournamentUsersSlice = createSlice({
  name: 'tournamentUsersSlice',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
      const isFilterActive = Boolean(state.filter.text)
      if (isFilterActive) {
        state.filter.currentPage = action.payload.currentPage
      } else {
        state.currentPage = action.payload.currentPage
      }
    },
    setPlayersPerPage: (state, action: PayloadAction<{ playersPerPage: number }>) => {
      const isFilterActive = Boolean(state.filter.text)
      state.playersPerPage = action.payload.playersPerPage
      localStorage.setItem('playersPerPage', state.playersPerPage.toString())
      
      if (isFilterActive) {
        state.filter.pages = Math.ceil(state.filter.players.length / state.playersPerPage)
        const currentPage = state.filter.currentPage;
        state.filter.currentPage = currentPage > state.filter.pages ? state.filter.pages : currentPage
      } else {
        state.pages = Math.ceil(state.players.length / state.playersPerPage)
        const currentPage = state.currentPage;
        state.currentPage = currentPage > state.pages ? state.pages : currentPage
      }
    },
    setFilter: (state, action: PayloadAction<{ text: string }>) => {
      state.filter.text = action.payload.text
    },
    resetFilter: (state) => {
      state.filter.text = ''
      state.filter.players = []
      
      state.filter.pages = 0
      state.filter.currentPage = 0
      
      state.filter.isLoading = false
      state.filter.isLoaded = false
      
      state.pages = Math.ceil(state.players.length / state.playersPerPage)
      const currentPage = state.currentPage;
      state.currentPage = currentPage > state.pages ? state.pages : currentPage
    },
    resetProcess: (state) => {
      state.isProcessing = false
      state.isProcessed = false
      state.processName = undefined
    },
    setTournamentId: (state, action: PayloadAction<{ tournamentId: number }>) => {
      state.tournamentId = action.payload.tournamentId
      state.players = []
      state.currentPage = 0
      state.pages = 0
    },
    setMomentsPerPage: (state, action: PayloadAction<{ perPage: number }>) => {
      state.moments.perPage = action.payload.perPage
      localStorage.setItem('momentsPerPage', state.moments.perPage.toString())
      
      const currentPlayerId = state.currentPlayerId
      if (currentPlayerId === undefined) return
      
      state.moments.pages = Math.ceil(state.events[ currentPlayerId ]?.length / state.moments.perPage)
      state.moments.currentPage = state.moments.currentPage > state.moments.pages ? state.moments.pages : state.moments.currentPage
    },
    setMomentsCurrentPage: (state, action: PayloadAction<{ currentPage: number }>) => {
      state.moments.currentPage = action.payload.currentPage
    },
    setCurrentPlayerId: (state, action: PayloadAction<{ playerId: number }>) => {
      state.currentPlayerId = action.payload.playerId
    },
    resetMomentsData: (state) => {
      state.moments = {
        perPage: Number(localStorage.getItem('momentsPerPage')) || 9,
        loadLimit: 20,
        
        pages: 0,
        currentPage: 0,
        
        allDataLoaded: false,
      }
    },
  },
  extraReducers: (builder) => {
    createFetchPlayersReducer(builder);
    createFetchTournamentUsersByFilterReducer(builder);
    createUpsertTournamentUserReducer(builder);
    createDeleteTournamentUserReducer(builder);
    createAddTournamentUserToTeamReducer(builder);
    createKickTournamentUserFromTeamReducer(builder);
    createFetchTournamentUserReducer(builder);
    createFetchUserEventsReducer(builder);
  },
});

export const {
  setPlayersPerPage,
  setCurrentPage,
  setFilter,
  resetFilter,
  setTournamentId,
  setMomentsPerPage,
  setMomentsCurrentPage,
  setCurrentPlayerId,
  resetMomentsData,
} = TournamentUsersSlice.actions

export default TournamentUsersSlice.reducer
