export type PresetsNameList = 'create' | 'edit' | 'duplicate' | 'copy' | 'delete';

interface IPresets {
  header: string;
  submitButton: string;
}

export const presets: Record<PresetsNameList, IPresets> = {
  create: {
    header: 'Новый шаблон',
    submitButton: 'Создать',
  },
  edit: {
    header: 'Редактирование шаблона',
    submitButton: 'Сохранить',
  },
  duplicate: {
    header: 'Дублирование шаблона',
    submitButton: 'Создать',
  },
  copy: {
    header: 'Копирование шаблона',
    submitButton: 'Создать',
  },
  delete: {
    header: 'Удаление шаблона',
    submitButton: 'Удалить',
  },
}
