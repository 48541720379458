import { ActiveSelection, Canvas, Group, IEvent, Object, Rect, Textbox } from "fabric/fabric-impl";
import { round } from "../../../../template-editor/domain/utils/helpers";

export default class SnapToGrid {
  static gridStep = 10
  static isSnapping = false
  
  static roundByGrid(x: number) {
    const round = Math.round(x / SnapToGrid.gridStep) * SnapToGrid.gridStep
    
    if (round <= 0) {
      return Math.ceil(x / SnapToGrid.gridStep) * SnapToGrid.gridStep
    } else {
      return round
    }
  }
  
  static onFabricObjectScaling(canvas: Canvas, e: IEvent<Event>) {
    if (!e.transform) return;
    const active = canvas.getActiveObject();
    if (!active) {return}
    if (active.top === undefined || active.left === undefined) {return;}
    if (active.width === undefined || active.height === undefined) {return;}
    if (active.scaleY === undefined || active.scaleX === undefined) {return;}
    
    const strokeWidth = active.strokeWidth ?? 1;
    const [width, height] = [active.getScaledWidth(), active.getScaledHeight()];
    
    // X
    if (['tl', 'ml', 'bl'].indexOf(e.transform.corner) !== -1) {
      const tl = this.roundByGrid(active.left);
      active.scaleX = (width + active.left - tl) / (active.width + strokeWidth);
      active.left = tl;
    } else if (['tr', 'mr', 'br'].indexOf(e.transform.corner) !== -1) {
      const tl = this.roundByGrid(active.left + width);
      active.scaleX = (tl - active.left) / (active.width + strokeWidth);
    }
    
    // Y
    if (['tl', 'mt', 'tr'].indexOf(e.transform.corner) !== -1) {
      const tt = this.roundByGrid(active.top);
      active.scaleY = (height + active.top - tt) / (active.height + strokeWidth);
      active.top = tt;
    } else if (['bl', 'mb', 'br'].indexOf(e.transform.corner) !== -1) {
      const tt = this.roundByGrid(active.top + height);
      active.scaleY = (tt - active.top) / (active.height + strokeWidth);
    }
    
    // Avoid singularities
    active.scaleX = (active.scaleY >= 0 ? 1 : -1) * Math.max(Math.abs(active.scaleX), 0.001);
    active.scaleY = (active.scaleY >= 0 ? 1 : -1) * Math.max(Math.abs(active.scaleY), 0.001);
    
    this.transformAfterScaling(active);
    canvas?.renderAll()
  }
  
  static transformAfterScaling(active: Object, parentScaling?: { scaleX: number, scaleY: number }) {
    const isActiveSelection = active?.type === 'activeSelection';
    const isGroupedObjects = active?.data?.type === 'group';
    if (isActiveSelection || isGroupedObjects) {
      const objects = (active as ActiveSelection).getObjects();
      for (const obj of objects) {
        this.transformAfterScaling(obj, active.getObjectScaling());
      }
      return
    }
    
    const isRect = active?.type === 'rect';
    if (isRect) this.snapReact(active as Rect, parentScaling);
    
    let isGroup = active?.type === 'group';
    if (isGroup) this.snapGroup(active as Group, parentScaling);
  }
  
  private static snapReact(active: Rect, parentScaling?: { scaleX: number, scaleY: number }) {
    const rx = active.data?.rx
    const ry = active.data?.ry
    if (!rx || !ry) return
    const scaleX = parentScaling?.scaleX ? parentScaling?.scaleX * (active.scaleX ?? 1) : active.scaleX
    const scaleY = parentScaling?.scaleY ? parentScaling?.scaleY * (active.scaleY ?? 1) : active.scaleY
    if (!scaleX || !scaleY) return
    active.set({ rx: rx * (1 / scaleX), ry: ry * (1 / scaleY) })
  }
  
  private static snapGroup(active: Group, parentScaling?: { scaleX: number, scaleY: number }) {
    const type = active.data?.type
    if (!type) return
    if (type === 'image') {
      this.snapImagePlaceHolder(active, parentScaling);
    } else if (type === 'text') {
      this.snapTextPlaceHolder(active, parentScaling);
    } else if (type === 'composition') {
      this.snapCompositionPlaceHolder(active, parentScaling);
    }
  }
  
  private static snapImagePlaceHolder(active: Group, parentScaling?: { scaleX: number, scaleY: number }) {
    if (active.top === undefined || active.left === undefined) {return;}
    if (active.width === undefined || active.height === undefined) {return;}
    
    const scaleX = parentScaling?.scaleX ? parentScaling?.scaleX * (active.scaleX ?? 1) : active.scaleX
    const scaleY = parentScaling?.scaleY ? parentScaling?.scaleY * (active.scaleY ?? 1) : active.scaleY
    if (scaleY === undefined || scaleX === undefined) {return;}
    
    const objects = active.getObjects()
    if (objects.length === 0) return;
    const text = objects[ 1 ]
    text.set({ scaleX: 1 / scaleX, scaleY: 1 / scaleY })
    const defaultScaleX = 1 / scaleX
    const defaultScaleY = 1 / scaleY
    
    if (!text.scaleX || !text.scaleY) return;
    if (!text.width || !text.height) return;
    if (text.width > active.width * scaleX) {
      if (text.width < active.width * scaleX) { return; }
      const factorScaleX = active.width * scaleX / text.width
      const factorScaleY = active.height * scaleY / text.height
      const factorScale = Math.min(factorScaleX, factorScaleY)
      text.set({ scaleX: defaultScaleX * factorScale, scaleY: defaultScaleY * factorScale })
    } else {
      if (text.height < active.height * scaleY) return;
      const factorScale = active.height * scaleY / text.height
      text.set({ scaleX: defaultScaleX * factorScale, scaleY: defaultScaleY * factorScale })
    }
  }
  
  private static snapTextPlaceHolder(group: Group, parentScaling?: { scaleX: number, scaleY: number }) {
    const scaleX = parentScaling?.scaleX ? parentScaling?.scaleX * (group.scaleX ?? 1) : group.scaleX
    const scaleY = parentScaling?.scaleY ? parentScaling?.scaleY * (group.scaleY ?? 1) : group.scaleY
    if (group.top === undefined || group.left === undefined || group.width === undefined ||
      group.height === undefined || scaleY === undefined || scaleX === undefined) {return;}
    
    const objects = group.getObjects()
    if (objects.length === 0) return;
    const text = objects[ 0 ] as Textbox
    if (!text) return
    const defaultScaleX = 1 / scaleX
    const defaultScaleY = 1 / scaleY
    text.set({ scaleX: defaultScaleX, scaleY: defaultScaleY })
    if (!text.width || !text.height) return;
    const fontSize = (group.data?.fontSize ?? 20) / defaultScaleY
    text.set({ width: group.width * scaleX * 0.98, fontSize })
    // text.set({ width: group.width * scaleX, fontSize })
    const textboxWidth = text.width
    
    if (textboxWidth > group.width * scaleX || text.height > group.height * scaleY) {
      const factorScaleX = group.width * scaleX / textboxWidth
      const factorScaleY = group.height * scaleY / text.height
      const factorScale = Math.min(factorScaleX, factorScaleY)
      text.set({ scaleX: defaultScaleX * factorScale * 0.95, scaleY: defaultScaleY * factorScale })
      // text.set({ scaleX: defaultScaleX * factorScale, scaleY: defaultScaleY * factorScale })
    }
  }
  
  static onFabricObjectMoving(canvas: Canvas, event: IEvent<Event>) {
    const coord = event.target?.getPointByOrigin('left', 'top');
    if (!coord) return
    event.target?.set({ left: SnapToGrid.roundByGrid(coord.x), top: SnapToGrid.roundByGrid(coord.y) });
    canvas.renderAll();
  }
  
  static setGridStep(step: number) {
    this.gridStep = step
  }
  
  static enableSnapToGrid(canvas: Canvas) {
    canvas.on('object:scaling', this.onFabricObjectScaling.bind(this, canvas))
    canvas.on('object:moving', this.onFabricObjectMoving.bind(this, canvas))
    this.isSnapping = true
  }
  
  static disableSnapToGrid(canvas: Canvas) {
    canvas.off('object:scaling', this.onFabricObjectScaling.bind(this, canvas))
    canvas.off('object:moving', this.onFabricObjectMoving.bind(this, canvas))
    this.isSnapping = false
  }
  
  private static snapCompositionPlaceHolder(active: Group, parentScaling: {
    scaleX: number;
    scaleY: number
  } | undefined) {
  
  }
  
  static snapToGrid(objects: Object[]) {
    for (const object of objects) {
      const left = round(object.left ?? 0)
      const top = round(object.top ?? 0)
      object.set({
        left: this.roundByGrid(left),
        top: this.roundByGrid(top),
      })
      
      if (object?.data?.type === 'text') {
        const width = round(object.width ?? 0)
        const height = round(object.height ?? 0)
        object.set({
          width: this.roundByGrid(width),
          height: this.roundByGrid(height),
        })
      } else {
        if (object.scaleX === undefined || object.scaleY === undefined) {return;}
        if (object.width === undefined || object.height === undefined) {return;}
        if (object.left === undefined || object.top === undefined) {return;}
        
        const [scaledWidth, scaledHeight] = [object.getScaledWidth(), object.getScaledHeight()];
        const strokeWidth = object.strokeWidth ?? 1;
        
        const tl = this.roundByGrid(object.left + scaledWidth);
        object.scaleX = (tl - object.left) / (object.width + strokeWidth);
        
        const tt = this.roundByGrid(object.top + scaledHeight);
        object.scaleY = (tt - object.top) / (object.height + strokeWidth);
        
        object.scaleX = (object.scaleY >= 0 ? 1 : -1) * Math.max(Math.abs(object.scaleX), 0.001);
        object.scaleY = (object.scaleY >= 0 ? 1 : -1) * Math.max(Math.abs(object.scaleY), 0.001);
      }
      
      this.transformAfterScaling(object)
    }
  }
}
