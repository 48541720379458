import { TemplateExtend } from "../../../core/domain/interfaces/supabase/overlay/auxiliary-types";

export default function sortTemplates(templates: TemplateExtend[]) {
  let defaults = []
  let others = []
  
  for (const template of templates) {
    if (template.access === 'default') {
      defaults.push(template);
    } else {
      others.push(template);
    }
  }
  
  return [
    ...defaults.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()),
    ...others.sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime()),
  ];
}
