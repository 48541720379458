import { GameEvent } from '../interfaces/GameDetails';
import { GameEventDTO } from '../interfaces/GameEventDTO';
import { GamePeriodsSettings, getGameEventTime } from './match-periods-settings';

export function mapGameEvent(dto: GameEventDTO, periodSettings: GamePeriodsSettings): GameEvent {
  const {
    minutes,
    extraMinutes,
  } = getGameEventTime(periodSettings, dto.period!, dto.seconds!);
  
  return {
    id: dto.id || Date.now(),
    teamId: dto.teamId,
    type: dto.type,
    period: dto.period,
    seconds: dto.seconds,
    minutes,
    extraMinutes,
    playerIds: dto.firstPlayerId
      ? dto.secondPlayerId ? [dto.firstPlayerId, dto.secondPlayerId] : [dto.firstPlayerId]
      : [],
    videoUrl: dto.videoUrl,
  } as GameEvent;
}
