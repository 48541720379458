import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function ExportIcon(props: IProps) {
  const {
    width = 24,
    height = 24,
    ...rest
  } = props;
  
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M20 14.6667V15.7333C20 17.2268 20 17.9735 19.7094 18.544C19.4537 19.0457 19.0457 19.4537 18.544 19.7094C17.9735 20 17.2268 20 15.7333 20H8.26667C6.77319 20 6.02646 20 5.45603 19.7094C4.95426 19.4537 4.54631 19.0457 4.29065 18.544C4 17.9735 4 17.2268 4 15.7333V14.6667M16.4444 8.44444L12 4M12 4L7.55556 8.44444M12 4V14.6667'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
