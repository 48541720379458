import { combineReducers } from '@reduxjs/toolkit';
import templates from "./templates/templates";
import editor from "./editor/editor";

const templateEditorReducer = combineReducers({
  templates: templates,
  editor: editor,
});

export default templateEditorReducer;
