import React from 'react';
import { MainBlock } from "./styles";
import UDText from "../../../../ud-ui/components/text";
import { DivProps } from "../../../../../typings";

interface IProps extends DivProps {
  isActive: boolean
  isDisabled?: boolean
  widthPercent: number
  name: string
  date?: {
    from?: string
    to?: string
  }
  price: number
}

function PlanCard(props: IProps) {
  const { isActive, widthPercent, name, date, price, isDisabled, ...restProps } = props;
  
  return (
    <MainBlock width={widthPercent} isActive={isActive} isDisabled={isDisabled} {...restProps}>
      <UDText type={'subhead'}>{name}</UDText>
      <UDText
        type={'caption-1'}
        style={{ flexGrow: 1, color: '#8D8D8D' }}
      >
        {date?.from && `от ${date?.from}`} {date?.to && `до ${date?.to}`}
      </UDText>
      <UDText type={'subhead'} className={'bold'}>{price} руб.</UDText>
    </MainBlock>
  );
}

export default PlanCard;
