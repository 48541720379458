import BaseFabric from "./baseFabric";
import { CanvasConstructor } from "../types";
import { Canvas } from "fabric/fabric-impl";
import SnapToGrid from "../addons/snapToGrid";
import customization from "../addons/customization";
import { fabric } from "fabric";

export default class Interactive extends BaseFabric {
  canvas: Canvas
  
  constructor(data?: CanvasConstructor) {
    super();
    const { width = 1920, height = 1080, backgroundColor = 'transparent', id = 'canvas' } = data ?? {}
    
    this.canvas = new fabric.Canvas(id, {
      height,
      width,
      imageSmoothingEnabled: false,
      preserveObjectStacking: true,
      backgroundColor: backgroundColor ?? '#FFFFFF',
    })
    
    this.setResolution({ width, height })
    this.init()
  }
  
  sendBackgroundElemsToBack() {
    this.canvas.getObjects().forEach((obj) => {
      if (obj.data?.isSave === false) {
        this.canvas.sendToBack(obj)
      }
    })
  }
  
  reselectActiveObjects() {
    const activeElem = this.canvas.getActiveObjects()
    this.canvas.discardActiveObject()
    const activeSelection = new fabric.ActiveSelection(activeElem, { canvas: this.canvas })
    this.canvas.setActiveObject(activeSelection)
    this.canvas.requestRenderAll()
  }
  
  getLeftTopCurVP() {
    const viewport = this.canvas.viewportTransform;
    const zoom = this.canvas.getZoom()
    const left = SnapToGrid.roundByGrid(viewport ? -1 * viewport[ 4 ] / zoom + 20 : 20);
    const top = SnapToGrid.roundByGrid(viewport ? -1 * viewport[ 5 ] / zoom + 20 : 20);
    return { left, top };
  }
  
  groupObjects(props: { objects: fabric.Object[], options?: fabric.IGroupOptions }): fabric.Group {
    const {
      objects = [],
      options = {},
    } = props
    
    const clonedObjects: fabric.Object[] = []
    
    for (const object of objects) {
      object.clone((cloned: fabric.Object) => {
        clonedObjects.push(cloned)
        this.canvas.remove(object)
      }, ['data'])
    }
    
    const group = new fabric.Group(clonedObjects, {
      ...customization.getAllProperties(),
      data: {
        type: 'group',
        ...options?.data,
      },
      ...options,
    });
    
    this.canvas.add(group)
    
    return group
  }
}
