import React, { useMemo } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import UDTabs from 'modules/ud-ui/components/tabs';
import { defaultTab, TeamEditFormPart } from './constants';
import UDText from '../../../../ud-ui/components/text';
import { Team } from '../../../domain/interfaces/Team';
import { Tab } from "../../../../ud-ui/components/tabs/interfaces";
import { Pages } from "../../../../navigation/domain/enums/pages";

type CreateEditFormParams = {
  tab: Tab;
  count?: number;
  isActive?: boolean;
  subLabel?: string;
};

const createEditFormTab = (params: CreateEditFormParams): Tab => {
  const { tab, count, isActive, subLabel } = params;
  
  if (count !== undefined) {
    const badgeBgColor = isActive
      ? 'bgc-SurfaceWhite'
      : 'bgc-AccentNapplesYellow';
    const className = classNames([
      'color-SurfaceRaisinBlack50',
      badgeBgColor,
      'p-1',
      'br-4',
      'ml-2',
      'line-height-1',
      'caption-1',
    ]);
    
    return {
      hash: tab.hash,
      label: (
        <>
          {tab.label}{' '}
          <UDText type='subhead' className={className}>
            {count}
          </UDText>
        </>
      ),
    };
  }
  
  return { ...tab, subLabel };
};

type TeamEditFormTabsProps = {
  team: Team;
};

const TeamEditFormTabs = ({ team }: TeamEditFormTabsProps) => {
  const location = useLocation();
  const activeTab = (location.hash || '').split('/')[ 0 ];
  const createNewPlayer = useMatch(`${Pages.TEAMS.EDIT}/players/create`);
  
  const tabs = useMemo(() => {
    return Object.values(TeamEditFormPart).map((tab) =>
      createEditFormTab({
        tab: tab,
        isActive: tab.hash === activeTab,
        subLabel: tab.hash === TeamEditFormPart.PLAYERS.hash && createNewPlayer ? 'Создание игрока' : undefined,
      }),
    );
  }, [activeTab, createNewPlayer]);
  
  return <UDTabs tabs={tabs} defaultTab={defaultTab}/>;
};

export default TeamEditFormTabs;
