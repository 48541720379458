import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const UDHeaderWrapper = styled.div`
  border-radius: 8px;
`

export const defaultCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  background-color: white;
  border-radius: 8px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-size: 17px;
  color: #1A1A29;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
