import React, { useEffect, useMemo } from "react";
import useFabric from "../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import useTemplates from "../../../../template-editor/ui/hooks/useTemplates";
import { millisToMinutesAndSeconds } from "../../../domain/utils/convertTime";
import useTimer from "../../hooks/useTimer";
import { CanvasWrapper } from "./styles";
import useGameInfo from "../../hooks/useGameInfo";
import FadeIn from "../../../../ud-ui/components/fabric-canvas/addons/fadeIn";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveEventId, selectEvents } from "../../../store/overlay/selectors";
import { AppDispatch } from "../../../../../store/store";
import { selectActiveTemplate } from "../../../../template-editor/store/templates/selectors";
import { setActiveEventId, shiftEvent } from "../../../store/overlay/overlay";
import { Scheme } from "../../../../ud-ui/components/fabric-canvas/types";
import { time } from "../../../domain/constants/time";
import useTime from "../../hooks/useTime";
import convertorEventToReplacer from "../../../domain/utils/convertor.event-to-replacer";
import parseInfoEvent from "../../../store/gameInfo/utils/parse-info.event";
import ReplaceCommon from "../../../../ud-ui/components/fabric-canvas/addons/replacer/replaceCommon";
import ReplaceEvent from "../../../../ud-ui/components/fabric-canvas/addons/replacer/replaceEvent";

export function CanvasEventBoards() {
  const dispatch = useDispatch<AppDispatch>()
  const { canvas, manager } = useFabric('static', { id: "events" })
  const replacer = useReplacer(manager)
  const { activeTemplate } = useTemplates()
  const { timer } = useTimer();
  const { period } = useTime()
  const events = useSelector(selectEvents)
  const boards = useSelector(selectActiveTemplate)?.boards
  const activeEvent = useSelector(selectActiveEventId)
  const fadeIn = useMemo(() => {
    return new FadeIn()
  }, []);
  const gameInfo = useGameInfo()
  const replaceCommon = useMemo(() => {
    return new ReplaceCommon(replacer, fadeIn)
  }, [fadeIn, replacer]);
  const replaceEvent = useMemo(() => {
    return new ReplaceEvent(replacer, fadeIn)
  }, [fadeIn, replacer]);
  
  useEffect(() => {
    if (!manager) return
    const event = events[ 0 ]
    if (!event) return
    if (activeEvent) return
    if (!boards) return
    const boardsEvent = boards.filter(board => board.type === 'events' && board.events.includes(event.type))
    
    if (!boardsEvent.length) {
      dispatch(shiftEvent())
      return;
    }
    
    let count = 0
    
    const eventData = convertorEventToReplacer(
      event,
      { teams: gameInfo.teams, fouls: gameInfo.fouls, goals: gameInfo.goals },
    )
    
    const loadEvents = () => {
      const board = boardsEvent[ count ]
      if (!board) return
      
      const promise = new Promise((resolve) => {
        dispatch(setActiveEventId(event.id));
        const scheme: Scheme = { objects: board?.objects, version: board?.version }
        
        const objects = manager.canvas.getObjects()
        fadeIn.fadeOut(objects).then(() => {
          manager.loadLocal({ scheme }).then(() => {
            const loadedObject = manager.canvas.getObjects()
            for (const obj of loadedObject) {
              obj.set({ opacity: 0 })
            }
            
            replaceCommon.replace({
              startOpacity: 0,
              data: {
                ...gameInfo,
                timer: millisToMinutesAndSeconds(timer),
                period,
                championshipName: gameInfo.championship?.name,
                city: gameInfo.match?.address.city,
                organizerLogo: gameInfo.organizer?.logo,
                teams: {
                  first: {
                    ...gameInfo?.teams?.first,
                    lastGamesResults: gameInfo.teams?.first.lastGamesResults.map(x => x.result),
                  },
                  second: {
                    ...gameInfo?.teams?.second,
                    lastGamesResults: gameInfo.teams?.second.lastGamesResults.map(x => x.result),
                  },
                },
              },
            })
            replaceEvent.replace({ startOpacity: 0, data: eventData })
            
            const allObject = manager.canvas.getObjects().filter(object => object.opacity === 0)
            
            fadeIn.fadeIn(allObject).then(() => {
              setTimeout(() => {
                const allObject = manager.canvas.getObjects()
                fadeIn.fadeOut(allObject).then(() => {
                  manager.clear()
                  dispatch(setActiveEventId(null));
                  resolve(true)
                })
              }, board.duration * time.second)
            })
          })
        })
      })
      
      promise.then(() => {
        count++
        if (count <= boardsEvent.length - 1) {
          loadEvents()
        } else {
          dispatch(shiftEvent())
        }
      })
    }
    
    loadEvents()
    
    // eslint-disable-next-line
  }, [manager, events, dispatch, boards])
  
  useEffect(() => {
    if (!manager) return
    if (!activeTemplate) return
    manager?.setResolution(activeTemplate.resolution)
    manager?.setDimensions(activeTemplate.resolution)
    manager.normalizedViewport()
    fadeIn.init(manager)
  }, [fadeIn, activeTemplate, manager])
  
  useEffect(() => {
    if (!manager) return
    const { goals, fouls, teams } = gameInfo
    
    replacer.updateText({ indicator: 'teamScore0', text: goals.first.toString() })
    replacer.updateText({ indicator: 'teamScore1', text: goals.second.toString() })
    replacer.updateText({ indicator: 'teamFouls0', text: fouls.first.toString() })
    replacer.updateText({ indicator: 'teamFouls1', text: fouls.second.toString() })
    
    replacer.updateText({ indicator: 'teamScore', text: goals.first.toString(), teamIndex: 0 })
    replacer.updateText({ indicator: 'teamScore', text: goals.second.toString(), teamIndex: 1 })
    replacer.updateText({ indicator: 'teamFouls', text: fouls.first.toString(), teamIndex: 0 })
    replacer.updateText({ indicator: 'teamFouls', text: fouls.second.toString(), teamIndex: 1 })
    
    const event = events[ 0 ]
    if (!event) return
    const { teamPrefix } = parseInfoEvent(event, teams)
    const { teamPrefix: reversePrefix } = parseInfoEvent(event, teams, { teamReverse: true })
    
    if (teamPrefix) {
      replacer.updateText({
        indicator: 'score',
        text: goals[ teamPrefix ].toString().toString(),
        teamReverse: false,
      })
    }
    
    if (reversePrefix) {
      replacer.updateText({
        indicator: 'score',
        text: goals[ reversePrefix ].toString().toString(),
        teamReverse: true,
      })
    }
    
    // eslint-disable-next-line
  }, [events, gameInfo.teams, gameInfo.fouls, gameInfo.goals, replacer])
  
  useEffect(() => {
    if (!manager) return
    replacer.updateText({ indicator: 'matchTime', text: millisToMinutesAndSeconds(timer) })
    replacer.updateText({ indicator: 'period', text: period.toString() })
  }, [replacer, timer, period, manager])
  
  
  return (
    <CanvasWrapper>
      {canvas}
    </CanvasWrapper>
  );
}
