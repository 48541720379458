import { TeamByMatchId, TeamDTO } from "../interfaces/TeamByMatchId";

export function teamByMatchDataMapper(teams: TeamDTO[]): TeamByMatchId[] {
  return teams.map((team) => {
    return {
      teamId: team.id,
      players: team.championshipApplications.map((application) => {
        return {
          id: application.id,
          avatar: application.user.photo?.externalUrl || 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
          firstName: application.user.name,
          lastName: application.user.surname,
          middleName: application.user.middleName,
          userId: application.user.id,
          number: application.number,
          position: application.position,
        }
      }),
    }
  })
}
