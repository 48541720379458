import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Interactive from "../../../ud-ui/components/fabric-canvas/canvases/interactive";
import { Object } from "fabric/fabric-impl";
import { Scheme } from "../../../ud-ui/components/fabric-canvas/types";
import { PreviewState } from "../../domain/interface/previewState";

export interface EditorSliceState {
  fabricManager?: Interactive
  activeObjects: Object[]
  activeModal?: string
  previewState?: PreviewState
  
  hasChanges: boolean
  savedScheme?: Scheme
  
  isInit: boolean
  isEditingText: boolean
}

const initialState: EditorSliceState = {
  activeObjects: [],
  hasChanges: false,
  isInit: false,
  isEditingText: false,
};

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setFabricManager: (state, action: PayloadAction<Interactive>) => {
      state.fabricManager = action.payload as any
    },
    setHasChanges: (state, action: PayloadAction<boolean>) => {
      state.hasChanges = action.payload
    },
    setSavedScheme: (state, action: PayloadAction<Scheme | undefined>) => {
      state.savedScheme = action.payload as any
    },
    setActiveObjects: (state, action: PayloadAction<Object[]>) => {
      state.activeObjects = action.payload as any
    },
    setPreviewState: (state, action: PayloadAction<PreviewState | undefined>) => {
      state.previewState = action.payload as any
    },
    setActiveModal: (state, action: PayloadAction<string | undefined>) => {
      state.activeModal = action.payload
    },
    setInit: (state, action: PayloadAction<boolean>) => {
      state.isInit = action.payload
    },
    setIsEditingText: (state, action: PayloadAction<boolean>) => {
      state.isEditingText = action.payload
    },
  },
});

export const {
  setFabricManager,
  setHasChanges,
  setSavedScheme,
  setActiveModal,
  setActiveObjects,
  setPreviewState,
  setInit,
  setIsEditingText,
} = editorSlice.actions

export default editorSlice.reducer
