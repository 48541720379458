import logo1 from "../../../ud-ui/components/images/placeholders/logo1.png";
import logo2 from "../../../ud-ui/components/images/placeholders/logo2.png";
import tournamentLogo from "../../../ud-ui/components/images/placeholders/tournamentLogo.png";
import { format } from "date-fns";
import { Replacer } from "../../../ud-ui/components/fabric-canvas/addons/replacer/replacer";
import ReplaceCommon from "../../../ud-ui/components/fabric-canvas/addons/replacer/replaceCommon";
import ReplaceEvent from "../../../ud-ui/components/fabric-canvas/addons/replacer/replaceEvent";
import ru from "date-fns/locale/ru";
import ReplaceCarousel from "../../../ud-ui/components/fabric-canvas/addons/replacer/replaceCarousel";

interface IReplacer {
  replacer: Replacer
}

function replaceFakeData(props: IReplacer) {
  const { replacer } = props
  const now = Date.now()
  const replaceCommon = new ReplaceCommon(replacer)
  const replaceEvent = new ReplaceEvent(replacer)
  const replaceCarousel = new ReplaceCarousel(replacer)
  
  replaceCommon.replace({
    startOpacity: 1,
    data: {
      championshipName: 'Кубок мира',
      city: 'Новосибирск',
      formatStartDate: {
        time: format(now, 'HH:mm'),
        date: format(now, 'd MMMM', { locale: ru }),
      },
      teams: {
        first: {
          name: 'Слоны',
          logo: logo1,
          lastGamesResults: ['WON', 'DRAW', 'LOST'],
          members: fakePlayersTeam0,
        },
        second: {
          name: 'Фениксы',
          logo: logo2,
          lastGamesResults: ['LOST', 'DRAW', 'WON'],
          members: fakePlayersTeam1,
        },
      },
      goals: {
        first: 2,
        second: 7,
      },
      fouls: {
        first: 3,
        second: 4,
      },
      organizerLogo: tournamentLogo,
      period: 1,
      timer: '14:22',
    },
  })
  
  replaceEvent.replace({
    startOpacity: 1,
    data: {
      players: {
        first: {
          avatar: avatar,
          lastName: 'Константинопольский',
          name: 'Константин',
          number: 15,
          qtyRedCards: 0,
          qtyYellowCards: 3,
        },
        second: {
          avatar: avatar,
          lastName: 'Михайлов',
          name: 'Михайл',
          number: 21,
          qtyRedCards: 1,
          qtyYellowCards: 2,
        },
      },
      teams: {
        team: {
          fouls: 0,
          logo: logo1,
          name: 'СЛОНЫ',
          score: 2,
        },
        reversedTeam: {
          fouls: 0,
          logo: logo2,
          name: 'ФЕНИКСЫ',
          score: 7,
        },
      },
      eventMinute: 0,
      currentPeriod: 0,
    },
  })
  
  replaceCarousel.replace({
    data: {
      name: fakePlayersTeam0[ 0 ].name,
      position: 'Вратарь',
      avatar: avatar,
      number: fakePlayersTeam0[ 0 ].number,
    },
    teamIndex: 0,
    startOpacity: 1,
  })
  
  replaceCarousel.replace({
    data: {
      name: fakePlayersTeam1[ 0 ].name,
      position: 'Вратарь',
      avatar: avatar,
      number: fakePlayersTeam1[ 0 ].number,
    },
    teamIndex: 1,
    startOpacity: 1,
  })
}

export default replaceFakeData

const avatar = 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png';

type Player = {
  photo: string
  name: string
  number: number
};

const fakePlayersTeam0: Player[] = [
  {
    photo: avatar,
    name: "Константин Константинопольский",
    number: 15,
  },
  {
    photo: '',
    name: "Михаил Михайлов",
    number: 21,
  },
  {
    photo: avatar,
    name: "Егор Егоров",
    number: 34,
  },
  {
    photo: avatar,
    name: "Иван Иванов",
    number: 45,
  },
  {
    photo: avatar,
    name: "Александр Виноградов Александрович",
    number: 77,
  },
  {
    photo: avatar,
    name: "Александр Александров",
    number: 88,
  },
  {
    photo: avatar,
    name: "Александр Александров",
    number: 99,
  },
  {
    photo: avatar,
    name: "Александр Александров",
    number: 100,
  },
  {
    photo: avatar,
    name: "Александр Александров",
    number: 101,
  },
  {
    photo: avatar,
    name: "Александр Александров",
    number: 102,
  },
  {
    photo: avatar,
    name: "Александр Александров",
    number: 103,
  },
  
  {
    photo: avatar,
    name: "Александр Александров",
    number: 104,
  },
  {
    photo: avatar,
    name: "Александр Александров",
    number: 105,
  },
  
  {
    photo: avatar,
    name: "Александр Александров",
    number: 106,
  },
  {
    photo: avatar,
    name: "Александр Александров",
    number: 107,
  },
  
  {
    photo: avatar,
    name: "Александр Александров",
    number: 108,
  },
]

const fakePlayersTeam1: Player[] = [
  {
    photo: avatar,
    name: "Криштиану Роналду",
    number: 5,
  },
  {
    photo: avatar,
    name: "Лионель Месси",
    number: 31,
  },
  {
    photo: avatar,
    name: "Роберт Левандовский",
    number: 81,
  },
  {
    photo: avatar,
    name: "Кевин де Брюйне",
    number: 91,
  },
  {
    photo: avatar,
    name: "Джанлуиджи Буффон",
    number: 99,
  },
  {
    photo: avatar,
    name: "Криштиану Роналду",
    number: 5,
  },
  {
    photo: avatar,
    name: "Лионель Месси",
    number: 31,
  },
  {
    photo: avatar,
    name: "Роберт Левандовский",
    number: 81,
  },
  {
    photo: avatar,
    name: "Кевин де Брюйне",
    number: 91,
  },
  {
    photo: avatar,
    name: "Джанлуиджи Буффон",
    number: 99,
  },
]
