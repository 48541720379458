import { Canvas, Group } from "fabric/fabric-impl";
import { fabric } from "fabric";
import { ObservableField } from "../../../../utils/observerField";
import customization from "./customization";
import SnapToGrid from "./snapToGrid";

class LineDrawer {
  canvas!: Canvas
  isDown = false;
  
  group?: Group
  line?: fabric.Line
  drawMode: ObservableField<boolean> = new ObservableField<boolean>(false);
  
  private onMouseDown = this.mouseDown.bind(this)
  private onMouseMove = this.mouseMove.bind(this)
  private onDblClick = this.dblClick.bind(this)
  private onPressKeyboard = this.hotkeyCallBack.bind(this)
  
  init(canvas: Canvas) {
    this.canvas = canvas
  }
  
  private hotkeyCallBack(e: KeyboardEvent) {
    if (e.code === 'Escape') {
      this.edit();
    }
  }
  
  activate() {
    this.canvas.on('mouse:down', this.onMouseDown);
    this.canvas.on('mouse:dblclick', this.onDblClick);
    this.canvas.on('mouse:move', this.onMouseMove)
    document.addEventListener("keydown", this.onPressKeyboard);
  }
  
  disable() {
    this.canvas.off('mouse:down', this.onMouseDown);
    this.canvas.off('mouse:dblclick', this.onDblClick);
    this.canvas.off('mouse:move', this.onMouseMove)
    document.removeEventListener("keydown", this.onPressKeyboard);
  }
  
  setSelectable(value: boolean) {
    this.canvas.forEachObject((object) => {
      if (object.data?.isSave === false) return
      object.selectable = value;
      object.setCoords()
    })
    this.canvas.selection = value;
  }
  
  mouseDown(options: fabric.IEvent<Event>) {
    if (!this.drawMode.value) return
    if (!this.group) {
      this.group = new fabric.Group([], {
        selectable: false,
        ...customization.getAllProperties(),
      });
      this.canvas.add(this.group);
    }
    
    if (this.line) {
      this.group.addWithUpdate(this.line);
      this.canvas.remove(this.line)
      this.canvas.requestRenderAll();
    }
    
    this.isDown = true
    const pointer = this.canvas.getPointer(options.e);
    const points = [
      SnapToGrid.roundByGrid(pointer.x),
      SnapToGrid.roundByGrid(pointer.y),
      SnapToGrid.roundByGrid(pointer.x),
      SnapToGrid.roundByGrid(pointer.y)
    ];
    
    this.line = new fabric.Line(points, {
      stroke: 'black',
      hoverCursor: 'default',
      selectable: false,
      ...customization.getAllProperties()
    });
    
    
    this.canvas.add(this.line);
    this.canvas.requestRenderAll();
  };
  
  mouseMove(options: fabric.IEvent<Event>) {
    if (!this.drawMode.value) return
    if (!this.isDown) return;
    if (this.line === undefined) return;
    const pointer = this.canvas.getPointer(options.e);
    this.line.set({
      x2: SnapToGrid.roundByGrid(pointer.x),
      y2: SnapToGrid.roundByGrid(pointer.y),
    });
    this.canvas.requestRenderAll();
  };
  
  dblClick() {
    if (!this.line) return
    if (this.group) {
      this.group.setObjectsCoords()
      this.group.addWithUpdate(this.line);
      this.canvas.remove(this.line)
      this.canvas.requestRenderAll();
    }
    
    this.line.setCoords()
    this.isDown = false;
    this.line = undefined;
    this.group = undefined;
  };
  
  draw() {
    this.drawMode.value = true
    this.activate()
    this.setSelectable(false);
  }
  
  edit() {
    if (this.drawMode) {
      const canvasObjects = this.canvas.getObjects();
      const sel = canvasObjects[ canvasObjects.length - 1 ]; //Get last object
      if (sel.type !== 'group') this.canvas.remove(sel);
      this.drawMode.value = false;
      this.group = undefined;
      this.line = undefined;
    }
    this.disable()
    this.setSelectable(true);
  }
}

const lineDrawer = new LineDrawer()
export default lineDrawer
