import { PostgrestSingleResponse, SupabaseClient } from "@supabase/supabase-js";
import { CreateLog, InitOverlayLog } from "../../domain/interfaces/supabase/overlay-logs/actions";

const table = 'overlay_logs'

export default class OverlayLogs {
  tournamentId?: number
  championshipId?: number
  matchId?: number
  templateId?: number
  
  constructor(private supabase: SupabaseClient) {}
  
  init({ tournamentId, championshipId, matchId, templateId }: InitOverlayLog) {
    tournamentId !== undefined && (this.tournamentId = tournamentId);
    championshipId !== undefined && (this.championshipId = championshipId);
    matchId !== undefined && (this.matchId = matchId);
    templateId !== undefined && (this.templateId = templateId);
  }
  
  private async createLog(params: CreateLog) {
    const {
      level,
      comment,
      message,
    } = params
    
    const { error } = (await this.supabase
      .from(table)
      .insert({
        tournament_id: this.tournamentId,
        championship_id: this.championshipId,
        match_id: this.matchId,
        template_id: this.templateId ?? undefined,
        level,
        comment,
        message,
      })) as PostgrestSingleResponse<CreateLog>
    
    if (error) {
      console.error(error)
      return false
    } else {
      return true
    }
  }
  
  async createError(params: Omit<CreateLog, 'level'>) {
    return this.createLog({ ...params, level: 'error' })
  }
  
  async createWarning(params: Omit<CreateLog, 'level'>) {
    return this.createLog({ ...params, level: 'warning' })
  }
  
  async createInfo(params: Omit<CreateLog, 'level' | 'comment'>) {
    return this.createLog({ ...params, level: 'info' })
  }
  
  async createDebug(params: Omit<CreateLog, 'level' | 'comment'>) {
    return this.createLog({ ...params, level: 'debug' })
  }
}
