import styled from '@emotion/styled';
import React from 'react';
import { useDispatch } from 'react-redux';
import UDIcon from '../../../../ud-ui/components/icon';
import PauseIcon from '../../../../ud-ui/components/icon/pause-icon';
import PlayIcon from '../../../../ud-ui/components/icon/play-icon';
import { millisToMinutesAndSeconds } from '../../../domain/utils/convertTime';
import useTimer from '../../hooks/useTimer';
import Block from '../components/block';
import Counter from '../components/counter';
import Text from '../components/text';
import ValueBlock from '../components/valueBlock';
import { resetTime } from "../../../store/time/actions";
import { setPeriod, toggleTimer } from "../../../store/time/time";
import useTime from "../../hooks/useTime";

export function Period() {
  const dispatch = useDispatch();
  const { period } = useTime()
  const { timer, setTimer, addToTimer, isActive } = useTimer();
  
  const onClickToggler = () => {
    dispatch(toggleTimer());
  };
  
  const onChangePeriod = (num: number) => {
    setTimer(0)
    dispatch(setPeriod(num));
  };
  
  const onClickReset = () => {
    dispatch(resetTime());
  };
  
  return <Block className={'flex-center flex-column'} headerText={'Тайм'} style={{ gap: 6 }}>
    <div className={'d-flex align-items-center'}
         style={{ width: '100%', height: 30, justifyContent: 'space-between' }}>
      <div className={'flex-center'} style={{ height: '100%', width: 70, background: '#D9B244' }}
           onClick={onClickToggler}>
        {isActive
          ? <PauseIcon width={13} height={14} color={'white'}/>
          : <PlayIcon width={14} height={14} color={'white'}/>
        }
      </div>
      <Counter value={period} setValue={onChangePeriod}/>
      <ValueBlock
        text={millisToMinutesAndSeconds(timer)}
        textSize={'large'}
        className={'flex-center'}
        style={{ width: 70, height: 30 }}
      />
    </div>
    <div className={'d-flex flex-row justify-content-between'} style={{ gap: 4, width: '100%' }}>
      <Button onClick={() => addToTimer(-10 * 1000)}><Text size={'medium'} color={'white'}>-10</Text></Button>
      <Button onClick={() => addToTimer(-5 * 1000)}><Text size={'medium'} color={'white'}>-5</Text></Button>
      <Button onClick={() => addToTimer(-1 * 1000)}><Text size={'medium'} color={'white'}>-1</Text></Button>
      <Button onClick={() => addToTimer(1000)}><Text size={'medium'} color={'white'}>+1</Text></Button>
      <Button onClick={() => addToTimer(5 * 1000)}><Text size={'medium'} color={'white'}>+5</Text></Button>
      <Button onClick={() => addToTimer(10 * 1000)}><Text size={'medium'} color={'white'}>+10</Text></Button>
      <UDIcon componentProps={{ onClick: onClickReset }} name={'cyclic-arrow'}/>
    </div>
  </Block>;
}

const Button = styled.button`
  background: #686A79;
  width: 26px;
  height: 20px;
  border: none;
`;
