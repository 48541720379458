import {
  addTournamentUserToTeam,
  deleteTournamentUser,
  fetchTournamentUser,
  fetchTournamentUsers,
  fetchTournamentUsersByFilter,
  fetchUserEvents,
  kickTournamentUserFromTeam,
  upsertTournamentUser,
} from "./actions";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { PlayersSliceState } from "./index";
import { toast } from "react-toastify";

type ReducerBuilder = ActionReducerMapBuilder<PlayersSliceState>;

export function createFetchPlayersReducer(builder: ReducerBuilder) {
  builder.addCase(fetchTournamentUsers.pending, (state) => {
    state.isLoading = true;
    state.isLoaded = false;
  });
  
  builder.addCase(fetchTournamentUsers.fulfilled, (state, action) => {
    
    const existingPlayers = state.players.map(player => player.id);
    const newPlayers = action.payload.filter(player => !existingPlayers.includes(player.id));
    
    if (state.players.length === 0) {
      state.players = action.payload;
    } else {
      state.players = [...state.players, ...newPlayers];
    }
    state.pages = Math.ceil(state.players.length / state.playersPerPage);
    if (state.currentPage === 0) {
      state.currentPage = state.pages > 0 ? 1 : 0;
    }
    state.allDataLoaded = newPlayers.length === 0 || action.payload.length < state.playerLoadedLimit;
    state.isLoading = false;
    state.isLoaded = true;
  });
  
  builder.addCase(fetchTournamentUsers.rejected, (state) => {
    state.isLoading = false;
    state.isLoaded = false;
    toast.error('Не удалось загрузить игроков');
  });
}

export function createFetchTournamentUsersByFilterReducer(builder: ReducerBuilder) {
  builder.addCase(fetchTournamentUsersByFilter.pending, (state) => {
    state.filter.isLoading = true;
    state.filter.isLoaded = false;
  });
  
  builder.addCase(fetchTournamentUsersByFilter.fulfilled, (state, action) => {
    state.filter.players = action.payload;
    state.filter.pages = Math.ceil(state.filter.players.length / state.playersPerPage);
    state.filter.currentPage = state.filter.pages > 0 ? 1 : 0;
    state.filter.allDataLoaded = state.filter.players.length < state.playerLoadedLimit;
    state.filter.isLoading = false;
    state.filter.isLoaded = true;
  });
  
  builder.addCase(fetchTournamentUsersByFilter.rejected, (state) => {
    state.filter.isLoading = false;
    state.filter.isLoaded = false;
    toast.error('Не удалось выполнить операцию')
  });
}

export function createUpsertTournamentUserReducer(builder: ReducerBuilder) {
  builder.addCase(upsertTournamentUser.pending, (state) => {
    state.isProcessed = false;
    state.isProcessing = true;
    state.processName = 'upsertTournamentUser';
  });
  
  builder.addCase(upsertTournamentUser.fulfilled, (state, action) => {
    state.isProcessing = false;
    state.isProcessed = true;
    state.processName = '';
    
    const playerIndex = state.players.findIndex(player => player.id === action.payload.id);
    if (playerIndex !== -1) {
      state.players[ playerIndex ] = action.payload;
    } else {
      state.players.push(action.payload);
      const isFilterActive = state.filter.text
      if (isFilterActive) {
        state.filter.pages = Math.ceil(state.filter.players.length / state.playersPerPage);
      } else {
        state.pages = Math.ceil(state.players.length / state.playersPerPage);
      }
    }
  });
  
  builder.addCase(upsertTournamentUser.rejected, (state) => {
    state.isProcessing = false;
    state.isProcessed = false;
    state.processName = '';
    toast.error('Не удалось выполнить операцию')
  });
}

export function createDeleteTournamentUserReducer(builder: ReducerBuilder) {
  builder.addCase(deleteTournamentUser.pending, (state) => {
    state.isProcessing = true
    state.isProcessed = false
    state.processName = 'deletingTournamentUser'
  });
  
  builder.addCase(deleteTournamentUser.fulfilled, (state, action) => {
    if (action.payload.status) {
      state.players = state.players.filter(player => player.id !== action.meta.arg.userId)
      const isFilterActive = state.filter.text
      if (isFilterActive) {
        state.filter.pages = Math.ceil(state.filter.players.length / state.playersPerPage);
        state.filter.currentPage = state.filter.currentPage > state.filter.pages ? state.filter.pages : state.filter.currentPage;
      } else {
        state.pages = Math.ceil(state.players.length / state.playersPerPage);
        state.currentPage = state.currentPage > state.pages ? state.pages : state.currentPage;
      }
    }
    
    state.isProcessing = false
    state.isProcessed = true
    state.processName = undefined
  });
  
  builder.addCase(deleteTournamentUser.rejected, (state) => {
    state.isProcessing = false
    state.isProcessed = false
    state.processName = undefined
    toast.error('Не удалось удалить игрока')
  });
}

export function createAddTournamentUserToTeamReducer(builder: ReducerBuilder) {
  builder.addCase(addTournamentUserToTeam.pending, (state) => {
    state.isProcessed = false
    state.isProcessing = true
    state.processName = 'addTournamentUserToTeam'
  });
  
  builder.addCase(addTournamentUserToTeam.fulfilled, (state, action) => {
    state.isProcessing = false
    state.isProcessed = true
    state.processName = undefined
    
    const playerIndex = state.players.findIndex(player => player.id === action.payload.id);
    if (playerIndex !== -1) {
      state.players[ playerIndex ] = action.payload;
    }
  });
  
  builder.addCase(addTournamentUserToTeam.rejected, (state) => {
    state.isProcessing = false
    state.isProcessed = false
    state.processName = undefined
    toast.error('Не удалось добавить игрока в команду')
  });
}

export function createKickTournamentUserFromTeamReducer(builder: ReducerBuilder) {
  builder.addCase(kickTournamentUserFromTeam.pending, (state) => {
    state.isProcessed = false
    state.isProcessing = true
    state.processName = 'addTournamentUserToTeam'
  });
  
  builder.addCase(kickTournamentUserFromTeam.fulfilled, (state, action) => {
    state.isProcessing = false
    state.isProcessed = true
    state.processName = undefined
    
    const playerIndex = state.players.findIndex(player => player.id === action.meta.arg.userId);
    if (playerIndex !== -1) {
      state.players[ playerIndex ] = {
        ...state.players[ playerIndex ],
        positionsInTeams: state.players[ playerIndex ].positionsInTeams.filter(position => position.team.id !== action.meta.arg.teamId),
      };
    }
  });
  
  builder.addCase(kickTournamentUserFromTeam.rejected, (state) => {
    state.isProcessing = false
    state.isProcessed = false
    state.processName = undefined
    toast.error('Не удалось исключить игрока')
  });
}

export function createFetchTournamentUserReducer(builder: ReducerBuilder) {
  builder.addCase(fetchTournamentUser.fulfilled, (state, action) => {
    const isExist = state.players.some(player => player.id === action.payload.id);
    if (!isExist) {
      state.players.push(action.payload);
    }
  });
  
  builder.addCase(fetchTournamentUser.rejected, (state) => {
    toast.error('Не удалось добавить игрока');
  });
}

export function createFetchUserEventsReducer(builder: ReducerBuilder) {
  builder.addCase(fetchUserEvents.pending, (state) => {
    state.isProcessing = true
    state.isProcessed = false
    state.processName = 'fetchUserEvents'
  });
  
  builder.addCase(fetchUserEvents.fulfilled, (state, action) => {
    state.isProcessing = false
    state.isProcessed = true
    state.processName = undefined
    
    if (state.events[ action.meta.arg.userId ]?.length === 0) {
      state.events[ action.meta.arg.userId ] = action.payload
    } else {
      const oldEvents = state.events[ action.meta.arg.userId ] ?? []
      state.events[ action.meta.arg.userId ] = [...oldEvents, ...action.payload]
    }
    
    const events = state.events[ action.meta.arg.userId ] ?? []
    state.moments.pages = Math.ceil(events.length / state.moments.perPage)
    
    if (state.moments.currentPage === 0) {
      state.moments.currentPage = state.moments.pages > 0 ? 1 : 0;
    }
    
    state.moments.allDataLoaded = action.payload.length < state.moments.perPage
  });
  
  builder.addCase(fetchUserEvents.rejected, (state) => {
    state.isProcessing = false
    state.isProcessed = false
    toast.error('Не удалось выполнить операцию')
  });
}
