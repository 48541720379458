import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function DownIndicatorIcon(props: IProps) {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg
      width={width ?? 20}
      height={height ?? 20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg' {...rest}>
      <circle cx='10' cy='10' r='9' fill={'currentColor'}/>
      <path
        d='M6.625 8.875L10 12.25L13.375 8.875'
        stroke='#0A0A0B'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
