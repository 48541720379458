import Selector from "../../../elements/selector";
import React, { useCallback } from "react";
import history from "../../../../../../ud-ui/components/fabric-canvas/addons/history";
import { selectFabricManager } from "../../../../../store/editor/selectors";
import { useSelector } from "react-redux";
import UDText from "../../../../../../ud-ui/components/text";

interface IProps {
  onChange: (playerIndex: 0 | 1) => void
  value: 0 | 1
}

function ChoosingPlayer(props: IProps) {
  const { onChange, value } = props
  const manager = useSelector(selectFabricManager)
  
  const onFocusField = useCallback(() => {
    manager?.render()
    history.saveState()
  }, [manager]);
  
  return (
    <>
      <UDText type={'caption-1'}>Игрок</UDText>
      <Selector
        onFocus={onFocusField}
        styledEachOption
        onChange={(event) => {onChange((event?.value))}}
        options={options}
        value={options.find((option) => option.value === value)}
      />
    </>
  );
}

export default ChoosingPlayer;

const options = [
  { label: 'Игрок №1', value: 0 },
  { label: 'Игрок №2', value: 1 },
]
