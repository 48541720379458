import styled from '@emotion/styled';

export const CardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 8px;
`;

export const ChampionshipTeamCardHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(26, 26, 41, 0.08), 0px 8px 16px rgba(26, 26, 41, 0.08);
  cursor: pointer;
  
  &.expanded {
    border-radius: 8px 8px 0px 0px;
    box-shadow: 0px 0px 1px rgba(26, 26, 41, 0.08), 0px 8px 16px rgba(26, 26, 41, 0.08);
    
    & .team-card-expand-btn {
      transform: rotate(180deg);
    }
  }
  
  & .team-card-expand-btn {
    cursor: pointer;
    margin-right: 14px;
    
    > img {
      vertical-align: middle;
    }
  }
  
  & .team-logo {
    flex-shrink: 0;
    object-fit: contain;
  }
  
  & .team-info {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
  }
  
  & .team-players-count {
    width: 120px;
  }
  
  & .team-actions {
    flex: 0.5;
  }
`;

export const TeamCardHeaderRow = styled.div`
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.SurfaceWhite};
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(26, 26, 41, 0.08), 0px 8px 16px rgba(26, 26, 41, 0.08);
  cursor: pointer;
  
  & .team-logo {
    flex-shrink: 0;
    object-fit: contain;
  }
  
  & .team-card {
    display: block;
    
    .team-column {
      display: flex;
      align-items: center;
    }
  }
`;

export const CardBodyContainer = styled.div`
  font-family: 'SF Pro Display', sans-serif;
  padding: 16px 32px;
  
  .team-players-list {
    display: flex;
    flex-wrap: wrap;
    
    & .team-player-item {
      flex: 0 0 340px;
      box-sizing: border-box;
      color: #171e42;
      margin-right: 10px;
    }
  }
`;

export const NoTeamsMessageContainer = styled.div`
  font-family: 'SF Pro Display', sans-serif;
  color: #8F8E99;
  text-align: center;
`;

export const TeamPlayerAvatar = styled.div`
  width: ${(props: TeamPlayerAvatarProps) => props.size ?? 32}px;
  height: ${(props: TeamPlayerAvatarProps) => props.size ?? 32}px;
  min-width: ${(props: TeamPlayerAvatarProps) => props.minSize ?? 32}px;
  min-height: ${(props: TeamPlayerAvatarProps) => props.minSize ?? 32}px;
  background: url(${(props: TeamPlayerAvatarProps) => props.url});
  border-radius: ${(props: TeamPlayerAvatarProps) => props.square ? 8 : (props.size ?? 32) / 2}px;
  border: 1px solid #EAEAEC;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const TeamPlayerContainer = styled.div`
  display: flex;
  padding: 10px 16px;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  
  .player-info {
    margin-left: 8px;
    
    .player-name {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #0A0A0B;
      margin-bottom: 4px;
    }
    
    .player-position {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #8F8E99;
    }
  }
`;

type TeamPlayerAvatarProps = {
  url: string;
  size?: number;
  square?: boolean;
  minSize?: number;
};

export const TeamPlayerForm = styled.div`
  .player-form {
    --bs-gutter-x: 48px;
  }
  
  .player-data {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 16px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: end;
    margin: 8px 0;
  }
`;
