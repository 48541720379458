import { getMappedGameInfo } from "./getMappedGameInfo";
import { getScore } from "./getScore";
import { getFouls } from "./getFouls";
import { GeneralInformation } from "../interfaces/generalInformation";

export async function getData(matchId: number): Promise<GeneralInformation> {
  const gameInfoMapped = await getMappedGameInfo(matchId)
  
  return {
    goals: await getScore(matchId),
    fouls: await getFouls(matchId),
    gameInfo: gameInfoMapped,
  }
}
