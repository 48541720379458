import React from 'react';
import { DaysWeek, Divider, MainBlock } from "./styles";
import UDText from "../../../../ud-ui/components/text";
import Days from "./elements/days";
import Header from "./elements/header";

function DataPicker() {
  return (
    <MainBlock>
      <div style={{ paddingInline: 16 }}>
        <Header/>
        <DaysWeek>
          <UDText style={{ color: 'inherit' }} type={'subhead'}>Пн</UDText>
          <UDText style={{ color: 'inherit' }} type={'subhead'}>Вт</UDText>
          <UDText style={{ color: 'inherit' }} type={'subhead'}>Ср</UDText>
          <UDText style={{ color: 'inherit' }} type={'subhead'}>Чт</UDText>
          <UDText style={{ color: 'inherit' }} type={'subhead'}>Пт</UDText>
          <UDText style={{ color: 'inherit' }} type={'subhead'}>Сб</UDText>
          <UDText style={{ color: 'inherit' }} type={'subhead'}>Вс</UDText>
        </DaysWeek>
      </div>
      <Divider/>
      <div style={{ paddingInline: 16, marginTop: 8 }}>
        <Days/>
      </div>
    </MainBlock>
  );
}

export default DataPicker;
