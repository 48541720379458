import { AccessTypes, VisibilityEnum } from "../../../../../core/domain/interfaces/supabase/overlay/auxiliary-types";
import { Values } from "../../../interface/form/template";

interface IProps {
  values: Values
}

interface Result {
  width: number
  height: number
  name: string
  visibility: VisibilityEnum
  access: AccessTypes
}

function ValueTypeValidator(props: IProps): Result {
  const { values } = props
  
  
  if (typeof values.name !== 'string') {
    throw Error('failed to create template: name is not string');
  }
  
  if (typeof values.width !== 'string') {
    throw Error('failed to create template: width is not string');
  }
  
  if (typeof values.height !== 'string') {
    throw Error('failed to create template: name is not string');
  }
  
  if (typeof values.visibility !== 'object') {
    throw Error('failed to create template: visibility is not object');
  }
  
  if (typeof values.access !== 'object') {
    throw Error('failed to create template: access is not object');
  }
  
  
  return {
    name: values.name.trim(),
    width: Number(values.width),
    height: Number(values.height),
    visibility: values.visibility.value as VisibilityEnum,
    access: values.access.value as AccessTypes,
  }
}

export default ValueTypeValidator
