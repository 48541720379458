import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import debounce from 'modules/utils/debounce';
import UDInput from 'modules/ud-form/components/input/component';
import PerPage from "../../../../ud-ui/components/per-page/per-page";

type Props = {
  className?: string;
  filters: any;
  teamsPerPage: number;
  onFilter: (changes: any) => any;
};

const MainComponentsTeamFilters = (props: Props) => {
  const { filters, onFilter, className, teamsPerPage } = props;
  const [resultsOnPage, setResultsOnPage] = useState(teamsPerPage);
  
  const handleChangeSearch = useMemo(
    () =>
      debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        onFilter({
          ...filters,
          search: e.target.value,
          page: 1,
        });
      }, 300),
    [filters, onFilter],
  );
  
  const handleChangeResultsOnPage = useCallback((value: number) => {
    debounce(() => {
      onFilter({
        ...filters,
        perPage: value,
      });
    }, 300)();
    
    setResultsOnPage(value);
  }, [filters, onFilter]);
  
  return (
    <div className={classNames('container py-6', className)}>
      <div className='row gx-2'>
        <div className='col-9'>
          <div className='row gx-2'>
            <div className='col-8'>
              <UDInput
                defaultValue={filters.search}
                placeholder='Поиск по команде'
                onChange={handleChangeSearch}
                iconProps={{
                  position: 'icon-left',
                  name: 'search',
                  size: 20,
                  componentProps: { className: 'color-SurfaceRomanSilver30' },
                }}
              />
            </div>
          </div>
        </div>
        <PerPage
          value={resultsOnPage}
          onChange={handleChangeResultsOnPage}
        />
      </div>
    </div>
  );
};

export default MainComponentsTeamFilters;
