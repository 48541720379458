import { GameResultHeader } from "../game-result-header";
import { useGameDetailsFetch } from "../../hooks/use-game-details-fetch";
import { useGameDetails } from "../../hooks/use-game-details";
import MainStreamURL from "../game-result/components/MainStreamURL";
import EventsList from "./components/events-list";
import { useDispatch, useSelector } from "react-redux";
import { gameDetailsIsSavingSelector, gameEventsChangedSelector } from "../../../store/selectors";
import UDButton from "../../../../ud-ui/components/button";
import { AppDispatch } from "../../../../../store/store";
import { useCallback, useMemo } from "react";
import { saveGameDetails } from "../../../store/actions";
import { toast } from "react-toastify";
import TimestampUrls from "../game-result/components/timestampUrls";

interface IProps {
  gameId: number
}

function MomentsPage(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  
  const { gameId } = props
  useGameDetailsFetch(gameId);
  
  const { isLoading, data } = useGameDetails();
  
  const eventsChanged = useSelector(gameEventsChangedSelector);
  const isDetailsSaving = useSelector(gameDetailsIsSavingSelector);
  
  const isSaveButtonDisabled = useMemo(() => {
    return !eventsChanged || isDetailsSaving;
  }, [eventsChanged, isDetailsSaving]);
  
  const handleSaveMomentsUrls = useCallback(() => {
    if (!data) return;
    if (!eventsChanged) return;
    if (isDetailsSaving) return;
    
    dispatch(saveGameDetails(data)).then(() => {
      toast.success('Сохранено')
    }).catch(() => {
      toast.error('Произошла ошибка')
    })
  }, [data, dispatch, eventsChanged, isDetailsSaving]);
  
  return (
    <div>
      <GameResultHeader isLoading={isLoading} game={data}/>
      <MainStreamURL isLoading={isLoading} game={data}/>
      <TimestampUrls isLoading={isLoading} game={data}/>
      <div className='mt-9'>
        <EventsList game={data} isLoading={isLoading}/>
      </div>
      
      <div className='d-flex justify-content-end p-4' style={{ marginTop: 10 }}>
        <UDButton
          variant={'primary'}
          disabled={isSaveButtonDisabled}
          loading={isDetailsSaving}
          onClick={handleSaveMomentsUrls}
        >
          Сохранить и опубликовать
        </UDButton>
      </div>
    </div>
  );
}

export default MomentsPage;
