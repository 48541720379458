import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import useBoards from "../../useBoards";
import { deleteBoard } from "../../../../store/templates/actions";
import { resetPressedState } from "../../../../store/templates/templates";

function useDeleteModal() {
  const dispatch = useDispatch();
  const { activeBoard, isProcessed, isFinished, process } = useBoards()
  
  const onSubmit = useCallback(() => {
    if (!activeBoard) return;
    dispatch(deleteBoard({ id: activeBoard.id }));
  }, [activeBoard, dispatch]);
  
  const data: IUNModalProps = useMemo(() => {
    return {
      initialValue: {},
      headerText: 'Удаление табло',
      contentText: 'Вы уверены, что хотите удалить этот табло? Это действие нельзя отменить.',
      buttonText: 'Удалить',
      onSubmit,
      isProcessed,
    }
  }, [isProcessed, onSubmit]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  useEffect(() => {
    if (!isFinished) { return; }
    if (process !== 'delete board') { return; }
    close();
    dispatch(resetPressedState());
  }, [close, dispatch, isFinished, process]);
  
  return { modal, close, open }
}

export default useDeleteModal;
