import { Group, Object as FabricObject } from "fabric/fabric-impl";

export default function getGroupObjects(group: Group): FabricObject[] {
  const objects = group.getObjects();
  return objects.reduce((previousValue: FabricObject[], object) => {
    if (object.data?.type === 'group') {
      const group = object as Group
      return [...previousValue, ...getGroupObjects(group)]
    } else {
      return [...previousValue, object]
    }
  }, []) as FabricObject[]
}
