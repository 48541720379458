/** @jsxImportSource @emotion/react */
import React, {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { IconContainer, Input, InputWrapper } from './styles';
import { UDCommonInputProps } from './types';
import UDInputNumberControls from './number-controls';
import UDColorableIcon from 'modules/ud-ui/components/icon/colorable-icon';
import { getMaskText } from '../helpers/getMaskText';
import UDTooltip from "../../tooltip";

export type UDInputProps = UDCommonInputProps & {
  mask?: string;
  formatter?: (value: string) => string;
  parser?: (value: string) => string;
  autoFocus?: boolean;
};

const UDInput = React.forwardRef(
  (props: UDInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      hasError,
      valid,
      className,
      wrapperProps,
      iconProps,
      placeholder,
      mask = '',
      formatter,
      parser,
      onChange,
      onFocus,
      onBlur,
      value,
      autoFocus,
      ...otherProps
    } = props;
    
    const showMaskInput = Boolean(mask);
    const commonInputClassNames = [
      'input',
      'text subhead',
      props.className,
      { '--error': hasError },
      iconProps?.position || (iconProps && 'icon-right'),
    ];
    
    const inputRef = useRef<HTMLInputElement>(null);
    
    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }
    }, [autoFocus])
    
    const [inputInitialValue] = useState(() => {
      const initialValue = String(value || '');
      return formatter ? formatter(initialValue) : initialValue;
    });
    
    const [inputValue, setInputValue] = useState<string>(inputInitialValue);
    const [isFocused, setIsFocused] = useState(false);
    const maskTextVisible = showMaskInput && (isFocused || !!inputValue);
    
    useImperativeHandle(ref, () => inputRef.current!, []);
    
    const inputClassName = classNames(...commonInputClassNames);
    const maskInputClassName = classNames(
      ...commonInputClassNames,
      'input-mask',
      { 'input-mask__visible': maskTextVisible },
    );
    
    useLayoutEffect(() => {
      const v = String(value || '');
      const formattedValue = formatter ? formatter(v) : v;
      
      setInputValue(formattedValue);
    }, [formatter, value]);
    
    
    // autofocus:
    useEffect(() => {
      if (inputRef.current === document.activeElement) {
        setIsFocused(true);
      }
    }, [inputRef]);
    
    useEffect(() => {
      const stringValue = String(value || '');
      const formattedValue = formatter ? formatter(stringValue) : stringValue;
      
      setInputValue(formattedValue);
      
      if (inputRef.current) {
        inputRef.current.value = String(formattedValue);
      }
    }, [formatter, value]);
    
    const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true);
      
      if (onFocus) {
        onFocus(event);
      }
    }, [onFocus]);
    
    const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(false);
      
      if (onBlur) {
        onBlur(event);
      }
    }, [onBlur]);
    
    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      const formattedValue = formatter ? formatter(value) : value;
      
      setInputValue(formattedValue);
      
      if (inputRef.current && value !== formattedValue) {
        inputRef.current.value = formattedValue;
      }
      
      if (onChange) {
        event.target.value = parser ? parser(value) : value;
        onChange(event);
      }
    }, [formatter, onChange, parser]);
    
    return (
      <InputWrapper
        {...wrapperProps}
        className={classNames(wrapperProps?.className, {
          error: hasError,
          valid,
        })}
        style={iconProps?.tooltip ? { overflow: 'visible', ...wrapperProps?.style } : wrapperProps?.style}
      >
        {showMaskInput && (
          <Input
            className={maskInputClassName}
            value={getMaskText(inputValue, mask)}
            readOnly={true}
            tabIndex={-1}
            aria-hidden={true}
          />
        )}
        
        <Input
          {...otherProps}
          defaultValue={inputInitialValue}
          placeholder={maskTextVisible ? undefined : placeholder}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={inputClassName}
          ref={inputRef}
          // style={{readOnly: maskTextVisible}}
        />
        {props.type === 'number' && !props.disabled && (
          <UDInputNumberControls inputRef={inputRef}/>
        )}
        {iconProps && (
          <IconContainer className={iconProps.position || 'icon-right'}>
            {iconProps.component}
            <UDTooltip text={iconProps.tooltip}>
              <UDColorableIcon
                name={iconProps.name}
                size={iconProps.size}
                componentProps={iconProps.componentProps}
              />
            </UDTooltip>
          </IconContainer>
        )}
      </InputWrapper>
    );
  },
);

export default UDInput;
