import { RootState } from "../../../../store/store";
import { createSelector } from "@reduxjs/toolkit";

const selectState = (state: RootState) => state.templateEditor.templates
export const selectTemplates = createSelector(selectState, (state) => state.templates)
export const selectActiveTemplate = createSelector(
  selectState,
  (state) => state.templates.concat(state.catalog ?? []).find(template => template.id === state.activeTemplateId),
)
export const selectIsLoading = createSelector(selectState, (state) => state.isLoading)
export const selectIsLoaded = createSelector(selectState, (state) => state.isLoaded)
export const selectIsProcessed = createSelector(selectState, (state) => state.isProcessed)
export const selectIsFinished = createSelector(selectState, (state) => state.isFinished)
export const selectActiveBoard = createSelector(
  [selectState, selectActiveTemplate],
  (state, activeTemplate) => {
    return activeTemplate?.boards.find(board => board.id === state.activeBoardId)
  },
)
export const selectBoards = createSelector(
  selectActiveTemplate,
  (activeTemplate) => activeTemplate?.boards ?? [],
)
export const selectCatalogTemplates = createSelector(
  [selectState],
  (state) => state.catalog,
);
export const selectCatalogTemplatesByFilter = createSelector(
  [selectState, selectCatalogTemplates],
  (state, templates) => {
    const filter = state.filterByName.toLowerCase()
    const filteredByName = filter ? templates?.filter((template) => template.name.toLocaleLowerCase()
      .includes(filter)) ?? [] : templates ?? []
    switch (state.sortByDate) {
      case 'desc':
        return filteredByName.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      case 'asc':
        return filteredByName.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      default:
        return filteredByName
    }
  },
)
export const selectFilterByName = createSelector(
  selectState,
  (state) => state.filterByName,
)
export const selectSortByDate = createSelector(
  selectState,
  (state) => state.sortByDate,
)
export const selectProcess = createSelector(
  selectState,
  (state) => state.process,
)
export const selectTournamentIdLoadedTemplate = createSelector(
  selectState,
  (state) => state.tournamentId,
)
