import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { organizersStoreSelector } from "../../store/selectors";
import { useEffect } from "react";
import { fetchOrganizers } from "../../store/actions";

export default function useOrganizers() {
  const dispatch = useDispatch<AppDispatch>()
  const result = useSelector(organizersStoreSelector)
  
  useEffect(() => {
    if (!result.isLoaded) {
      dispatch(fetchOrganizers())
    }
  }, [dispatch, result.isLoaded])
  
  return result
}
