import { commonPlaceholder } from "../../../../../../ud-ui/components/fabric-canvas/types/placeholders/createArgument";

interface IItem extends commonPlaceholder {
  listName: string
}

export const commonPlaceholderList: IItem[] = [
  {
    listName: 'Город',
    indicator: 'city',
    blockText: 'Город',
    type: 'text',
  },
  {
    listName: 'Логотип турнира',
    indicator: 'organizerLogo',
    blockText: 'Логотип турнира',
    type: 'image',
  },
  {
    listName: 'Название чемпионата',
    indicator: 'championshipName',
    blockText: 'Название чемпионата',
    type: 'text',
  },
  {
    listName: 'Время начала матча',
    indicator: 'startMatchTime',
    blockText: 'Время начала матча',
    type: 'text',
  },
  {
    listName: 'Дата начала матча',
    indicator: 'startMatchDate',
    blockText: 'Дата начала матча',
    type: 'text',
  },
  {
    listName: 'Время матча',
    indicator: 'matchTime',
    blockText: 'Время матча',
    type: 'text',
  },
  {
    listName: 'Логотип\nкоманды',
    indicator: 'teamLogo',
    blockText: 'Логотип команды',
    type: 'image',
    teamIndex: 0,
  },
  {
    listName: 'Название команды',
    indicator: 'teamName',
    blockText: 'Название команды',
    type: 'text',
    teamIndex: 0,
  },
  {
    listName: 'Счёт команды',
    indicator: 'teamScore',
    blockText: 'Счёт команды',
    type: 'text',
    teamIndex: 0,
  },
  {
    listName: 'Фолы команды',
    indicator: 'teamFouls',
    blockText: 'Фолы команды',
    type: 'text',
    teamIndex: 0,
  },
  {
    listName: 'Тайм',
    indicator: 'period',
    blockText: 'Тайм',
    type: 'text',
  },
]
