import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import AuthLayoutsMain from '../../layouts';
import { AppDispatch } from '../../../../../store/store';
import { useAuth } from '../../hooks/useAuth';
import UDButton from '../../../../ud-ui/components/button';
import { switchUserTournament } from '../../../store/actions';
import { useAuthGuard } from '../../hooks/useAuthGuard';
import SearchComponent from "../../../../players/ui/components/search-component";
import classNames from "classnames";

const AuthPagesSelectTournament = () => {
  useAuthGuard();
  
  const dispatch = useDispatch<AppDispatch>();
  const { availableTournaments } = useAuth();
  
  const handleTournamentSelected = useCallback((tournamentId: number) => () => {
    dispatch(switchUserTournament(tournamentId));
  }, [dispatch]);
  
  const [filter, setFilter] = useState('');
  const filteredTournaments = useMemo(() => {
    return availableTournaments.filter((tournament) => {
      return tournament.name.toLowerCase().includes(filter.toLowerCase());
    });
  }, [availableTournaments, filter]);
  
  return (
    <AuthLayoutsMain
      title='Выберите турнир'
      subtitle=''
      contentClassName='tournaments-list'
    >
      {availableTournaments.length > 1 && (
        <SearchComponent
          style={{
            paddingRight: '10px',
            marginBottom: '20px',
            scrollbarGutter: 'stable',
            overflow: 'auto',
          }}
          placeholder={'Название турнира'}
          value={filter}
          onChange={event => setFilter(event.target.value)}
        />
      )}
      <div
        style={{
          overflow: 'auto',
          height: 'calc(100% - 38px - 20px)',
          minHeight: '38px',
          paddingRight: '10px',
          scrollbarGutter: 'stable',
        }}
      >
        {filteredTournaments.map((tournament, index) => (
          <div
            className={classNames({ 'mt-5': index !== 0 })}
            key={tournament.id}
          >
            <UDButton
              className='w-100'
              variant='secondary'
              type='submit'
              onClick={handleTournamentSelected(tournament.id)}
            >
              {tournament.name}
            </UDButton>
          </div>
        ))}
      </div>
    </AuthLayoutsMain>
  );
};

export default AuthPagesSelectTournament;
