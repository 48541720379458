import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function Globe(props: IProps) {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 18} height={height ?? 18} viewBox="0 0 18 18" fill="none"
         xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M1 9H17M1 9C1 13.4183 4.58172 17 9 17M1 9C1 4.58172 4.58172 1 9 1M17 9C17 13.4183 13.4183 17 9 17M17 9C17 4.58172 13.4183 1 9 1M9 1C11.001 3.19068 12.1382 6.03363 12.2 9C12.1382 11.9664 11.001 14.8093 9 17M9 1C6.99898 3.19068 5.8618 6.03363 5.8 9C5.8618 11.9664 6.99898 14.8093 9 17"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
