import { Canvas, StaticCanvas } from "fabric/fabric-impl";
import { Scheme } from "../types";
import { ObservableField } from "../../../../utils/observerField";
import { fabric } from "fabric";
import customization from "../addons/customization";

export default class BaseFabric {
  canvas!: StaticCanvas | Canvas
  
  template?: Scheme
  resolution: ObservableField<{ width: number, height: number }> = new ObservableField({ width: 1920, height: 1080 })
  deletedObjects?: { background: string }
  cursorPos?: { x: number, y: number }
  
  protected init() {
    this.canvas.on('mouse:move', e => {
      this.cursorPos = e.absolutePointer
    })
    
    fabric.Object.NUM_FRACTION_DIGITS = 10;
  }
  
  dispose() {
    if (!this.canvas) return
    this.canvas.dispose()
  }
  
  clear(safeBackground = false) {
    const backgroundColor = this.canvas.backgroundColor
    this.canvas.clear()
    
    if (!safeBackground) return
    this.canvas.backgroundColor = backgroundColor
    this.canvas.requestRenderAll()
  }
  
  normalizedViewport(offset?: { x?: number, y?: number, zoom?: number }) {
    const savedViewport = this.resolution
    const zoom = Math.min(
      this.canvas.getHeight() / savedViewport.value.height * (offset?.zoom ?? 1),
      this.canvas.getWidth() / savedViewport.value.width * (offset?.zoom ?? 1),
    )
    this.canvas.setZoom(zoom * (offset?.zoom ?? 1))
    
    const viewport = this.canvas.viewportTransform
    if (!viewport) return
    viewport[ 4 ] = (this.canvas.getWidth() - (savedViewport.value.width - (offset?.x ?? 0)) * zoom) / 2
    viewport[ 5 ] = (this.canvas.getHeight() - (savedViewport.value.height - (offset?.y ?? 0)) * zoom) / 2
    this.canvas.setViewportTransform(viewport)
    this.canvas.requestRenderAll()
  }
  
  hideBackground() {
    this.deletedObjects = { background: this.canvas.backgroundColor?.toString() ?? 'transparent' }
    this.canvas.backgroundColor = 'transparent'
    this.canvas.requestRenderAll()
  }
  
  showBackground() {
    if (!this.deletedObjects) return
    this.canvas.backgroundColor = this.deletedObjects.background
    this.canvas.requestRenderAll()
  }
  
  loadLocal({ scheme, safeBackground }: { safeBackground?: boolean, scheme: Scheme | string }) {
    const oldBackground = this.canvas.backgroundColor
    return new Promise((resolve) => {
        if (!scheme) return resolve(false)
        const schemeParse: Scheme = typeof scheme === 'string' ? JSON.parse(scheme) : scheme
        fabric.util.enlivenObjects(schemeParse.objects ?? [], (objs: fabric.Object[]) => {
          this.canvas.clear()
          this.canvas.add(...objs);
          this.canvas.getObjects().forEach(obj => obj.set({ ...customization.getAllProperties() }));
          if (safeBackground) this.canvas.backgroundColor = oldBackground
          // this.canvas.requestRenderAll()
          resolve(true)
        }, 'fabric')
      },
    )
  }
  
  getScheme(hideBackgroundElems = false): Scheme {
    hideBackgroundElems && this.hideBackground()
    this.canvas.includeDefaultValues = false;
    const template = this.canvas.toDatalessJSON([
      'selectable',
      'data',
      'lockMovementX',
      'lockMovementY',
      'lockScalingX',
      'lockScalingY',
      'lockUniScaling',
      'lockRotation',
      'lockSkewingX',
      'lockSkewingY',
      'lockScalingFlip',
    ])
    hideBackgroundElems && this.showBackground()
    return template
  }
  
  setResolution(resolution: { width: number, height: number }) {
    this.resolution.value = resolution
  }
  
  setDimensions({ width, height }: { width: number, height: number }) {
    this.canvas.setDimensions({ width, height })
    this.canvas.requestRenderAll()
  }
  
  getParentSize() {
    const { parentElement } = this.canvas.getElement()
    if (!parentElement) return
    const height = parentElement.clientHeight
    const width = parentElement.clientWidth
    return { width, height }
  }
  
  setParentSize(offset?: { x?: number, y?: number }) {
    const { width, height } = this.getParentSize() ?? {}
    if (width === undefined || height === undefined) return
    this.canvas.setWidth(width + (offset?.x ?? 0))
    this.canvas.setHeight(height + (offset?.y ?? 0))
    this.canvas.requestRenderAll()
  }
  
  render() {
    this.canvas.renderAll()
  }
}
