import graphqlResource from '../../../core/graphqlResource';
import { IGameInfoDTO } from '../interfaces/gameInfo/gameInfoDTO';
import { gameInfoDataMapper } from "../mappers/gameInfoDataMapper";

export interface IGameInfoResponseDTO {
  data: {
    match: IGameInfoDTO
  }
  errors?: any
}

async function getGameInfo(matchId: number) {
  const query = `
      query getGameDetails($id: Int!) {
        match(id: $id) {
          id
          date
          tournament {
            id
            name
            images { externalUrl }
            address {
              city
              country
              region
            }
          }
          championship {
            id
            name
            settings { name value }
            championshipTable {
              rows {
                team {
                  id
                  name
                }
                lastGamesResults { result }
              }
            }
          }
          teamMatches {
            team {
              id
              name
              emblem {
                externalUrl
              }
              members {
                number
                position
                user {
                  id
                  photo { url: externalUrl }
                  name
                  surname
                  middleName
                }
              }
              championshipApplications {
                championship { id }
                state
                number
                position
                user {
                  id
                  name
                  surname
                  middleName
                }
              }
            }
          }
          participants {
            id
            match { id }
            user { id }
            isInvolved
          }
        }
      }
    `;
  
  const response = await graphqlResource.query<IGameInfoResponseDTO>(query, { id: matchId });
  
  if (response.data.errors) {
    console.error({ errors: response.data.errors })
    return null;
  }
  
  return gameInfoDataMapper(response.data.data.match);
}

export default getGameInfo;
