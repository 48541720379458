/** @jsxImportSource @emotion/react */
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { IconButton, tooltipStyles } from "./styles";
import LayersIcon from "../../../../../ud-ui/components/icon/layers-icon";
import React, { useCallback, useEffect, useState } from "react";
import InteractiveClickableIcon from "./interactiveClickableIcon";
import { useSelector } from "react-redux";
import { Object } from "fabric/fabric-impl";
import { fabric } from "fabric";
import customization from "../../../../../ud-ui/components/fabric-canvas/addons/customization";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import { selectActiveModal, selectActiveObjects, selectFabricManager } from "../../../../store/editor/selectors";

export function Copy() {
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager)
  const activeObjects = useSelector(selectActiveObjects)
  const [buffer, setBuffer] = useState<Object>()
  
  const onCopy = useCallback(() => {
    if (!fabricManager) return
    const activeObject = fabricManager.canvas.getActiveObject()
    if (!activeObject) return
    setBuffer(activeObject)
  }, [fabricManager]);
  
  const onPaste = useCallback((object: Object, position?: { x: number, y: number }) => {
      if (!fabricManager) return
      if (!object) return
      fabricManager.canvas.discardActiveObject()
      object?.clone((clonedObj: fabric.ActiveSelection) => {
        fabricManager.canvas.discardActiveObject();
        if (clonedObj === null) return;
        if (clonedObj.left === undefined || clonedObj.top === undefined) return;
        
        clonedObj.set({
          left: position?.x ?? clonedObj.left + 10,
          top: position?.y ?? clonedObj.top + 10,
          evented: true,
          ...customization.getAllProperties(),
        });
        
        if (clonedObj.type === 'activeSelection') {
          clonedObj.canvas = fabricManager.canvas;
          clonedObj.forEachObject(function (obj) {
            fabricManager.canvas.add(obj);
            obj.set({ ...customization.getAllProperties() })
          });
          clonedObj.setCoords();
        } else {
          fabricManager.canvas.add(clonedObj);
        }
        
        fabricManager.canvas.setActiveObject(clonedObj);
        fabricManager.canvas.requestRenderAll();
      }, ['data'])
    }
    , [fabricManager]);
  
  useEffect(() => {
    const ctrlC = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.code === 'KeyC') {
        onCopy()
      }
    }
    
    const ctrlV = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.code === 'KeyV') {
        if (!buffer) return
        if (!fabricManager) return
        let isFocused = (document.activeElement?.localName === 'input' )
        if (isFocused) return;
        history.disableHistory()
        const cursorPos = fabricManager?.cursorPos
        onPaste(buffer, cursorPos)
        history.saveState()
        history.activeHistory()
      }
    }
    
    document.addEventListener('keydown', ctrlC)
    document.addEventListener('keydown', ctrlV)
    return () => {
      document.removeEventListener('keydown', ctrlC)
      document.removeEventListener('keydown', ctrlV)
    }
  }, [buffer, fabricManager, onCopy, onPaste])
  
  const onClickCopy = () => {
    if (!fabricManager) return
    const activeObject = fabricManager.canvas.getActiveObject()
    if (!activeObject) return
    onPaste(activeObject)
    setBuffer(undefined)
  }
  
  
  return (
    <UDTooltip show={!activeModal} css={tooltipStyles} text={"Копировать"}>
      <IconButton onClick={onClickCopy}>
        <InteractiveClickableIcon isActive={activeObjects.length > 0} child={LayersIcon}/>
      </IconButton>
    </UDTooltip>
  );
}
