import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const MainBlock = styled.div`
  background-color: #F5D956;
  height: 76px;
  border-radius: 8px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  padding-right: 20px;
  //overflow: hidden;
`

export const buttonCss = css`
  border-color: transparent;
  outline-color: transparent !important;
  transition: background-color 0.2s ease;
  width: 180px;
  height: 36px;
`
