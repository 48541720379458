/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, disableButtonCSS, MainBlock, modalContainerCss } from "./styles";
import UDText from "../../../../../../ud-ui/components/text";
import PlanCard from "../../../../elements/planCard";
import { useModal } from "../../../../../../ud-ui/components/modal/useModal";
import { Plan } from "../../../../../domain/interfaces/plan";
import UDButton from "../../../../../../ud-ui/components/button";
import { selectActiveOrganizerPlans } from "../../../../../store/selectors";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../../store/store";
import { fetchActiveTournamentPlan, givePlan } from "../../../../../store/actions";
import { toast } from "react-toastify";

interface IProps {
  tournamentId: number
  ownerId?: number
}

function PlansBlock(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { tournamentId, ownerId } = props
  
  const [selectedPlan, setSelectedPlan] = useState<Plan>()
  const [tariff, setTariff] = useState<string>()
  const { open, close, Modal } = useModal({ defaultShow: false })
  const activePlan = useSelector(selectActiveOrganizerPlans(tournamentId))
  const [currentPlanId, setCurrentPlanId] = useState<number>()
  
  useEffect(() => {
    if (activePlan === undefined) {
      dispatch(fetchActiveTournamentPlan({ tournamentId: tournamentId }))
    } else if (activePlan === null) {
      setCurrentPlanId(0)
    } else {
      setCurrentPlanId(activePlan.id)
    }
  }, [activePlan, dispatch, tournamentId]);
  
  const closeModal = useCallback(() => {
    setTariff(undefined)
    setSelectedPlan(undefined)
    close()
  }, [close])
  
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal()
      }
    }
    
    document.addEventListener('keydown', onKeyDown)
    
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [closeModal])
  
  const handleGivePlan = useCallback(() => {
    if (ownerId === undefined) {
      toast.error('Пользователь не найден')
      return
    }
    
    const planId = selectedPlan?.id
    if (planId === undefined) {
      toast.error('Не удалось определить план')
      return
    }
    
    dispatch(givePlan({
      tournamentId: tournamentId,
      recipientId: ownerId,
      tournamentPlanId: planId,
    })).then(() => {
      dispatch(fetchActiveTournamentPlan({ tournamentId: tournamentId }))
      closeModal()
    })
  }, [closeModal, dispatch, ownerId, tournamentId, selectedPlan?.id]);
  
  return (
    <MainBlock>
      <div className={'mb-7'}>
        <UDText type={'subhead'}>Данный пользователь использует план: <span
          style={{ color: '#F5D956', fontWeight: "bold" }}
        >{plans.find(plan => plan.id === currentPlanId)?.name}</span></UDText>
      </div>
      <div className={'d-flex flex-column'} style={{ gap: 10 }}>
        {plans.map((plan, index) => {
          const planLength = plans.length
          
          const onClick = () => {
            if (plan.id < 0) return
            open()
            setSelectedPlan(plan)
          }
          
          return (
            <PlanCard
              isDisabled={plan.id < 0}
              onClick={onClick}
              key={plan.id}
              name={plan.name}
              price={plan.price}
              isActive={plan.id === currentPlanId}
              date={plan.date}
              widthPercent={100 - (planLength - (index + 1)) * 5}
            />)
        })}
      </div>
      
      <Modal
        onClose={closeModal}
        overlayProps={{
          id: 'overlay',
          onClick: (event) => {
            event.preventDefault();
            const target = event.target as HTMLElement
            if (target?.id === 'overlay') {
              closeModal()
            }
          },
        }}
        containerProps={{ css: modalContainerCss }}
        closeIconProps={{ style: { paddingInline: 22, paddingBlock: 22 }, className: 'close' }}
      >
        {selectedPlan && selectedPlan?.id === activePlan?.id ? (
          <div className={'d-flex flex-column align-items-start'}>
            <UDText type={'title-3'} style={{ color: 'white', marginBottom: 30 }}>{selectedPlan.name} план</UDText>
            <UDText type={'subhead'} style={{ color: 'white', marginBottom: 26 }}>
              План подключен и будет действовать
              до <span style={{ fontWeight: 'bold' }}>{selectedPlan.date?.to ?? "'Неизвестно'"}</span>
            </UDText>
            <UDButton style={{ width: 130 }} onClick={closeModal} variant={'primary'}>Понятно</UDButton>
          </div>
        ) : (
          <div className={'d-flex flex-column align-items-start'}>
            <UDText type={'title-3'} style={{ color: 'white', marginBottom: 30 }}>{selectedPlan?.name} план</UDText>
            <UDText type={'subhead'} style={{ color: 'white', marginBottom: 16 }}>Подключить этот план:</UDText>
            <div style={{ marginBottom: 30, display: 'flex' }}>
              <Button
                onClick={() => setTariff('month')}
                className={'flex-center'}
                style={{
                  marginRight: 16,
                  border: tariff === 'month' ? '2px solid #F5D956' : '2px solid transparent',
                }}
              >
                На 1 месяц
              </Button>
              <Button
                disabled
                onClick={() => setTariff('year')}
                className={'flex-center'}
                style={{
                  border: tariff === 'year' ? '2px solid #F5D956' : '2px solid transparent',
                  cursor: "not-allowed",
                }}
              >
                На 12 месяцев
              </Button>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <UDButton
                css={disableButtonCSS}
                disabled={!tariff}
                style={{ width: 186, height: 36 }}
                onClick={handleGivePlan}
                variant={'primary'}
              >
                Подключить
              </UDButton>
              <UDText type={'caption-1'} style={{ color: '#A8A8A8' }}>один месяц за {selectedPlan?.price} руб.</UDText>
            </div>
          </div>
        )}
      </Modal>
    </MainBlock>
  );
}

export default PlansBlock;

const plans: Plan[] = [
  {
    id: 0,
    name: 'Бесплатный',
    price: 0,
  }, {
    id: -1,
    name: 'Базовый',
    price: 1500,
  }, {
    id: -2,
    name: 'Продвинутый',
    price: 2500,
  }, {
    id: 2,
    name: 'Профессиональный',
    price: 10000,
  },
]
