import { Day as IDay } from "../interfaces/day";

interface IProps {
  interval: Date[]
}

function splitDaysOnWeeks(props: IProps) {
  const { interval } = props
  const countEmptyDays = (interval[0].getDate() - ((interval[0].getDay() + 6) % 7)) * -1 + 1;
  
  const weeks: IDay[][] = []
  const week: IDay[] = Array(countEmptyDays).fill({status: 'empty'});
  for (const date of interval) {
    week.push({ date });
    if (week.length === 7) {
      weeks.push([...week]);
      week.length = 0;
    }
  }
  if (week.length) {
    weeks.push([...week]);
  }
  const lastWeek = weeks[weeks.length - 1]
  const length = 7 - lastWeek.length
  if (length) {
    for (let i = 0; i < length; i++) {
      lastWeek.push({status: 'empty'})
    }
  }
  
  return weeks
}

export default splitDaysOnWeeks
