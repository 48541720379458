/** @jsxImportSource @emotion/react */
import { Buttons, Container, defaultCSS, UDHeaderWrapper } from "./styles";
import { Divider } from "../../../divider";
import UDText from "../../../text";

interface IProps {
  mainText?: string
  subElems?: JSX.Element[]
  buttons?: JSX.Element[]
}

function UDHeaderViewBoards(props: IProps) {
  const { mainText, buttons, subElems } = props
  
  return (
    <UDHeaderWrapper css={defaultCSS}>
      <Container>
        <UDText type={'subhead'} style={{ color: 'inherit', fontSize: 'inherit' }}>{mainText}</UDText>
        <Buttons>
          {buttons}
        </Buttons>
      </Container>
      <Divider type={'horizontal'} color={'#EAEAEC'}/>
      <Container>
        {subElems}
      </Container>
    </UDHeaderWrapper>
  );
}

export default UDHeaderViewBoards;
