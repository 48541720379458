import UDFormInput from '../../../../ud-form/components/input';
import { Formik } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createTeam } from '../../../../organizer/store/actions';
import UDButton from '../../../../ud-ui/components/button';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../../../navigation/domain/enums/pages';
import { organizerIsTeamCreatingSelector } from '../../../../organizer/store/selectors';
import { AppDispatch } from '../../../../../store/store';
import UDFormDropZone from 'modules/ud-form/components/dropzone/index';
import { tourneysCommonStoreImagesSelector } from 'modules/tourneys/store/common/selectors';
import { Team } from "../../../domain/interfaces/Team";
import { useTeamList } from "../../../../main/ui/hooks/useTeamsList";
import * as yup from "yup";

type CreateTeamFormProps = {
  tournamentId: number;
};

interface teamCreatePayload {
  name: string;
  logo?: File[] | undefined;
}

const CreateTeamForm = ({ tournamentId }: CreateTeamFormProps) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(organizerIsTeamCreatingSelector);
  const { teams } = useTeamList();
  const existTeams = useMemo(() => {
    return teams?.map((team) => team.name) ?? [];
  }, [teams])
  
  const handleCancelClick = useCallback(() => {
    navigate(Pages.TEAMS.INDEX, { replace: true });
  }, [navigate]);
  
  const handleSubmit = useCallback(
    (values) => {
      dispatch(createTeam(values)).then((data) => {
        navigate(Pages.TEAMS.builders.edit((data?.payload as Team)?.id))
      });
    },
    [dispatch, navigate],
  );
  
  const initialValues = {
    tournamentId,
    name: '',
  };
  
  const images = useSelector(tourneysCommonStoreImagesSelector);
  
  const teamCreateValidator: yup.SchemaOf<teamCreatePayload> = yup
    .object()
    .shape({
      name: yup.string().notOneOf(existTeams, 'Команду уже существует').required('Обязательное поле'),
      logo: yup.array().optional(),
    });
  
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={teamCreateValidator}>
      {(formProps) => (
        <form
          className='d-flex flex-column justify-content-between h-100'
          onSubmit={formProps.handleSubmit}
        >
          <input
            type='hidden'
            name='tournamentId'
            value={formProps.initialValues.tournamentId}
          />
          
          <div className='row align-items-start gx-4'>
            <UDFormInput
              name='name'
              label='Название команды'
              placeholder='Введите название'
              containerProps={{ className: 'col-6' }}
            />
            
            <UDFormDropZone
              name='logo'
              label='Логотип команды'
              value={images}
              isCover={true}
              containerProps={{ className: 'col-6' }}
              formProps={{ className: 'bgc-SurfaceWhite br-8 p-6' }}
            />
          </div>
          
          <div className='d-flex justify-content-end mb-2'>
            <UDButton
              type='button'
              variant='secondary'
              disabled={isLoading}
              onClick={handleCancelClick}
              className='mr-4'
            >
              Отменить
            </UDButton>
            
            <UDButton
              type='submit'
              variant='primary'
              disabled={isLoading}
              loading={isLoading}
            >
              Сохранить
            </UDButton>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default CreateTeamForm;
