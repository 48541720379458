import React, { useEffect, useState } from 'react';
import Checkbox from "../../elements/checkbox";
import { Grid, Item, LongDivider, ShortDivider } from "./styles";
import { useSelector } from "react-redux";
import Input from "../../elements/input";
import ColorPicker from "../../elements/colorPicker";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import backgroundElems from "../../../../../ud-ui/components/fabric-canvas/addons/backgroundElems";
import { selectActiveObjects, selectFabricManager } from "../../../../store/editor/selectors";

function Canvas() {
  const activeObjects = useSelector(selectActiveObjects)
  const fabricManager = useSelector(selectFabricManager)
  
  const canvasColor = fabricManager?.canvas.backgroundColor?.toString() ?? '#FFFFFF'
  
  // canvas
  const [backgroundColor, setBackgroundColor] = useState<string>(canvasColor)
  const [resolution, setResolution] = useState<{
    width: number,
    height: number
  }>(fabricManager?.resolution.value ?? { width: 1920, height: 1080 })
  const [showGrid, setShowGrid] = useState<boolean>(true)
  const [gridStep, setGridStep] = useState<string>('10')
  
  const onChangeShowGrid = () => {
    if (!fabricManager?.canvas) return
    if (!showGrid) {
      const transformGridStep = parseInt(gridStep)
      backgroundElems.drawGrid(fabricManager?.canvas, {
        resolution,
        gridStep: isNaN(transformGridStep) || transformGridStep < 1 ? 1 : transformGridStep
      })
    } else {
      backgroundElems.hideGrid(fabricManager?.canvas)
    }
    setShowGrid(!showGrid)
    fabricManager?.canvas.requestRenderAll()
  }
  
  const onChangeGridStep = (value: string) => {
    setGridStep(value)
  }
  
  const onSubmitGridStep = (value: string) => {
    const transformGridStep = isNaN(parseInt(value)) || parseInt(value) < 1 ? 1 : parseInt(value)
    if (!fabricManager?.canvas) return
    SnapToGrid.setGridStep(transformGridStep)
    backgroundElems.drawGrid(fabricManager.canvas, { resolution, gridStep: transformGridStep })
    fabricManager?.canvas.requestRenderAll()
  }
  
  const onChangeColor = (color: string) => {
    fabricManager?.canvas.setBackgroundColor(color, fabricManager?.canvas.renderAll.bind(fabricManager?.canvas))
    setBackgroundColor(color)
  }
  
  useEffect(() => {
    setBackgroundColor(fabricManager?.canvas.backgroundColor?.toString() ?? '#FFFFFF')
    setResolution(fabricManager?.resolution.value ?? { width: 1920, height: 1080 })
    setShowGrid(backgroundElems.isGridShow)
    setGridStep((SnapToGrid.gridStep ?? 10).toString())
  }, [activeObjects, fabricManager?.canvas.backgroundColor, fabricManager?.resolution])
  
  return (
    <>
      <Item>
        Канвас
      </Item>
      <ShortDivider/>
      <Item className={'d-flex flex-column'} style={{ gap: 8 }}>
        <Grid className={'d-flex'} style={{ gap: 8 }}>
          <div className={'d-flex flex-center'} style={{ gap: 8 }}>
            <Checkbox checked={showGrid} onChange={onChangeShowGrid}/>
            Сетка
          </div>
          <Input
            unit={'px'}
            value={gridStep.toString()}
            onSubmit={(value) => {onSubmitGridStep(value)}}
            onBlur={(value) => {onSubmitGridStep(value)}}
            onChange={(value) => {onChangeGridStep(value)}}
          />
        </Grid>
      </Item>
      <LongDivider/>
      <Item style={{ gap: 8 }}>
        Фон
        <ColorPicker color={backgroundColor} onChange={onChangeColor}/>
      </Item>
    </>
  );
}

export default Canvas;
