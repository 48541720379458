import MomentCard from "./moment-card";
import { Player } from "../../domain/interfaces/player";
import {
  selectMomentsPaginationData,
  selectPlayerEvents,
  selectPlayerEventsOnPage,
  selectProcess,
} from "../../store/selectors";
import { useDispatch, useSelector } from "react-redux";
import UDText from "../../../ud-ui/components/text";
import notFound from '../../../ud-ui/components/images/notFound.png'
import React, { useCallback, useEffect } from "react";
import { AppDispatch } from "../../../../store/store";
import { fetchUserEvents } from "../../store/actions";
import { MomentsWrapper } from "./styles";
import { setMomentsCurrentPage, setMomentsPerPage } from "../../store";
import UDPagination from "../../../ud-ui/components/pagination";
import PerPage from "../../../ud-ui/components/per-page/per-page";

interface IProps {
  player: Player
}

function PlayerMomentsPage(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  
  const { player } = props
  const eventsOnPage = useSelector(selectPlayerEventsOnPage(player.id))
  const allEventsCount = useSelector(selectPlayerEvents(player.id)).length
  const { name: processName, isProcessing } = useSelector(selectProcess)
  const { pages, currentPage, limit, allDataLoaded, perPage } = useSelector(selectMomentsPaginationData)
  
  useEffect(() => {
    if (eventsOnPage.length === 0) {
      dispatch(fetchUserEvents({ userId: player.id, limit, offset: 0 }))
    }
  }, [eventsOnPage.length, dispatch, limit, player.id]);
  
  const onPageChange = useCallback((page: number) => {
    dispatch(setMomentsCurrentPage({ currentPage: page }))
    
    if (allDataLoaded) return
    if (page === pages) {
      dispatch(fetchUserEvents({ userId: player.id, limit, offset: allEventsCount }))
    }
  }, [allDataLoaded, allEventsCount, dispatch, limit, pages, player.id]);
  
  const onPerPageChange = useCallback((perPage: number) => {
    dispatch(setMomentsPerPage({ perPage }))
  }, [dispatch]);
  
  if (isProcessing && processName === 'fetchUserEvents' && eventsOnPage.length === 0) {
    return (
      <MomentsWrapper className={'my-8'}>
        {Array(12).fill(0).map((_, index) => (
          <div key={index} className={'skeleton-block'} style={{ width: 280, height: 157.5, borderRadius: 8 }}/>
        ))}
      </MomentsWrapper>
    );
  }
  
  if (eventsOnPage.length === 0) {
    return (
      <div className={'w-100 flex-column justify-content-center align-items-center'}>
        <img src={notFound} alt={'empty'} style={{ maxHeight: '300px' }}/>
        <UDText type={'headline'}>Событий нет</UDText>
      </div>
    )
  }
  
  return (
    <div>
      <div className={'d-flex justify-content-end'}>
        <PerPage
          value={perPage}
          onChange={onPerPageChange}
        />
      </div>
      <MomentsWrapper className={'my-8'}>
        {eventsOnPage.map((event) => (
          <MomentCard key={event.id} player={player} event={event}/>
        ))}
      </MomentsWrapper>
      <UDPagination
        className='justify-content-end px-6 my-8'
        pages={pages}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
}

export default PlayerMomentsPage;
