import { css, Theme } from "@emotion/react";

export const ButtonStyles = (theme: Theme) => css`
  color: #8F8E99;
  
  transition: color 0.2s ease-in-out;
  cursor: pointer;
  
  &:hover {
    color: ${theme.colors.AccentNapplesYellow};
  }
`
