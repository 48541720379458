import styled from "@emotion/styled";
import StyledSelect from "../../styled-select";

export const HeadBlock = styled.div`
  width: 100%;
  gap: 0;
  border-radius: 8px;
  position: relative;

  padding: 20px;
  display: flex;
  flex-direction: column;

  background: #FFFFFF;
  box-shadow: 0 0 20px 0 #0000001A;

  z-index: 10;
`

export const BodyBlock = styled.div`
  top: -8px;
  position: relative;
  padding-block: 28px 8px;
  border-radius: 0 0 8px 8px;

  background: #FFFFFF;

  z-index: 5;
`

export const TitleField = styled.div<{ width: number }>`
  gap: 8px;
  display: flex;
  flex-direction: column;
  
  width: ${({ width }) => width}px;
`

export const DecisionSelect = styled(StyledSelect)`
  margin-left: auto;
`
