import { Organizer } from "../../../domain/interfaces/Organizer";
import UDButton from "../../../../ud-ui/components/button";
import useModal from "../../../../ud-ui/components/new-modal/useModal";
import UDInput from "../../../../ud-form/components/input/component";
import { useCallback, useState } from "react";
import UDText from "../../../../ud-ui/components/text";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { topupBalance } from "../../../store/actions";
import { toast } from "react-toastify";

interface IProps {
  organizer: Organizer | undefined
}

function Balance(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { organizer } = props
  const { open, close, Modal } = useModal()
  const [amount, setAmount] = useState<number>(0)
  
  
  const handleOpen = useCallback(() => {
    setAmount(0)
    
    open({
      modalStyle: {
        padding: 0,
        zIndex: 100,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: 'transparent',
        top: 0,
        left: 0,
        borderRadius: 0,
        borderColor: 'transparent',
        overflow: 'clip',
        background: '#00000085',
      },
    })
  }, [open]);
  
  const handleTopup = useCallback(() => {
    if (!organizer?.adminUser?.id) {
      toast.error('Не определить пользователя')
      console.error({ message: "Can't identify user", adminUser: organizer?.adminUser, organizer })
      return
    }
    
    dispatch(topupBalance({ amount, recipientId: organizer?.adminUser?.id })).then(close)
  }, [amount, close, dispatch, organizer]);
  
  return (
    <>
      <UDButton
        style={{ height: 36 }}
        disabled={!organizer}
        onClick={handleOpen}
        type={'button'}
        variant={'primary'}
      >
        Баланс
      </UDButton>
      
      <Modal>
        <div className={'d-flex flex-column w-100'} style={{ gap: 30 }}>
          <UDText className={'mb-2'} type={'headline'}>Пополнить баланс</UDText>
          <div className={'d-flex align-items-center w-100'} style={{ gap: 20 }}>
            <UDText type={'subhead'} style={{ textWrap: 'nowrap' }}>Сумма в рублях</UDText>
            <UDInput
              autoFocus
              style={{ backgroundColor: '#F2F2F2' }}
              wrapperProps={{ className: classNames('w-100') }}
              placeholder={'Введите сумму'}
              value={amount}
              onChange={event => setAmount(Number(event.target.value))}
            />
          </div>
          <div className={'d-flex align-items-center'} style={{ gap: 20 }}>
            <UDButton onClick={close} variant={'secondary'}>Отменить</UDButton>
            <UDButton onClick={handleTopup} variant={'primary'}>Пополнить</UDButton>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Balance;
