import React, { useEffect, useMemo, useRef } from "react";
import useFabric from "../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import useTemplates from "../../../../template-editor/ui/hooks/useTemplates";
import { millisToMinutesAndSeconds } from "../../../domain/utils/convertTime";
import { useDispatch, useSelector } from "react-redux";
import useBoards from "../../../../template-editor/ui/hooks/useBoards";
import useTimer from "../../hooks/useTimer";
import { CanvasWrapper } from "./styles";
import { selectActiveBoard } from "../../../../template-editor/store/templates/selectors";
import useGameInfo from "../../hooks/useGameInfo";
import Fade from "../../../../ud-ui/components/fabric-canvas/addons/fadeIn";
import useTime from "../../hooks/useTime";
import ReplaceCommon from "../../../../ud-ui/components/fabric-canvas/addons/replacer/replaceCommon";
import ReplaceEvent from "../../../../ud-ui/components/fabric-canvas/addons/replacer/replaceEvent";
import ReplaceCarousel from "../../../../ud-ui/components/fabric-canvas/addons/replacer/replaceCarousel";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../../../../store/store";
import { setActiveBoard } from "../../../../template-editor/store/templates/templates";

export function CanvasStaticBoards() {
  const dispatch = useDispatch<AppDispatch>()
  const { canvas, manager } = useFabric('static', { id: "boards" })
  const replacer = useReplacer(manager)
  const { activeTemplate } = useTemplates()
  const { boards, activeScheme } = useBoards()
  const activeBoard = useSelector(selectActiveBoard)
  const { timer } = useTimer()
  const { period } = useTime()
  const gameInfo = useGameInfo()
  const fade = useMemo(() => {
    return new Fade()
  }, []);
  const replaceCommon = useMemo(() => {
    return new ReplaceCommon(replacer, fade)
  }, [fade, replacer]);
  const replaceEvent = useMemo(() => {
    return new ReplaceEvent(replacer, fade)
  }, [fade, replacer])
  const replaceCarousel = useMemo(() => {
    return new ReplaceCarousel(replacer, fade)
  }, [fade, replacer]);
  const { t } = useTranslation()
  const timeout = useRef<ReturnType<typeof setInterval> | null>(null);
  
  useEffect(() => {
    if (!manager) return
    timeout.current && clearInterval(timeout.current)
    
    const curActiveOverlay = activeBoard
    const allObject = manager.canvas.getObjects()
    
    fade.fadeOut(allObject).then(() => {
      if (curActiveOverlay?.id !== activeBoard?.id) return
      if (!activeBoard) {
        manager.clear()
        return
      }
      
      if (!boards.length) return
      const scheme = activeScheme
      if (!scheme) return;
      
      const onLoadCommon = () => {
        const loadedObject = manager.canvas.getObjects()
        for (const obj of loadedObject) {
          obj.set({ opacity: 0 })
        }
        
        replaceCommon.replace({
          startOpacity: 0,
          data: {
            ...gameInfo,
            timer: millisToMinutesAndSeconds(timer),
            period,
            championshipName: gameInfo.championship?.name,
            city: gameInfo.match?.address.city,
            organizerLogo: gameInfo.organizer?.logo,
            teams: {
              first: {
                ...gameInfo?.teams?.first,
                lastGamesResults: gameInfo.teams?.first.lastGamesResults.map(x => x.result),
              },
              second: {
                ...gameInfo?.teams?.second,
                lastGamesResults: gameInfo.teams?.second.lastGamesResults.map(x => x.result),
              },
            },
          },
        })
        
        replaceEvent.replace({ startOpacity: 0 })
        
        const allObject = manager.canvas.getObjects().filter(object => object.opacity === 0)
        for (const obj of allObject) {
          fade.fadeIn([obj])
        }
      }
      
      const onLoadCarousel = () => {
        onLoadCommon()
        
        const positions = ['goalkeeper', 'defender', 'midfielder', 'forward'];
        const firstTeamPosition = _(gameInfo.teams?.first.members)
          .filter({ isInvolved: true })
          .groupBy('position')
          .value()
        const secondTeamPosition = _(gameInfo.teams?.second.members)
          .filter({ isInvolved: true })
          .groupBy('position')
          .value()
        
        let positionIndex = 0
        let curIndex = 0
        let length = Math.max(
          firstTeamPosition[ positions[ positionIndex ] ]?.length ?? 0,
          secondTeamPosition[ positions[ positionIndex ] ]?.length ?? 0,
        )
        
        const loadPlayer = (props: { position: string, index: number }) => {
          const { position, index: i } = props
          const first = firstTeamPosition[ position ]?.[ i ] ?? null
          const firstPlayerPosition = first?.position && t(`player.position.${first?.position}`)
          replaceCarousel.replace({
            teamIndex: 0,
            startOpacity: 0,
            data: {
              avatar: first?.photo,
              name: `${first?.name ?? ''} ${first?.surname ?? ''}`,
              number: first?.number,
              position: firstPlayerPosition &&
                firstPlayerPosition?.charAt(0).toUpperCase() + firstPlayerPosition?.slice(1),
            },
          })
          
          const second = secondTeamPosition[ position ]?.[ i ] ?? null
          const secondPlayerPosition = second?.position && t(`player.position.${second?.position}`)
          replaceCarousel.replace({
            teamIndex: 1,
            startOpacity: 0,
            data: {
              avatar: second?.photo,
              name: `${second?.name ?? ''} ${second?.surname ?? ''}`,
              number: second?.number,
              position: secondPlayerPosition &&
                secondPlayerPosition?.charAt(0).toUpperCase() + secondPlayerPosition?.slice(1),
            },
          })
          
          const isSkip = !first && !second
          
          const allObject = manager.canvas.getObjects().filter(object => object.opacity === 0)
          for (const obj of allObject) {
            fade.fadeIn([obj])
          }
          
          
          const nextPlayers = () => {
            const allObjects = manager.canvas.getObjects()
            const carouselObjects = allObjects?.filter(object => (object?.data?.indicator)?.includes('carousel') || object?.data?.delete)
            if (carouselObjects?.length === 0) {
              return
            }
            
            curIndex += 1
            if (curIndex >= length) {
              positionIndex += 1
              if (positionIndex >= positions.length) {
                fade.fadeOut(allObjects).then(() => {
                  dispatch(setActiveBoard({ id: undefined }))
                })
                return
              }
              
              curIndex = 0
              length = Math.max(
                firstTeamPosition[ positions[ positionIndex ] ]?.length ?? 0,
                secondTeamPosition[ positions[ positionIndex ] ]?.length ?? 0,
              )
            }
            
            fade.fadeOut(carouselObjects, undefined, false).then(() => {
              for (const obj of carouselObjects) {
                if (obj?.data?.delete) {
                  manager.canvas.remove(obj)
                }
              }
              
              loadPlayer({ position: positions[ positionIndex ], index: curIndex })
            })
          }
          
          if (isSkip) {
            nextPlayers()
          } else {
            const promise = new Promise(resolve => {
              timeout.current = setTimeout(resolve, activeBoard.duration * 1000)
            })
            promise.then(nextPlayers)
          }
        }
        
        loadPlayer({ position: positions[ positionIndex ], index: curIndex })
      }
      
      switch (activeBoard.type) {
        case 'static':
          manager.loadLocal({ scheme }).then(onLoadCommon)
          break;
        case 'carousel':
          manager.loadLocal({ scheme }).then(onLoadCarousel)
          break;
      }
    })
    
    // eslint-disable-next-line
  }, [activeBoard, manager, replacer])
  
  useEffect(() => {
    if (!manager) return
    if (!activeTemplate) return
    manager?.setResolution(activeTemplate.resolution)
    manager?.setDimensions(activeTemplate.resolution)
    manager.normalizedViewport()
    fade.init(manager)
  }, [activeTemplate, fade, manager])
  
  useEffect(() => {
    if (!manager) return
    const { goals, fouls } = gameInfo
    
    replacer.updateText({ indicator: 'teamScore0', text: goals.first.toString() })
    replacer.updateText({ indicator: 'teamScore1', text: goals.second.toString() })
    replacer.updateText({ indicator: 'teamFouls0', text: fouls.first.toString() })
    replacer.updateText({ indicator: 'teamFouls1', text: fouls.second.toString() })
    
    replacer.updateText({ indicator: 'teamScore', text: goals.first.toString(), teamIndex: 0 })
    replacer.updateText({ indicator: 'teamScore', text: goals.second.toString(), teamIndex: 1 })
    replacer.updateText({ indicator: 'teamFouls', text: fouls.first.toString(), teamIndex: 0 })
    replacer.updateText({ indicator: 'teamFouls', text: fouls.second.toString(), teamIndex: 1 })
    
    // eslint-disable-next-line
  }, [gameInfo.fouls, gameInfo.goals, replacer])
  
  useEffect(() => {
    if (!manager) return
    replacer.updateText({ indicator: 'matchTime', text: millisToMinutesAndSeconds(timer) })
    replacer.updateText({ indicator: 'period', text: period.toString() })
  }, [replacer, timer, period, manager])
  
  return (
    <CanvasWrapper>
      {canvas}
    </CanvasWrapper>
  );
}
