import React, { useCallback, useEffect, useState } from 'react';
import styled from "@emotion/styled";
import { Chrome, ColorResult } from "@uiw/react-color";
import { hexToRgba, rgbaToHexa, validHex } from "@uiw/color-convert";
import { round } from "../../../domain/utils/helpers";
import { TransparentInput } from "./styles";
import { css } from "@emotion/react";

interface IProps {
  color: string
  onChange: (color: string) => void
  showAlpha?: boolean
}

function ColorPicker(props: IProps) {
  const { color, onChange, showAlpha } = props
  
  const [showColorPicker, setShowColorPicker] = useState(false);
  
  const closeColorPicker = useCallback(() => {
    setShowColorPicker(false)
  }, []);
  
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeColorPicker();
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeColorPicker]);
  
  if (!color) return <></>
  const rgba = hexToRgba(color)
  const hexa = color[0] === '#' ? color : '#' + color
  
  
  const onClickSelectColor = () => {
    setShowColorPicker(!showColorPicker)
  }
  
  const onChangeColor = (color: ColorResult) => {
    onChange?.(color.hexa.toUpperCase())
  }
  
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const color = e.target.value
    if (!color.length) return
    const hexa = '#' + e.target.value.toUpperCase();
    if (color[ 0 ] !== '#') {
      if (!validHex(hexa)) return
      onChange?.(hexa)
    } else {
      if (!validHex(e.target.value)) return
      onChange?.(e.target.value.toUpperCase())
    }
  }
  
  return (
    <MainBlock>
      {showColorPicker && <div
        onClick={closeColorPicker}
        style={{
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          top: 0,
          left: 0,
        }}
      />}
      <ViewBlock>
        <Wrapper style={{ width: '100%' }}>
          <SelectedColor onClick={onClickSelectColor} color={rgbaToHexa(rgba)}/>
          <TransparentInput
            onChange={onChangeInput}
            value={hexa}
            wrapperProps={{ css: css`background-color: transparent` }}
          />
        </Wrapper>
        {showAlpha && (
          <>
            <Alpha style={{ width: 64 }}>
              <span style={{ color: '#242424', fontSize: 11 }}>{round(rgba.a * 100)}</span>
              %
            </Alpha>
          </>
        )}
      </ViewBlock>
      <Chrome
        // @ts-ignore
        inputType={'hexa'}
        color={hexa}
        onChange={onChangeColor}
        style={{ display: showColorPicker ? 'block' : 'none', width: "unset" }}
      />
    </MainBlock>
  );
}

export default ColorPicker;

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px
`

const Wrapper = styled.div`
  height: 32px;
  border-radius: 8px;
  background: #F2F3F4;
  padding: 8px;
  
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SelectedColor = styled.div<{ color: string }>`
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  border-radius: 4px;
  border: 1px solid #E2E3E4;
  background: ${props => props.color};
  cursor: pointer;
`

const ViewBlock = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: row;
`

const Alpha = styled(Wrapper)`
  font-family: Aktiv Grotesk Trial, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  color: #929292;
  
  display: flex;
  justify-content: space-between;
`
