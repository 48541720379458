/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupIcon from "../../../../../ud-ui/components/icon/group-icon";
import { selectActiveModal, selectActiveObjects, selectFabricManager } from "../../../../store/editor/selectors";
import { IconButton, tooltipStyles } from "./styles";
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { ActiveSelection } from "fabric/fabric-impl";
import customization from "../../../../../ud-ui/components/fabric-canvas/addons/customization";
import { AppDispatch } from "../../../../../../store/store";
import { setActiveObjects } from "../../../../store/editor/editor";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";

export function Group() {
  const dispatch = useDispatch<AppDispatch>()
  const activeModal = useSelector(selectActiveModal);
  const manager = useSelector(selectFabricManager)
  const activeObjects = useSelector(selectActiveObjects)
  const isGroupedObjects = useMemo(() => {
    if (!manager) return false
    return activeObjects.length === 1 && activeObjects[ 0 ].data?.type === 'group'
  }, [activeObjects, manager]);
  
  const toGroup = useCallback(() => {
    if (!manager) return
    const activeObject = manager.canvas.getActiveObject();
    if (!activeObject) return
    if (activeObject?.type !== 'activeSelection') return
    
    const group = (activeObject as ActiveSelection).toGroup();
    group.data = { ...group.data, type: 'group' };
    group.set({ ...customization.getAllProperties() });
    manager.canvas.requestRenderAll();
    dispatch(setActiveObjects([group]))
  }, [dispatch, manager]);
  
  const toUngroup = useCallback(() => {
    if (!manager) return
    const activeObject = manager.canvas.getActiveObject();
    if (!activeObject) return
    
    const activeSelection = (activeObject as ActiveSelection).toActiveSelection();
    const objects = activeSelection.getObjects();
    objects.forEach(obj => obj.set({ ...customization.getAllProperties() }));
    manager.canvas.requestRenderAll();
    dispatch(setActiveObjects(objects))
  }, [dispatch, manager]);
  
  const onClick = useCallback(() => {
    if (!manager) return
    history.saveState()
    history.disableHistory()
    if (isGroupedObjects) {
      toUngroup()
    } else if (activeObjects.length > 1) {
      toGroup()
    }
    history.activeHistory()
  }, [activeObjects.length, isGroupedObjects, manager, toGroup, toUngroup]);
  
  return (
    <UDTooltip show={!activeModal} css={tooltipStyles} text={isGroupedObjects ? "Разгруппировать" : "Группировать"}>
      <IconButton onClick={onClick} disabled={activeObjects.length < 2 && !isGroupedObjects}>
        <GroupIcon/>
      </IconButton>
    </UDTooltip>
  );
}
