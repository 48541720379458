import { Common } from "./common";
import Interactive from "../../canvases/interactive";
import { Complex } from "./complex";
import { Event } from "./event";
import { Carousel } from "./carousel";

export class Placeholders {
  public common: Common;
  public complex: Complex;
  public event: Event;
  public carousel: Carousel;
  
  constructor(manager: Interactive) {
    this.common = new Common({ manager })
    this.complex = new Complex({ manager })
    this.event = new Event({ manager })
    this.carousel = new Carousel({ manager })
  }
}
