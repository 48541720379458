import { useEffect, useMemo, useState } from "react";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";

function useSuccessCopyModal() {
  const dispatch = useDispatch<AppDispatch>()
  const [isClosed, setIsClosed] = useState(false)
  
  const data: IUNModalProps = useMemo(() => {
    return {
      onSubmit: () => setIsClosed(true),
      headerText: 'Копия успешно создана!',
      contentText: 'Мы добавили копию в список шаблонов',
      validator: () => ({}),
      initialValue: {},
      buttonText: 'Понятно',
    }
  }, []);
  
  const { modal, close, open } = useUniversalModal(data)
  
  useEffect(() => {
    if (isClosed) {
      close()
    }
  }, [close, dispatch, isClosed]);
  
  return { modal, close, open }
}

export default useSuccessCopyModal;
