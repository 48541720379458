import { Resource } from "../../../core/baseResource";
import httpResource from "../../../core/httpResource";
import { GivePlan, TopupBalance } from "../interfaces/actions";
import { ErrorGivePlan, ResponseGivePlan, ResponseTopupBalance } from "../interfaces/response";

class Plans extends Resource {
  constructor() {
    super('', httpResource);
  }
  
  public topupBalance(data: TopupBalance): Promise<ResponseTopupBalance> {
    return this
      .child('topup')
      .post({ userId: data.recipientId, amountInMinorUnits: data.amount * 100 });
  }
  
  public givePlan(data: GivePlan): Promise<ErrorGivePlan | ResponseGivePlan> {
    return this
      .child('give-tournament-plan')
      .post({ userId: data.recipientId, tournamentId: data.tournamentId, tournamentPlanId: data.tournamentPlanId });
  }
}

const plans = new Plans();
export default plans;
