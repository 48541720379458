import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
  color?: string;
}

export default function Viewers(props: IProps) {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg width={width ?? 12} height={height ?? 13} viewBox="0 0 12 13" fill="none"
         xmlns="http://www.w3.org/2000/svg"  {...rest}>
      <path
        d="M11 12C11 11.1472 11 10.7207 10.8924 10.3737C10.65 9.5925 10.0247 8.98113 9.22571 8.74414C8.87084 8.63889 8.43473 8.63889 7.5625 8.63889H4.4375C3.56527 8.63889 3.12916 8.63889 2.77429 8.74414C1.97528 8.98113 1.35003 9.5925 1.10765 10.3737C1 10.7207 1 11.1472 1 12M8.8125 3.75C8.8125 5.26878 7.5533 6.5 6 6.5C4.4467 6.5 3.1875 5.26878 3.1875 3.75C3.1875 2.23122 4.4467 1 6 1C7.5533 1 8.8125 2.23122 8.8125 3.75Z"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
