import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function FilterIcon(props: IProps) {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        d='M2.95582 5.4332H11.0447M0.933594 1.5332H13.0669M4.97804 9.3332H9.02248'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
