import React from 'react';

interface IProps extends React.SVGProps<SVGSVGElement> {
  width?: number;
  height?: number;
}

export default function NewClipboardIcon(props: IProps) {
  const {
    width,
    height,
    ...rest
  } = props;
  
  return (
    <svg
      width={width ?? 24}
      height={height ?? 24}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg' {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.57906 6.39629C6.56306 6.70224 5.82302 7.64511 5.82302 8.76083V16.757C5.82302 18.1206 6.92846 19.226 8.29208 19.226H15.3162C16.6798 19.226 17.7853 18.1206 17.7853 16.757V8.76083C17.7853 7.64512 17.0452 6.70224 16.0292 6.39629V6.83278C16.0292 7.74186 15.2923 8.47882 14.3832 8.47882H9.2251C8.31601 8.47882 7.57906 7.74186 7.57906 6.83278V6.39629ZM5 8.76083C5 6.94266 6.47392 5.46875 8.29208 5.46875C8.35283 5.46875 8.40208 5.518 8.40208 5.57875V6.83278C8.40208 7.28732 8.77056 7.6558 9.2251 7.6558H14.3832C14.8377 7.6558 15.2062 7.28732 15.2062 6.83278V5.57874C15.2062 5.51799 15.2555 5.46875 15.3162 5.46875C17.1344 5.46875 18.6083 6.94266 18.6083 8.76083V16.757C18.6083 18.5751 17.1344 20.0491 15.3162 20.0491H8.29208C6.47392 20.0491 5 18.5751 5 16.757V8.76083Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2939 4.26951C10.7779 4.09128 11.2954 4 11.817 4C12.3386 4 12.8561 4.09128 13.3402 4.26951C13.8242 4.44775 14.267 4.71004 14.6421 5.04345C15.0173 5.37694 15.3178 5.77551 15.524 6.21784C15.7302 6.66037 15.8372 7.13671 15.8372 7.61918H15.0141C15.0141 7.25918 14.9344 6.90129 14.778 6.56547C14.6214 6.22946 14.3903 5.92081 14.0953 5.65858C13.8002 5.39627 13.4473 5.18601 13.0558 5.04185C12.6642 4.89768 12.2432 4.82302 11.817 4.82302C11.3909 4.82302 10.9698 4.89768 10.5782 5.04185C10.1867 5.18601 9.83382 5.39627 9.53872 5.65858C9.24372 5.92081 9.01265 6.22946 8.85608 6.56547C8.69959 6.90129 8.6199 7.25918 8.6199 7.61918H7.79688C7.79688 7.13671 7.90386 6.66037 8.11008 6.21784C8.3162 5.77551 8.61675 5.37694 8.99194 5.04345C9.36702 4.71004 9.80978 4.44775 10.2939 4.26951Z'
        fill='currentColor'
      />
      <path
        d='M9.37486 12.1513C9.37486 12.621 8.99407 13.0018 8.52435 13.0018C8.05462 13.0018 7.67383 12.621 7.67383 12.1513C7.67383 11.6816 8.05462 11.3008 8.52435 11.3008C8.99407 11.3008 9.37486 11.6816 9.37486 12.1513Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.52435 12.5903C8.7668 12.5903 8.96335 12.3938 8.96335 12.1513C8.96335 11.9088 8.7668 11.7123 8.52435 11.7123C8.28189 11.7123 8.08534 11.9088 8.08534 12.1513C8.08534 12.3938 8.28189 12.5903 8.52435 12.5903ZM8.52435 13.0018C8.99407 13.0018 9.37486 12.621 9.37486 12.1513C9.37486 11.6816 8.99407 11.3008 8.52435 11.3008C8.05462 11.3008 7.67383 11.6816 7.67383 12.1513C7.67383 12.621 8.05462 13.0018 8.52435 13.0018Z'
        fill='currentColor'
      />
      <path
        d='M10.1035 12.1513C10.1035 11.6816 10.4843 11.3008 10.954 11.3008H15.0851C15.5549 11.3008 15.9356 11.6816 15.9356 12.1513C15.9356 12.621 15.5549 13.0018 15.0851 13.0018H10.954C10.4843 13.0018 10.1035 12.621 10.1035 12.1513Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0851 11.7123H10.954C10.7116 11.7123 10.515 11.9088 10.515 12.1513C10.515 12.3938 10.7116 12.5903 10.954 12.5903H15.0851C15.3276 12.5903 15.5241 12.3938 15.5241 12.1513C15.5241 11.9088 15.3276 11.7123 15.0851 11.7123ZM10.954 11.3008C10.4843 11.3008 10.1035 11.6816 10.1035 12.1513C10.1035 12.621 10.4843 13.0018 10.954 13.0018H15.0851C15.5549 13.0018 15.9356 12.621 15.9356 12.1513C15.9356 11.6816 15.5549 11.3008 15.0851 11.3008H10.954Z'
        fill='currentColor'
      />
      <path
        d='M9.37486 15.3105C9.37486 15.7802 8.99407 16.161 8.52435 16.161C8.05462 16.161 7.67383 15.7802 7.67383 15.3105C7.67383 14.8408 8.05462 14.46 8.52435 14.46C8.99407 14.46 9.37486 14.8408 9.37486 15.3105Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.52435 15.7495C8.7668 15.7495 8.96335 15.5529 8.96335 15.3105C8.96335 15.068 8.7668 14.8715 8.52435 14.8715C8.28189 14.8715 8.08534 15.068 8.08534 15.3105C8.08534 15.5529 8.28189 15.7495 8.52435 15.7495ZM8.52435 16.161C8.99407 16.161 9.37486 15.7802 9.37486 15.3105C9.37486 14.8408 8.99407 14.46 8.52435 14.46C8.05462 14.46 7.67383 14.8408 7.67383 15.3105C7.67383 15.7802 8.05462 16.161 8.52435 16.161Z'
        fill='currentColor'
      />
      <path
        d='M10.1035 15.3105C10.1035 14.8408 10.4843 14.46 10.954 14.46H15.0851C15.5549 14.46 15.9356 14.8408 15.9356 15.3105C15.9356 15.7802 15.5549 16.161 15.0851 16.161H10.954C10.4843 16.161 10.1035 15.7802 10.1035 15.3105Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0851 14.8715H10.954C10.7116 14.8715 10.515 15.068 10.515 15.3105C10.515 15.5529 10.7116 15.7495 10.954 15.7495H15.0851C15.3276 15.7495 15.5241 15.5529 15.5241 15.3105C15.5241 15.068 15.3276 14.8715 15.0851 14.8715ZM10.954 14.46C10.4843 14.46 10.1035 14.8408 10.1035 15.3105C10.1035 15.7802 10.4843 16.161 10.954 16.161H15.0851C15.5549 16.161 15.9356 15.7802 15.9356 15.3105C15.9356 14.8408 15.5549 14.46 15.0851 14.46H10.954Z'
        fill='currentColor'
      />
    </svg>
  );
}
