import React, { ReactNode, useCallback } from 'react';
import { DivProps } from "../../../../typings";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../../navigation/domain/enums/pages";
import { useModal } from "../../../ud-ui/components/modal/useModal";
import { CardContainer, TeamCardHeaderRow } from "../../../teams/ui/components/team-card/styles";
import classNames from "classnames";
import UDText from "../../../ud-ui/components/text";
import UDButton from "../../../ud-ui/components/button";
import { Player } from "../../domain/interfaces/player";
import PlayerPhoto from "./player-photo";
import { differenceInYears, format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { deleteTournamentUser } from "../../store/actions";
import { toast } from "react-toastify";

type Props = {
  player: Player;
  containerProps?: Omit<DivProps, 'children'>;
  actions?: JSX.Element;
  isSelectable?: boolean;
  selectedPlayerId?: number;
  children?: ReactNode;
  index?: number;
};

const PlayerCard = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>()
  const { player, containerProps, actions, index } = props;
  
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleEditInfoClick = useCallback(
    () => {
      navigate(Pages.PLAYERS.builders.view(player.id));
    }, [navigate, player.id]);
  
  const { open, close, Modal } = useModal({ defaultShow: false });
  const { open: openDeleteModal, close: closeDeleteModal, Modal: DeleteModal } = useModal({ defaultShow: false });
  
  const handleDeleteUser = useCallback(() => {
    dispatch(deleteTournamentUser({ userId: player.id })).then(() => {
      toast.success('Игрок успешно удалён')
      closeDeleteModal()
    })
  }, [closeDeleteModal, dispatch, player.id]);
  
  const birthDate = player.birthDate
    ? new Date(player.birthDate)
    : null;
  const birthDateString = birthDate ? format(birthDate, 'dd.MM.yyyy') : '';
  const years = birthDate ? differenceInYears(new Date(), birthDate) : null;
  const yearsString = years ? t('ages', { count: years }) : '';
  const full = birthDateString ? `${birthDateString} (${yearsString})` : '-';
  
  return (
    <CardContainer className='mb-4 last-mb-0'>
      <TeamCardHeaderRow
        {...containerProps}
        className={classNames(containerProps?.className)}
        style={{ maxHeight: 84 }}
        onClick={handleEditInfoClick}
      >
        <div className='team-card'>
          <div className='row'>
            {index !== undefined ? (
              <UDText type='subhead' className='team-column bold col-1'>
                {index + 1}
              </UDText>
            ) : (
              ''
            )}
            
            <div className='team-column col-4'>
              <PlayerPhoto
                className='team-logo mr-2'
                url={player.photo?.externalUrl}
                size={52}
              />
              <UDText
                type='subhead'
                className='bold'
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
              >
                {player.surname} {player.name} {player.middleName}
              </UDText>
            </div>
            
            {full ? (
              <UDText type='subhead' className='team-column col-2' style={{ textWrap: 'nowrap' }}>
                {full}
              </UDText>
            ) : (
              ''
            )}
            
            <UDText
              type='subhead'
              className='team-column col-1'
              onClick={(e) => {
                e.stopPropagation();
                // handleEditInfoClick(team, 'players');
              }}
            >
              {player.height ? `${player.height} см` : '-'}
            </UDText>
            
            <UDText
              type='subhead'
              className='team-column col-1'
              onClick={(e) => {
                e.stopPropagation();
                // handleEditInfoClick(team, 'players');
              }}
            >
              {player.weight ? `${player.weight} кг` : '-'}
            </UDText>
            
            <div className='team-column col-3 d-flex justify-content-end'>
              <UDButton
                className='d-flex align-items-center mr-3'
                variant='primary'
                onClick={(e) => {
                  e.stopPropagation();
                  open();
                }}
              >
                <span>В архив</span>
              </UDButton>
              
              <UDButton
                className='d-flex align-items-center'
                variant='secondary'
                onClick={(e) => {
                  e.stopPropagation();
                  openDeleteModal();
                }}
              >
                <span>Удалить</span>
              </UDButton>
            </div>
          </div>
        </div>
        {actions}
      </TeamCardHeaderRow>
      
      <Modal
        header={{ title: '', subtitle: '' }}
        containerProps={{
          style: {
            width: '500px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <UDText
          type='subhead'
          className='color-SurfaceRomanSilver30 px-10 mb-15'
          style={{ textAlign: 'center' }}
        >
          Данный функционал находится в разработке. Приносим свои извинения
          за доставленные неудобства
        </UDText>
        <UDButton
          className='d-flex align-items-center justify-content-center mb-3'
          variant='primary'
          onClick={(e) => {
            e.stopPropagation();
            close();
          }}
        >
          <span>Ок</span>
        </UDButton>
      </Modal>
      
      <DeleteModal
        header={{ title: '', subtitle: '' }}
        containerProps={{
          style: {
            width: '500px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      >
        <UDText
          type='headline'
          className='color-SurfaceRomanSilver30 px-10 mb-15'
          style={{ textAlign: 'center' }}
        >
          Удалить игрока {player.name} {player.surname} {player.middleName ?? ''}?
        </UDText>
        <UDText
          type='subhead'
          className='color-SurfaceRomanSilver30 px-10 mb-15'
          style={{ textAlign: 'center' }}
        >
          Это действие будет не обратимо.
        </UDText>
        <div className={'d-flex w-100'} style={{ gap: 10 }}>
          <UDButton
            className='d-flex align-items-center justify-content-center mb-3 w-50'
            variant='primary'
            onClick={(e) => {
              e.stopPropagation();
              closeDeleteModal();
            }}
          >
            <span>Нет</span>
          </UDButton>
          <UDButton
            className='d-flex align-items-center justify-content-center mb-3 w-50'
            variant='primary'
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteUser();
            }}
          >
            <span>Да</span>
          </UDButton>
        </div>
      </DeleteModal>
    
    </CardContainer>
  );
};

export default PlayerCard;
