import formatDateWithTimezone from '../../../utils/format-date-with-timezone';
import graphqlResource, { GraphqlResource } from "modules/core/graphqlResource";
import { GameDetails, GameEventType } from '../interfaces/GameDetails';
import { GameDetailsDTO, GameDetailsResponseDTO } from "../interfaces/GameDetailsResponseDTO";
import { mapGameDetails } from "../mappers/game-details-mapper";
import { GamePeriodsSettings } from '../mappers/match-periods-settings';

function mapEventTypeForBackend(type: GameEventType): string {
  let action;
  
  switch (type) {
    case GameEventType.FOUL:
      action = 'FOUL';
      break;
    case GameEventType.CORNER:
      action = 'CORNER';
      break;
    case GameEventType.OWN_GOAL:
      action = 'OWN_GOAL';
      break;
    case GameEventType.PENALTY:
      action = 'PENALTY';
      break;
    case GameEventType.SAVE:
      action = 'SAVE';
      break;
    case GameEventType.TIMEOUT:
      action = 'TIME_OUT';
      break;
    case GameEventType.REPLACEMENT:
      action = 'REPLACEMENT';
      break;
    case GameEventType.YELLOW_CARD:
      action = 'YELLOW_CARD';
      break;
    case GameEventType.RED_CARD:
      action = 'RED_CARD';
      break;
    case GameEventType.AFTER_MATCH_PENALTY_GOAL:
      action = 'AFTER_MATCH_PENALTY_GOAL';
      break;
    case GameEventType.AFTER_MATCH_PENALTY_MISSED:
      action = 'AFTER_MATCH_PENALTY_MISSED';
      break;
    default:
      action = 'GOAL';
      break;
  }
  
  return action;
}

export class GameRepository {
  constructor(private readonly graphqlResource: GraphqlResource) {}
  
  public async fetchGameDetails(gameId: number, periodsSettings: GamePeriodsSettings) {
    const query = `
      #graphql
      query getGameDetails($id: Int!) {
        match(id: $id) {
          id
          sportType
          date
          tournament {
            id
            name
          }
          championship {
            id
            name
          }
          teamMatches {
            team {
              id
              name
              emblem {
                externalUrl
              }
              members {
                user { id }
                position
                number
              }
              championshipApplications {
                championship { id }
                user {
                  id
                  name
                  surname
                  phone
                  birthDate
                  height
                  weight
                  photo { externalUrl }
                }
              }
            }
            score
            lineup {
              id
              name
              surname
            }
          }
          matchEvents {
            id
            second
            type
            subType
            firstTeamScore
            secondTeamScore
            videoUrl
            user {
              id
              name
              surname
            }
            playerName
            period
            secondaryUser {
              id
              name
              surname
            }
          }
          round {
            id
            name
            stage { id }
          }
          videos {
            externalUrl
          }
          participants {
            id
            match { id }
            user { id }
            isInvolved
          }
        }
      }
    `;
    
    const response = await this.graphqlResource.query<GameDetailsResponseDTO>(query, { id: gameId });
    const { match } = response.data.data;
    
    try {
      return mapGameDetails(match, periodsSettings);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  
  public async updateGameDetails(game: GameDetails, periodSettings: GamePeriodsSettings): Promise<GameDetails> {
    const query = `
      #graphql
      mutation updateMatch($input: UpsertMatchInput!) {
        upsertMatch(input: $input) {
          id
          sportType
          date
          tournament {
            id
            name
          }
          championship {
            id
            name
          }
          teamMatches {
            team {
              id
              name
              emblem {
                externalUrl
              }
              members {
                user { id }
                position
                number
              }
              championshipApplications {
                championship { id }
                user {
                  id
                  name
                  surname
                  phone
                  birthDate
                  height
                  weight
                  photo { externalUrl }
                }
              }
            }
            score
            lineup {
              id
              name
              surname
            }
          }
          matchEvents {
            id
            second
            type
            firstTeamScore
            secondTeamScore
            user { id }
            playerName
            period
            secondaryUser { id }
            videoUrl
          }
          round {
            id
            name
            stage { id }
          }
          videos {
            externalUrl
          }
          participants {
            id
            match { id }
            user { id }
            isInvolved
          }
        }
      }
    `;
    
    const variables = {
      input: {
        tournamentId: game.tournament.id,
        championshipId: game.championship.id,
        matchId: game.id,
        firstTeamId: game.teams[ 0 ].id,
        firstTeamScore: game.result.firstTeamScore,
        secondTeamId: game.teams[ 1 ].id,
        secondTeamScore: game.result.secondTeamScore,
        sportType: game.sportType,
        date: formatDateWithTimezone(game.date),
        matchEvents: game.events.map((event) => ({
          type: mapEventTypeForBackend(event.type),
          firstTeamScore: null,
          secondTeamScore: null,
          playerName: null,
          second: event.seconds,
          period: event.period,
          userId: event.playerIds[ 0 ],
          secondaryUserId: event.playerIds[ 1 ],
          videoUrl: event?.videoUrl,
        })),
        roundId: game.round?.id,
        videoUrls: game.videoUrls,
      },
    };
    
    const response = await this.graphqlResource.query<{ data: { upsertMatch: GameDetailsDTO; }; }>(query, variables);
    return mapGameDetails(response.data.data.upsertMatch, periodSettings);
  }
  
  public async updateMatchParticipants(matchId: number, participantIds: number[]): Promise<void> {
    const query = `
      mutation bulkChangeMatchParticipationStatus($input: BulkChangeParticipationInput!) {
        bulkChangeMatchParticipationStatus(input: $input) {
          id
          isInvolved
        }
      }
    `;
    
    const usersParticipation = participantIds.map((userId) => ({
      userId,
      isInvolved: true,
    }));
    
    const variables = {
      input: {
        matchId,
        usersParticipation,
      },
    };
    
    await this.graphqlResource.query(query, variables);
  }
}

export default new GameRepository(graphqlResource);
