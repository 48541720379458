interface IProps {
  totalSeconds: number
}

function getTimeFromTotal(props: IProps) {
  const { totalSeconds } = props
  
  return {
    hour: Math.floor(totalSeconds / 3600),
    minute: Math.floor((totalSeconds % 3600) / 60),
    second: totalSeconds % 60,
  }
}

export default getTimeFromTotal
