import { GameDetails, GameEvent } from "../../../../domain/interfaces/GameDetails";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";
import UrlInput from "../../../../../ud-ui/components/url-input/UrlInput";
import React, { useCallback } from "react";
import { setMomentUrl, setTimestampUrl } from "../../../../store/game-details";
import UDButton from "../../../../../ud-ui/components/button";
import useModal from "../../../../../ud-ui/components/new-modal/useModal";
import UDText from "../../../../../ud-ui/components/text";
import EmptyButton from "../../../../../ud-ui/components/emptyButton";
import QuestionMarkIcon from "../../../../../ud-ui/components/icon/question-mark-icon";
import generateMomentUrl from "../../../../utils/generateMomentUrl";

interface IProps {
  isLoading: boolean;
  game: GameDetails | null;
}

const keyFirstPeriod = '1'
const keySecondPeriod = '2'

export default function TimestampUrls(props: IProps) {
  const dispatch = useDispatch<AppDispatch>()
  
  const { isLoading, game } = props
  const timestampUrls = game?.timestamps ?? {}
  const firstPeriodRef = React.createRef<HTMLInputElement>()
  const secondPeriodRef = React.createRef<HTMLInputElement>()
  
  const { defaultOpen: open, close, Modal } = useModal({
    background: 'gray',
    onClose: () => {
      dispatch(setTimestampUrl({ key: keyFirstPeriod, url: firstPeriodRef.current?.value ?? '' }))
      dispatch(setTimestampUrl({ key: keySecondPeriod, url: secondPeriodRef.current?.value ?? '' }))
    },
  })
  
  const handleReplaceEventUrl = useCallback((events: GameEvent[]) => {
    for (const event of events) {
      switch (event.period) {
        case 1:
          const eventUrl = generateMomentUrl({ event, periodStartUrl: firstPeriodRef.current?.value ?? '' })
          if (!eventUrl) return
          dispatch(setMomentUrl({ eventId: event.id, url: eventUrl }))
          break;
        case 2:
          const eventUrl2 = generateMomentUrl({ event, periodStartUrl: secondPeriodRef.current?.value ?? '' })
          if (!eventUrl2) return
          dispatch(setMomentUrl({ eventId: event.id, url: eventUrl2 }))
          break;
      }
    }
    
  }, [dispatch, firstPeriodRef, secondPeriodRef]);
  
  const handleReplaceAllEventUrl = useCallback(() => {
    handleReplaceEventUrl(game?.events ?? [])
    close()
  }, [close, game?.events, handleReplaceEventUrl]);
  
  const handleReplaceEmptyEventUrl = useCallback(() => {
    handleReplaceEventUrl(game?.events.filter((event) => !event.videoUrl) ?? [])
    close()
  }, [close, game?.events, handleReplaceEventUrl]);
  
  return (
    <>
      <div className={'flex-column'}>
        <div className={'d-flex w-100 justify-content-end mb-4'}>
          <UDButton
            variant={'secondary'}
            onClick={open}
          >
            Сгенерировать ссылки
          </UDButton>
        </div>
      </div>
      
      <Modal>
        <div className={'flex-column mt-4 gap-2'}>
          <div className={'flex-row gap-2'}>
            <UDText type={'subhead'}>Ссылки с таймкодом на начало матча</UDText>
            <EmptyButton icon={<QuestionMarkIcon width={16} height={16}/>}/>
          </div>
          <UDText color={'#828282'} type={'caption-1'}>Ссылки принимаются из youtube и vk-video</UDText>
          <UrlInput
            ref={firstPeriodRef}
            isLoading={isLoading}
            name='first-time-url'
            placeholder={'Ссылка на начало 1-ого тайма'}
            inputProps={{
              style: { backgroundColor: '#F2F2F2' },
              defaultValue: timestampUrls[ keyFirstPeriod ]?.url ?? '',
            }}
          />
          <UrlInput
            ref={secondPeriodRef}
            isLoading={isLoading}
            name='second-time-url'
            placeholder={'Ссылка на начало 2-ого тайма'}
            inputProps={{
              style: { backgroundColor: '#F2F2F2' },
              defaultValue: timestampUrls[ keySecondPeriod ]?.url ?? '',
            }}
          />
        </div>
        <div className={'flex-row gap-4 mt-8'}>
          <UDButton
            variant={'primary'}
            onClick={handleReplaceAllEventUrl}
          >
            Заменить сущестующие
          </UDButton>
          <UDButton
            variant={'secondary'}
            onClick={handleReplaceEmptyEventUrl}
          >
            Вставить в пустые
          </UDButton>
        </div>
      </Modal>
    </>
  );
}
